{
  "acabado.AC-OR": "AÇO-OURO",
  "acabado.B": "Brilho",
  "acabado.BP01": "BP01",
  "acabado.BP01C": "CÍRCULO DE COR",
  "acabado.BSL": "BSL",
  "acabado.CAUCH": "Borracha",
  "acabado.CORB": "Gravata",
  "acabado.CRISA": "CRISÁLIDA",
  "acabado.ESTAM": "Impressão",
  "acabado.F+R": "FLAT + ROUND",
  "acabado.F+S": "FLAT + STRAIGHT",
  "acabado.F+S C": "FLAT + COLOR REC",
  "acabado.F+T": "FLAT + TIE",
  "acabado.FAC06": "FAC06",
  "acabado.FR11": "FRAP 11",
  "acabado.FRA15": "FRAP15",
  "acabado.GRAB": "gravura",
  "acabado.LAPI": "LAPIDADO",
  "acabado.LASER": "SER ESTAR",
  "acabado.M": "COMPANHEIRO",
  "acabado.M+S": "MOUNTAIN + STRAIGHT",
  "acabado.M+S C": "MONT.RECT.COLOR",
  "acabado.M+T": "MOUNTAIN + TIE",
  "acabado.MAL": "MALHA",
  "acabado.MB": "MATTE-GLOSS",
  "acabado.MECA": "USINAGEM",
  "acabado.MF5P": "MICROFUSÃO 5PC",
  "acabado.MMILA": "MILANESE MESH",
  "acabado.MOUNT": "MONTANA",
  "acabado.MTCA": "MATE CALADA",
  "acabado.OYS": "OSTRA",
  "acabado.PIR3": "PYRAMIDAL 3M",
  "acabado.PMP": "PULI-MATE-PULI",
  "acabado.PPM": "PANTER DE MATT POLIDO",
  "acabado.PPU": "PANTER POLIDO",
  "acabado.PRES": "Presidente",
  "acabado.PULID": "polido",
  "acabado.RAS": "RISCADO",
  "acabado.ROUND": "Volta",
  "acabado.S7M": "ESTAM 7 3MAT",
  "acabado.S7R": "CARIMBO 7PCS STRIPE",
  "acabado.S73M": "ESTAMP 7-3MATE",
  "acabado.ST3": "3PCS STAMPING",
  "acabado.ST4": "ESTAMPAGEM 4PCS",
  "acabado.ST5": "CARIMBADO 5 PCS",
  "acabado.ST6": "6PCS CARIMBADO",
  "acabado.ST7": "ESTAMPAGEM DE 7 PEÇAS",
  "acabado.TPMP": "TUBE P-M-P",
  "acabado.TUB": "Tubo",
  "acabado.V620": "V620",
  "acabado.Y": "MONTANHA POLIDA",
  "acabado.YHLO": "MONTANHA 1 / 2B + M",
  "accesorios.subtipo.BALLPOINT_PEN": "caneta esferográfica",
  "accesorios.subtipo.BELT": "Cinto",
  "accesorios.subtipo.CARD_HOLDER": "porta-cartões",
  "accesorios.subtipo.FOLDER": "encadernador",
  "accesorios.subtipo.FOUNTAIN_PEN": "caneta-tinteiro",
  "accesorios.subtipo.LEATHER_PEN_SET": "conjunto canetas couro",
  "accesorios.subtipo.OPTIC": "Óculos óticos",
  "accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
  "accesorios.subtipo.PEN": "caneta",
  "accesorios.subtipo.REPLACEMENT": "Peça sobressalente",
  "accesorios.subtipo.ROLLERBALL_PEN": "Caneta rollerball",
  "accesorios.subtipo.SOLAR": "Oculos de sol",
  "accesorios.subtipo.WALLET": "carteira",
  "accesorios.subtipo.WRITING_SET": "conjunto escrita",
  "accesorios.tipo.CLEANER": "limpadores",
  "accesorios.tipo.EYEWEAR": "Óculos",
  "accesorios.tipo.FRAGANCE": "perfumes",
  "accesorios.tipo.JEWELLERY_BOX": "Joalheiros",
  "accesorios.tipo.KEYCHAIN": "porta-chaves",
  "accesorios.tipo.LEATHER_ITEM": "artigo de couro",
  "accesorios.tipo.SET": "conjuntos",
  "accesorios.tipo.SPARE_PARTS": "peças sobressalentes",
  "accesorios.tipo.TOOL": "ferramentas",
  "accesorios.tipo.WRITING": "escrevendo",
  "accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
  "accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
  "agrupacion.coleccion": "colecao",
  "agrupacion.seleccion": "seleccao",
  "anchopuente.14": "14 mm",
  "anchopuente.15": "15 mm",
  "anchopuente.16": "16 mm",
  "anchopuente.17": "17 mm",
  "anchopuente.18": "18 mm",
  "anchopuente.21": "21 mm",
  "calendario.false": "Não",
  "calendario.true": "Sim",
  "caracteristicas.alarma": "Alarme",
  "caracteristicas.balancier_visible": "Balancim visível",
  "caracteristicas.complicacion_automatico": "Complicação automática",
  "caracteristicas.corona_rosca": "Coroa de rosca",
  "caracteristicas.dual_time": "Horário duplo",
  "caracteristicas.gmt": "GMT",
  "caracteristicas.luminova": "Luminova",
  "caracteristicas.luz": "Luz",
  "caracteristicas.reserva_marcha": "Reserva de marcha",
  "caracteristicas.skeleton": "Skeleton",
  "caracteristicas.solar": "Solar",
  "caracteristicas.superluminova": "Superluminova",
  "caracteristicas.tourbillon": "Tourbillon",
  "cierre.agrupado.CAJ": "Dobrável",
  "cierre.agrupado.CAP": "CAIXA DE BOTÕES",
  "cierre.agrupado.CCJ": "FECHO DA GAVETA",
  "cierre.agrupado.CI": "Mosquetão",
  "cierre.agrupado.CIP": "Pressão",
  "cierre.agrupado.CM": "Magnético",
  "cierre.agrupado.CPC": "CORREIA DE BOX PULS",
  "cierre.agrupado.CPL": "Lado",
  "cierre.agrupado.CPR": "Pressão",
  "cierre.agrupado.CSE": "Dobrável",
  "cierre.agrupado.CSP": "BOTÃO DE PRESSÃO DE FECHO SEGURO",
  "cierre.agrupado.HEB": "Fivela",
  "cierre.agrupado.HKSALO": "Cofre",
  "cierre.agrupado.LI": "Fecho Joia",
  "cierre.agrupado.MOS": "CARABINER",
  "cierre.agrupado.OCL": "HIDDEN",
  "cierre.agrupado.OCP": "BOTÃO DE PRESSÃO OCULTO",
  "cierre.agrupado.OCU": "Africano",
  "cierre.agrupado.ROS": "FECHO DE LINHA",
  "cierre.agrupado.SO": "Botão de pressão duplo",
  "cierre.agrupado.SOP": "BUTTON FLAP",
  "cierre.CAJ": "Dobrável",
  "cierre.CAP": "CAIXA DE BOTÕES",
  "cierre.CCJ": "FECHO DA GAVETA (ESTILO)",
  "cierre.CI": "Mosquetão",
  "cierre.CIP": "Pressão",
  "cierre.CM": "Magnético",
  "cierre.CMS": "MAGNÉTICO COM SEGURO",
  "cierre.CPC": "CORREIA PULS CAIXA",
  "cierre.CPL": "PULS DEPOIS DA CAIXA.",
  "cierre.CPR": "Pressão",
  "cierre.CSE": "Dobrável",
  "cierre.CSP": "BOTÃO DE PRESSÃO DE FECHO SEGURO",
  "cierre.HEB": "Fivela",
  "cierre.HKBB": "BUTTERFLY BUCKLE",
  "cierre.HKCB": "CLIP BUCKLE",
  "cierre.HKCLB": "CLIP LOCK BUCKLE",
  "cierre.HKJEWBUK": "BUCKLE DE JÓIAS",
  "cierre.HKLB": "BUCKLE DE COURO",
  "cierre.HKLOBUK": "Borboleta",
  "cierre.HKMB": "FIVELA MAGNÉTICA",
  "cierre.HKSALO": "Cofre",
  "cierre.HKSCB": "MOLA C-CLIP BUCKLE",
  "cierre.HKT-BABUK": "T-BAR BUCKLE",
  "cierre.HOR": "GOLD BUCKLE",
  "cierre.LI": "Fecho Joia",
  "cierre.MOS": "Mosquetão",
  "cierre.NEO": "NÃO LEVA",
  "cierre.NOSE": "NÃO SE SABE",
  "cierre.OCH": "FECHADURA ESCONDIDA",
  "cierre.OCL": "Oculto",
  "cierre.OCP": "BOTÃO DE PRESSÃO OCULTO",
  "cierre.OCS": "FLAP ESCONDIDO",
  "cierre.OCU": "Africano",
  "cierre.OPC": "HIDDEN STRAP PUSH",
  "cierre.OSP": "HIDDEN FLAP PULS",
  "cierre.ROS": "FECHO DE LINHA",
  "cierre.SO": "Botão de pressão duplo",
  "cierre.SOC": "FLAP STRAP",
  "cierre.SOH": "FLAP BUCKLE",
  "cierre.SOP": "BUTTON FLAP",
  "cierre.SPC": "FLAP PULS BELT",
  "cierre.undefined": "",
  "colores_base.1": "Cro",
  "colores_base.2N18": "2N18 DOURADO",
  "colores_base.2NBN": "CENTRO 2N18 CORPO PRETO",
  "colores_base.2NMA": "2N18 COM MARCAS",
  "colores_base.2NTN": "2N18 COM FILÉ PRETO",
  "colores_base.3N": "3N OURO",
  "colores_base.3N18": "3N18",
  "colores_base.4N18": "LIGHT ROSE GOLD",
  "colores_base.5N": "5N ROSE GOLD",
  "colores_base.5N18": "Rosa ouro",
  "colores_base.026": "MARFIM",
  "colores_base.032": "MARROM ESCURO",
  "colores_base.037": "HAVANA BROWN",
  "colores_base.041": "OURO MÉDIO",
  "colores_base.061": "AZUL MÉDIO",
  "colores_base.065": "Azul claro",
  "colores_base.066": "Azul claro",
  "colores_base.068": "AZURE",
  "colores_base.084": "CORAL",
  "colores_base.100": "AÇO BRILHANTE",
  "colores_base.128": "Nu",
  "colores_base.591": "PVD BLACK",
  "colores_base.600": "Rosa ouro",
  "colores_base.840": "AÇO + OURO",
  "colores_base.850": "AÇO + ROSA OURO",
  "colores_base.ACCC": "STEEL ZIRCONIA",
  "colores_base.AG": "Prata",
  "colores_base.AGAB": "SILVER AMBER",
  "colores_base.AGCR": "SILVER CRYSTAL",
  "colores_base.AGLA": "LAVENDER SILVER",
  "colores_base.AGLM": "SILVER LIME",
  "colores_base.AGMC": "PRATA MARCADA",
  "colores_base.AGMO": "SILVER PURPLE",
  "colores_base.AGPE": "SILVER PEARL",
  "colores_base.AGRO": "SILVER RED",
  "colores_base.AGTP": "SILVER TOPAZ",
  "colores_base.AGU": "Aquamarine",
  "colores_base.AM": "Amarelo",
  "colores_base.AMA": "Ametista",
  "colores_base.AMBA": "Âmbar",
  "colores_base.AMBR": "BRONZE AMARELO",
  "colores_base.AMCC": "YELLOW ZIRCONIA",
  "colores_base.AMMA": "AMARELO MARROM",
  "colores_base.AMPE": "YELLOW PEARL",
  "colores_base.AMT": "Ametista",
  "colores_base.AQ": "Aquamarine",
  "colores_base.AQBO": "AQUA BOHEMICA",
  "colores_base.AUR": "ALVORECER",
  "colores_base.AURB": "AURORA BOREAL",
  "colores_base.AVAZ": "BLUE ADVENTURINE",
  "colores_base.AZ": "Azul",
  "colores_base.AZRS": "ROSA AZUL",
  "colores_base.BDIA": "DIAMANTE NEGRO",
  "colores_base.BE": "BEGE",
  "colores_base.BICO": "BICOLOR",
  "colores_base.BL": "branco",
  "colores_base.BLCO": "CORAL WHITE",
  "colores_base.BLPE": "PÉROLA BRANCA",
  "colores_base.BLRY": "RISCADO DE BRANCO",
  "colores_base.BR": "Bronze",
  "colores_base.BRCC": "BRONZE ZIRCONS",
  "colores_base.BRIL": "SPARKLY",
  "colores_base.BRLI": "BRONZE LILAC",
  "colores_base.BRRS": "BRONZE PINK",
  "colores_base.BU": "BORDEAUX",
  "colores_base.BUCR": "CREME BURGUNDY",
  "colores_base.CA": "CAMELO",
  "colores_base.CABA": "Cavalo",
  "colores_base.CAL": "ARRASTAR",
  "colores_base.CALD": "Caldeira",
  "colores_base.CCPE": "ZIRCONIA PEARL",
  "colores_base.CER": "CEREJA",
  "colores_base.CH": "CHAMPANHE",
  "colores_base.CHA": "PLACADO",
  "colores_base.ChGR": "GREY PLATED",
  "colores_base.CHOC": "CHOCOLATE",
  "colores_base.CIRC": "Zircônia",
  "colores_base.CITR": "CITRINE",
  "colores_base.CM": "CREME",
  "colores_base.CMFU": "FUCHSIA CREAM",
  "colores_base.CO": "Cobre",
  "colores_base.CORA": "CORAL",
  "colores_base.CQ": "KHAKI",
  "colores_base.CR": "CHROME PLATED",
  "colores_base.CRDO": "CRISTAL DOURADO",
  "colores_base.CRIS": "Cristal",
  "colores_base.CRTR": "CRISTAL TRANSPARENTE",
  "colores_base.CURR": "Trabalho",
  "colores_base.CZ": "QUARTZO",
  "colores_base.CZAZ": "QUARTZO AZUL",
  "colores_base.CZMA": "QUARTZO MARROM",
  "colores_base.CZRS": "QUARTZO ROSA",
  "colores_base.DELF": "GOLFINHO",
  "colores_base.DI": "Digital",
  "colores_base.DIAM": "Diamantes",
  "colores_base.DO": "Dourado",
  "colores_base.DONE": "PRETO DOURADO",
  "colores_base.ESM": "Esmeralda",
  "colores_base.EXPO": "EXPO",
  "colores_base.FLOR": "Flor",
  "colores_base.FLUO": "FLUORESCENTE",
  "colores_base.FOCA": "FOCA",
  "colores_base.FR": "morango",
  "colores_base.FRAM": "FRAMBOESA",
  "colores_base.FU": "FÚCSIA",
  "colores_base.FUCC": "FUCHSIA ZIRCONS",
  "colores_base.FUME": "FUME",
  "colores_base.GATO": "Gato",
  "colores_base.GN": "Granada",
  "colores_base.GR": "Cinzento",
  "colores_base.GRA": "Granada",
  "colores_base.GRAG": "Prata cinza",
  "colores_base.GRPL": "CINZA PRATEADO",
  "colores_base.HA": "Hamilton",
  "colores_base.HAB": "Havana",
  "colores_base.HAHA": "HAMILTON HAMILTON",
  "colores_base.HAMC": "HAMILTON MARCADO",
  "colores_base.HANA": "HAMILTON NACAR",
  "colores_base.HANC": "HAMILTON MÃE DA PÉROLA",
  "colores_base.HEM": "HEMATITA",
  "colores_base.INDI": "INDICOLITE",
  "colores_base.JET": "JATO",
  "colores_base.KOAL": "KOALA",
  "colores_base.LI": "LILÁS",
  "colores_base.LIOS": "DARK LILAC",
  "colores_base.LIPE": "LILAC PEARL",
  "colores_base.LUMI": "SUPERLUMINOVA",
  "colores_base.LUN": "Lua",
  "colores_base.MA": "Castanho",
  "colores_base.MAAG": "MARROM / PRATA",
  "colores_base.MAPE": "PEARL BROWN",
  "colores_base.MBLU": "AZUL METÁLICO",
  "colores_base.MC": "Marcado",
  "colores_base.ME": "PÊSSEGO",
  "colores_base.MEN": "HORTELÃ",
  "colores_base.MF": "MARFIM",
  "colores_base.MFNE": "MARFIM-PRETO",
  "colores_base.MGRE": "VERDE METÁLICO",
  "colores_base.MO": "Roxa",
  "colores_base.MOBL": "PURPLE WHITE",
  "colores_base.MOS": "MOSTARDA",
  "colores_base.MS": "MOSTARDA",
  "colores_base.MSIL": "PRATA METÁLICA",
  "colores_base.MT": "COMPANHEIRO",
  "colores_base.MULT": "MULTICOLORADO",
  "colores_base.MULTAM": "MULTICOLOR AMARELO",
  "colores_base.MULTAZ": "MULTICOLOR BLUE",
  "colores_base.MULTRS": "MULTICOLOR ROSA",
  "colores_base.NA": "NÁCAR",
  "colores_base.NAAZ": "NACAR BLUE",
  "colores_base.NE": "Preto",
  "colores_base.NEAM": "PRETO AMARELO",
  "colores_base.NECC": "ZIRCÃO NEGRO",
  "colores_base.NEDO": "Ouro Preto",
  "colores_base.NEGD": "DIAMANTE NEGRO",
  "colores_base.NEO": "NÃO LEVA",
  "colores_base.NEPE": "PÉROLA NEGRA",
  "colores_base.NJ": "laranja",
  "colores_base.NJNC": "ORANGE PEARL",
  "colores_base.NYB": "WHITE NYLON",
  "colores_base.NYBE": "NYLON BEIG",
  "colores_base.NYBL": "WHITE NYLON",
  "colores_base.NYC": "CREME NYLON",
  "colores_base.NYCM": "CREME NYLON",
  "colores_base.NYG": "NYLON CINZA",
  "colores_base.NYGR": "NYLON CINZA",
  "colores_base.NYM": "NYLON BROWN",
  "colores_base.NYMA": "NYLON BROWN",
  "colores_base.NYN": "BLACK NYLON",
  "colores_base.NYNE": "BLACK NYLON",
  "colores_base.NYO": "NYLON OCHER",
  "colores_base.NYR": "RED NYLON",
  "colores_base.OBSI": "OBSIDIANA",
  "colores_base.OC": "OCRE",
  "colores_base.OCR": "OCRE",
  "colores_base.OLIV": "OLIVINA",
  "colores_base.ONIX": "ÔNIX",
  "colores_base.OR": "Ouro",
  "colores_base.ORAZ": "OURO AZUL",
  "colores_base.PACH": "CHAMPANHE AZUL",
  "colores_base.PAJA": "Pássaro",
  "colores_base.PAV": "PAVONADO",
  "colores_base.PD": "Paládio",
  "colores_base.PER": "Pérola",
  "colores_base.PERN": "PÉROLA NEGRA",
  "colores_base.PERR": "Cachorro",
  "colores_base.PLAG": "PRATA PRATA",
  "colores_base.PLAM": "PRATA AMARELA",
  "colores_base.PLAZ": "PRATA AZUL",
  "colores_base.PLLI": "LILAC SILVER",
  "colores_base.PLNE": "PRATA / PRETO",
  "colores_base.PLPL": "PRATA PRATA",
  "colores_base.PLRS": "PINK SILVER",
  "colores_base.PLVE": "SILVER GREEN",
  "colores_base.PV": "PAVONADO",
  "colores_base.PVCH": "CHAMPANHE AZUL",
  "colores_base.PVD": "PVD",
  "colores_base.RH": "Ródio",
  "colores_base.RH5N": "OURO BRANCO + OURO 5N",
  "colores_base.RO": "Vermelho",
  "colores_base.ROCA": "CALADA VERMELHA",
  "colores_base.ROMS": "MOSTARDA VERMELHA",
  "colores_base.RONE": "vermelho preto",
  "colores_base.RS": "Rosa",
  "colores_base.RSCC": "PINK ZIRCONIA",
  "colores_base.RSPE": "PEARL PINK",
  "colores_base.RSRO": "ROSA VERMELHO",
  "colores_base.RU": "RUTÊNIO",
  "colores_base.RUBI": "Rubi",
  "colores_base.SASA": "SALMON SALMON",
  "colores_base.ST": "SEMITRANSPARENTE",
  "colores_base.TA": "BLUE TIGER",
  "colores_base.TEJA": "Telha",
  "colores_base.TIT": "TITÂNIO",
  "colores_base.TOP": "Topázio",
  "colores_base.TP": "Topázio",
  "colores_base.TPCL": "LIGHT TOPAZ",
  "colores_base.TR": "PINK TIGER",
  "colores_base.TRAN": "transparente",
  "colores_base.TRCO": "TRICOLOR",
  "colores_base.TRI": "TRICOLOR",
  "colores_base.TRNE": "TRANSP BLACK",
  "colores_base.TW/W": "TOP WESSELTON PARA WESSELTON",
  "colores_base.VA": "Baunilha",
  "colores_base.VE": "Verde",
  "colores_base.VEBRI": "VERDE BRILHANTE",
  "colores_base.VECC": "ZIRCÔNIA VERDE",
  "colores_base.VI": "Tolet",
  "colores_base.VIDI": "VIOLETA DIGITAL",
  "colores_base.VIGR": "VIOLET GREY",
  "colores_base.VIN": "VINHO",
  "colores_base.VIOS": "DARK VIOLET",
  "colores_base.ZAFI": "Safira",
  "colores_catalogo.AG": "Prata",
  "colores_catalogo.AM": "Amarelo",
  "colores_catalogo.AZ": "Azul",
  "colores_catalogo.BE": "BEGE",
  "colores_catalogo.BL": "branco",
  "colores_catalogo.BU": "BORDEAUX",
  "colores_catalogo.CH": "CHAMPANHE",
  "colores_catalogo.CM": "CREME",
  "colores_catalogo.CO": "Cobre",
  "colores_catalogo.DO": "Dourado",
  "colores_catalogo.GR": "Cinzento",
  "colores_catalogo.MA": "Castanho",
  "colores_catalogo.MULT": "MULTICOLORADO",
  "colores_catalogo.NA": "NÁCAR",
  "colores_catalogo.NE": "Preto",
  "colores_catalogo.NJ": "laranja",
  "colores_catalogo.PL": "Prata",
  "colores_catalogo.PLA": "Prata",
  "colores_catalogo.PLAZ": "PRATA AZUL",
  "colores_catalogo.PLRS": "PINK SILVER",
  "colores_catalogo.RO": "Vermelho",
  "colores_catalogo.ROSE": "Rosa",
  "colores_catalogo.RS": "Rosa",
  "colores_catalogo.VE": "Verde",
  "colores_catalogo.VI": "Tolet",
  "correa.agrupada.ARMYS": "ARMYS",
  "correa.agrupada.CORREA": "Correia",
  "estanqueidad.1A": "1 ATM",
  "estanqueidad.3A": "3 ATM",
  "estanqueidad.5A": "5 ATM",
  "estanqueidad.10A": "10 ATM",
  "estanqueidad.20A": "20 ATM",
  "estanqueidad.?": "Sem aperto",
  "estanqueidad.IP68": "IP68",
  "estanqueidad.NEO": "NÃO TEM",
  "estanqueidad.R.P.": "RESISTENTE À PÓ",
  "estanqueidad.RP": "RESISTENTE À PÓ",
  "estanqueidad.SHPR": "SHOWERPROOF",
  "estanqueidad.W.P.": "À PROVA DÁGUA.",
  "estanqueidad.W.R.": "À prova d'água",
  "estanqueidad.WR": "À prova d'água",
  "estilos.CA": "Casual",
  "estilos.CH": "Chique",
  "estilos.CL": "Clássico",
  "estilos.EL": "elegância",
  "estilos.LE": "EDIÇÃO LIMITADA",
  "estilos.SP": "esportes",
  "estilos.VI": "Vintage",
  "formaCaja.C": "Quadrado",
  "formaCaja.CR": "Cruz",
  "formaCaja.DI": "Digital",
  "formaCaja.IR": "IRREGULAR",
  "formaCaja.O": "oval",
  "formaCaja.RC": "Retangular",
  "formaCaja.RD": "Redondo",
  "formaCaja.TO": "BARRIL",
  "formaCaja.TV": "Televisão",
  "funcion.agrupada.101": "Funções Digitais",
  "funcion.agrupada.102": "Multifuncional",
  "funcion.agrupada.103": "HORAS E MINUTOS",
  "funcion.agrupada.104": "Cronógrafo",
  "funcion.agrupada.105": "Funções Inteligentes",
  "gamas.?": "",
  "gamas.agrupada.BE": "Bebe",
  "gamas.agrupada.C": "homem",
  "gamas.agrupada.IN": "Infantil",
  "gamas.agrupada.NA": "Menina",
  "gamas.agrupada.NI": "Menino",
  "gamas.agrupada.S": "mulher",
  "gamas.agrupada.UN": "Unisex",
  "gamas.AN": "argolas",
  "gamas.BE": "Bebe",
  "gamas.C": "homem",
  "gamas.CD": "JUNIOR",
  "gamas.CO": "PINGENTE",
  "gamas.IN": "JUNIOR",
  "gamas.NA": "JUNIOR",
  "gamas.NI": "JUNIOR",
  "gamas.PE": "mulher",
  "gamas.PU": "PULSEIRAS",
  "joyas.tipo.agrupada.ANILLO": "Anel",
  "joyas.tipo.agrupada.BROCHE": "BROCHE",
  "joyas.tipo.agrupada.CIERRE": "Fecho",
  "joyas.tipo.agrupada.COLGANTE": "PINGENTE",
  "joyas.tipo.agrupada.COLLAR": "Colar",
  "joyas.tipo.agrupada.CONJUNTO": "Conjunto",
  "joyas.tipo.agrupada.GEMELOS": "Gêmeos",
  "joyas.tipo.agrupada.PENDIENTES": "Pendente",
  "joyas.tipo.agrupada.PIERCING": "Piercing",
  "joyas.tipo.agrupada.PULSERA": "pulseira",
  "joyas.tipo.agrupada.TOBILLERA": "tornozeleiras",
  "joyas.tipo.ANILLO": "Anel",
  "joyas.tipo.BROCHE": "BROCHE",
  "joyas.tipo.COLGANTE": "PINGENTE",
  "joyas.tipo.COLLAR": "Colar",
  "joyas.tipo.CONJUNTO": "Conjunto",
  "joyas.tipo.GEMELOS": "Gêmeos",
  "joyas.tipo.PENDIENTES": "Pendente",
  "joyas.tipo.PIERCING": "Piercing",
  "joyas.tipo.PULSERA": "pulseira",
  "joyas.tipo.sub.1-1": "LISA",
  "joyas.tipo.sub.1-2": "COM PEDRAS",
  "joyas.tipo.sub.1-3": "COM DIAMANTES",
  "joyas.tipo.sub.1-4": "LISA ALLIANCE",
  "joyas.tipo.sub.1-5": "LAPIDADA ALLIANCE",
  "joyas.tipo.sub.1-6": "ALIANÇA COM PEDRA",
  "joyas.tipo.sub.1-7": "CARIMBO",
  "joyas.tipo.sub.1-8": "ENAMEL PRINT",
  "joyas.tipo.sub.1-9": "DIAMOND ALLIANCE",
  "joyas.tipo.sub.1-10": "Impressão",
  "joyas.tipo.sub.1-11": "ESMALTE",
  "joyas.tipo.sub.1-12": "Esboço, projeto",
  "joyas.tipo.sub.1-13": "PIDRA CRISTAL",
  "joyas.tipo.sub.1-14": "SWAROVSKI",
  "joyas.tipo.sub.2-1": "Suave",
  "joyas.tipo.sub.2-2": "COM PEDRAS",
  "joyas.tipo.sub.2-3": "ESCAPULAR",
  "joyas.tipo.sub.2-4": "SMOOTH CROSS",
  "joyas.tipo.sub.2-5": "VIRGIN GIRL",
  "joyas.tipo.sub.2-6": "PERGAMINHO / PLACA",
  "joyas.tipo.sub.2-7": "Pequenos anjos",
  "joyas.tipo.sub.2-8": "CRUZ COM PEDRAS",
  "joyas.tipo.sub.2-9": "CRUZ COM CRISTO",
  "joyas.tipo.sub.2-10": "FETICHE",
  "joyas.tipo.sub.2-11": "CRUZ COM DIAMANTES",
  "joyas.tipo.sub.2-12": "COM DIAMANTES",
  "joyas.tipo.sub.2-13": "FETICHE COM PEDRAS",
  "joyas.tipo.sub.2-14": "ELÁSTICO",
  "joyas.tipo.sub.2-15": "Virgem",
  "joyas.tipo.sub.2-16": "CRISTO",
  "joyas.tipo.sub.2-17": "CRUZ CARAVACA",
  "joyas.tipo.sub.2-18": "ESMALTE",
  "joyas.tipo.sub.2-19": "COMUNHÃO",
  "joyas.tipo.sub.2-20": "Esboço, projeto",
  "joyas.tipo.sub.2-21": "CRUZ CALADA",
  "joyas.tipo.sub.3-1": "CHATON",
  "joyas.tipo.sub.3-2": "Zircônia",
  "joyas.tipo.sub.3-3": "PEDRA DE COR",
  "joyas.tipo.sub.3-4": "ESMALTE",
  "joyas.tipo.sub.3-5": "ELETROFORMAÇÃO",
  "joyas.tipo.sub.3-6": "PÉROLAS",
  "joyas.tipo.sub.3-7": "VOCÊ E EU",
  "joyas.tipo.sub.3-8": "Anel",
  "joyas.tipo.sub.3-9": "BOLA",
  "joyas.tipo.sub.3-10": "Vários",
  "joyas.tipo.sub.3-11": "CERÂMICA",
  "joyas.tipo.sub.3-12": "COM PEDRAS",
  "joyas.tipo.sub.3-13": "Anel",
  "joyas.tipo.sub.3-14": "ESMALTE",
  "joyas.tipo.sub.3-15": "COM DIAMANTES",
  "joyas.tipo.sub.3-16": "ELÁSTICO",
  "joyas.tipo.sub.3-17": "STONE HOOP",
  "joyas.tipo.sub.3-18": "Impressão",
  "joyas.tipo.sub.3-19": "Esboço, projeto",
  "joyas.tipo.sub.4-1": "LISA",
  "joyas.tipo.sub.4-2": "Gravado",
  "joyas.tipo.sub.4-3": "Encaracolado",
  "joyas.tipo.sub.4-4": "CURLY WITH STONES",
  "joyas.tipo.sub.4-5": "SUAVE COM PEDRAS",
  "joyas.tipo.sub.4-6": "SUAVE COM DIAMANTES",
  "joyas.tipo.sub.4-7": "ESMALTE",
  "joyas.tipo.sub.4-8": "COM PELE",
  "joyas.tipo.sub.5-1": "1ª POSIÇÃO COM PLACA",
  "joyas.tipo.sub.5-2": "bem",
  "joyas.tipo.sub.5-3": "Bruto",
  "joyas.tipo.sub.5-4": "SÓLIDO GROSSO COM FOLHA",
  "joyas.tipo.sub.5-5": "SÓLIDO ESPESSO",
  "joyas.tipo.sub.5-6": "OCO GROSSO COM GRÂNULOS",
  "joyas.tipo.sub.5-7": "THICK HOLLOW",
  "joyas.tipo.sub.5-8": "GROSSO COM PEDRAS",
  "joyas.tipo.sub.5-9": "RIVIERE",
  "joyas.tipo.sub.5-10": "OCO GROSSO COM FOLHA",
  "joyas.tipo.sub.5-11": "FINE HOLLOW COM PLACA",
  "joyas.tipo.sub.5-12": "COM PEDRA",
  "joyas.tipo.sub.5-13": "BOLAS",
  "joyas.tipo.sub.5-14": "BOLA COM PEDRA",
  "joyas.tipo.sub.5-15": "1ª POSIÇÃO",
  "joyas.tipo.sub.5-16": "FINE HOLLOW",
  "joyas.tipo.sub.5-17": "SÓLIDO FINO COM FOLHA",
  "joyas.tipo.sub.5-18": "ELÁSTICO",
  "joyas.tipo.sub.5-19": "COM BEADS",
  "joyas.tipo.sub.5-20": "ALÇA DE COURO",
  "joyas.tipo.sub.5-21": "COM DIAMANTES",
  "joyas.tipo.sub.5-22": "CORREIA DE COURO DIAMANTE",
  "joyas.tipo.sub.5-23": "COM PÉROLAS",
  "joyas.tipo.sub.5-24": "TIRA DE BORRACHA",
  "joyas.tipo.sub.6-1": "FINE HOLLOW",
  "joyas.tipo.sub.6-2": "THICK HOLLOW",
  "joyas.tipo.sub.6-3": "SÓLIDO FINO",
  "joyas.tipo.sub.6-4": "SÓLIDO ESPESSO",
  "joyas.tipo.sub.6-5": "BORRACHA COM FECHO",
  "joyas.tipo.sub.6-6": "COURO COM PRATA",
  "joyas.tipo.sub.6-7": "COURO COM FECHO DE OURO",
  "joyas.tipo.sub.6-8": "HOLLOW LAPIDADO",
  "joyas.tipo.sub.6-9": "NYLON CLOSURE PLAT",
  "joyas.tipo.sub.7-1": "HOLLOW LAPIDADO",
  "joyas.tipo.sub.7-2": "FINA COM PEDRA",
  "joyas.tipo.sub.7-3": "FINA COM PENDENTE",
  "joyas.tipo.sub.7-4": "argolas",
  "joyas.tipo.sub.7-5": "FINE STONE PENDANT",
  "joyas.tipo.sub.7-6": "GROSSO COM PENDENTE",
  "joyas.tipo.sub.7-7": "bem",
  "joyas.tipo.sub.7-8": "BOLAS",
  "joyas.tipo.sub.7-9": "FINA COM PENDENTE DE ESMALTE",
  "joyas.tipo.sub.7-10": "GROSSO COM PEDRAS",
  "joyas.tipo.sub.7-11": "HOLLOW THICK",
  "joyas.tipo.sub.7-12": "FINE HOLLOW",
  "joyas.tipo.sub.7-13": "BORRACHA COM PENDENTE",
  "joyas.tipo.sub.7-14": "FINA DIAMOND PENDANT",
  "joyas.tipo.sub.7-15": "ELÁSTICO",
  "joyas.tipo.sub.7-16": "GROSSO COM DIAMANTES",
  "joyas.tipo.sub.7-17": "CORDÃO DE COURO",
  "joyas.tipo.sub.7-18": "CABO COM PENDENTE",
  "joyas.tipo.sub.7-19": "COM BEADS",
  "joyas.tipo.sub.8-1": "1ª POSIÇÃO",
  "joyas.tipo.sub.8-2": "Gravata",
  "joyas.tipo.sub.9-1": "FINE SMOOTH",
  "joyas.tipo.sub.9-2": "ESPESSO LISO",
  "joyas.tipo.sub.9-3": "FINO COM PEDRAS",
  "joyas.tipo.sub.9-4": "GROSSO COM PEDRAS",
  "joyas.tipo.sub.9-5": "FINO COM DIAMANTES",
  "joyas.tipo.sub.9-6": "GROSSO COM DIAMANTES",
  "joyas.tipo.sub.9-7": "BOLAS",
  "joyas.tipo.sub.9-8": "RIVIERE",
  "joyas.tipo.sub.10-1": "Suave",
  "joyas.tipo.sub.10-2": "COM PEDRAS",
  "joyas.tipo.sub.10-3": "PÉROLAS",
  "joyas.tipo.sub.11-1": "Suave",
  "joyas.tipo.sub.11-2": "COM PEDRAS",
  "joyas.tipo.sub.12-1": "COM DIAMANTES",
  "joyas.tipo.sub.12-2": "Suave",
  "joyas.tipo.sub.13-1": "Suave",
  "joyas.tipo.sub.13-2": "COM PEDRAS",
  "joyas.tipo.sub.13-3": "COM DIAMANTES",
  "joyas.tipo.sub.14-1": "Pressão",
  "joyas.tipo.sub.14-2": "FIO",
  "joyas.tipo.sub.15-1": "COM BEADS",
  "joyas.tipo.sub.17-1": "PAR DE PARAFUSOS",
  "joyas.tipo.sub.25-1": "CLIQUE FÁCIL",
  "joyas.tipo.sub.99": "Indefinido",
  "joyas.tipo.sub.900-1": "9K AMARELO",
  "joyas.tipo.sub.900-2": "9K BRANCO",
  "joyas.tipo.sub.900-3": "9K BICOLOR",
  "joyas.tipo.sub.undefined": "",
  "joyas.tipo.TOBILLERA": "tornozeleiras",
  "kilataje.ORO9K": "9K OURO",
  "kilataje.ORO18K": "18K OURO",
  "longpatillas.140": "140 mm",
  "longpatillas.142": "142 mm",
  "longpatillas.145": "145 mm",
  "material.9K": "375ml 9K Gold",
  "material.18K": "18K OURO",
  "material.24K": "Ouro Fino",
  "material.?": "?",
  "material.AC": "Aço",
  "material.AC-EB": "STEEL-EBANE",
  "material.ACAC": "AÇO E AÇO",
  "material.ACBI": "STEEL-BICOLOR",
  "material.ACBR": "AÇO BRILHANTE",
  "material.ACCA": "AÇO E BORRACHA",
  "material.ACCC": "STEEL ZIRCONIA",
  "material.ACCE": "AÇO CERÂMICO",
  "material.ACCH": "AÇO PLACADO",
  "material.ACDI": "DIAMANTES DE AÇO",
  "material.ACE": "316l de aço inoxidável",
  "material.ACERO": "316l de aço inoxidável",
  "material.ACETA": "Acetato",
  "material.ACFC": "FIBRA DE CARBONO",
  "material.ACHB": "AÇO BICOLOUR",
  "material.ACHO": "AÇO FOLHEADO A OURO",
  "material.ACMA": "AÇO E MADEIRA",
  "material.ACOR": "AÇO E OURO",
  "material.ACPI": "AÇO E COURO",
  "material.ACPL": "AÇO E PLÁSTICO",
  "material.ACTI": "STEEL TITANIUM",
  "material.AGCI": "SILVER-ZIRCONIA",
  "material.AGES": "PRATA ENAMELED",
  "material.AGG": "Prata",
  "material.AGPE": "PRATA E PÉROLAS",
  "material.agrupado.ACERO": "Aço",
  "material.agrupado.ACETA": "ACETATO",
  "material.agrupado.CAUCHO": "Borracha",
  "material.agrupado.CERAMICA": "Cerâmica",
  "material.agrupado.CUERO": "Couro",
  "material.agrupado.GOMA": "Borracha",
  "material.agrupado.METAL": "METAL",
  "material.agrupado.ORO": "Ouro",
  "material.agrupado.ORO9K": "GOLD9K",
  "material.agrupado.ORO18K": "GOLD18K",
  "material.agrupado.PLATA": "Prata",
  "material.agrupado.TITANIO": "TITÂNIO",
  "material.AGS": "PRATA SWAROVSKI",
  "material.ALG": "Algodão",
  "material.ALUM": "Alumínio",
  "material.ALZ": "Liga de zinco",
  "material.ANTE": "Antes",
  "material.BELC": "BELCRO",
  "material.BICO": "BICOLOR",
  "material.BRO": "Bronze",
  "material.C18K": "FECHO DE BORRACHA + OURO",
  "material.CAAC": "AÇO DE BORRACHA",
  "material.CAU": "Borracha",
  "material.CAUCHO": "Borracha",
  "material.CEBR": "CERÂMICA-BRILHANTE",
  "material.CER": "CERÂMICA",
  "material.CERAMICA": "Cerâmica",
  "material.CHA": "Chapada",
  "material.CHAP": "PLACADO",
  "material.CHBI": "BICOLOUR PLATED",
  "material.CHOR": "BANHADO A OURO",
  "material.COCO": "PELE DE CROCODILO",
  "material.COCT": "COCO CUT",
  "material.CRBI": "BICOLOR CHROME",
  "material.CRMO": "cromada",
  "material.CRO": "CHROME PLATED",
  "material.CU": "corda",
  "material.CUERO": "Couro",
  "material.FIB": "FIBRA",
  "material.GO": "Borracha",
  "material.GOCA": "Borracha",
  "material.GOM": "Borracha",
  "material.HNAU": "FIO NÁUTICO",
  "material.LACH": "Latão banhado",
  "material.LACR": "CHROME BRASS",
  "material.LACT": "CUT LIZARD",
  "material.LAG": "LAGARTO",
  "material.LAT": "Latão",
  "material.LCHB": "Latão BICOLOUR",
  "material.LIQ": "líquido",
  "material.METAL": "METAL",
  "material.MIL": "MAGNIFIER MINERAL",
  "material.MIN": "MINERAL",
  "material.MINP": "FLAT MINERAL",
  "material.MINT": "MINERAL ESTANHADO",
  "material.NEO": "NEOPRENE",
  "material.NOB": "NOBUCK",
  "material.NY": "NYLON",
  "material.NYBE": "NYLON BELCRO",
  "material.NYCA": "NYLON RUBBER",
  "material.NYCT": "NYLON CUT",
  "material.NYL": "NYLON",
  "material.NYPC": "NYLON-COURO SUPERIOR",
  "material.NYPI": "COURO DE NYLON BASE",
  "material.O18": "18K OURO",
  "material.OASP": "OURO 18K SEM PREÇO",
  "material.OB5N": "OURO BRANCO + OURO 5N",
  "material.OBSP": "OURO BRANCO SEM PREÇO",
  "material.OR18": "OURO 18 KTES.",
  "material.OR24": "OURO 24 KTES.",
  "material.ORO": "Ouro",
  "material.OROB": "18K WHITE GOLD",
  "material.P+O": "COURO + FECHO DE OURO",
  "material.P-CC": "COURO C / CORTE (7,5X4,2)",
  "material.PABA": "PAVE BALL",
  "material.PALD": "Paládio",
  "material.PAST": "massa",
  "material.PC": "PLÁSTICO + FIBRA DE VIDRO",
  "material.PEL": "PLEX MAGNIFIER",
  "material.PI": "Pele",
  "material.PI-C": "COURO C / CORTE (7,5X3,8)",
  "material.PICA": "COURO DE BORRACHA",
  "material.PICH": "PATENTE DE COURO",
  "material.PICT": "CORTE DE COURO",
  "material.PIEL": "Couro",
  "material.PIEL-PU": "COURO-POLIURETANO",
  "material.PIGR": "COURO GRAVADO",
  "material.PILI": "PELE MACIA",
  "material.PINA": "NAYLON COURO",
  "material.PINY": "NYLON COURO",
  "material.PIPL": "PELE PLASTIFICADA",
  "material.PIRA": "COURO LISTRADO",
  "material.PISI": "PELE SINTÉTICA",
  "material.PISIN": "PELE SINTÉTICA",
  "material.PL": "PLEX",
  "material.PLA": "Plástico",
  "material.PLAA": "ANEL DE PLÁSTICO",
  "material.PLAL": "PLASTIC-MAGNIFIER",
  "material.PLAS": "Plástico",
  "material.PLAT": "Prata 925 ml",
  "material.PLATA": "Prata",
  "material.PLEX": "PLASTIC PLEX",
  "material.PLNY": "PLASTIC NYLON",
  "material.PLPI": "BASE DE COURO DE PLÁSTICO",
  "material.PLPL": "PLPL",
  "material.PLXL": "PLAST + MAGNIFIER",
  "material.POLICARBONATO": "POLICARBONATO",
  "material.PU": "POLIURETANO",
  "material.PUPI": "PURPURINA BASE DE COURO",
  "material.PVC": "PVC",
  "material.RAYA": "COURO LISTRADO",
  "material.RH": "Ródio",
  "material.RU": "RUTÊNIO",
  "material.SERP": "Serpente",
  "material.SPBP": "BASE DE COURO SINTÉTICA",
  "material.STBP": "BASE DE COURO SINTÉTICA",
  "material.SWA": "SWAROVSKI",
  "material.TELA": "PANO",
  "material.TEPI": "TECIDO DE COURO",
  "material.TIBI": "BICOLOUR TITANIUM",
  "material.TIBU": "PELE DE TUBARÃO",
  "material.TIT": "TITÂNIO",
  "material.TITANIO": "Titânio",
  "material.TORT": "PELE DE TARTARUGA",
  "material.TUNG": "TUNGSTÊNIO",
  "material.undefined": "",
  "material.Z+L": "SAPPHIRE + MAGNIFIER",
  "material.Z-G": "ZAF + MAGNIFIER + GRAVAÇÃO",
  "material.ZAF": "Safira",
  "material.ZAFP": "SAFIRA PINTADA",
  "movimiento.funcion.1": "SIMPLES (CAL)",
  "movimiento.funcion.4": "CHRONO (SEM CAL)",
  "movimiento.funcion.12": "RESERVE-START",
  "movimiento.funcion.101": "Digital",
  "movimiento.funcion.102": "Multifuncional",
  "movimiento.funcion.103": "QUARTZO",
  "movimiento.funcion.104": "Cronógrafo",
  "movimiento.funcion.105": "Digital",
  "movimiento.funcion.106": "Digital",
  "movimiento.funcion.107": "ALARME ANALÓGICO",
  "movimiento.funcion.108": "Multifuncional",
  "movimiento.funcion.109": "TEMPO DUPLO",
  "movimiento.funcion.110": "Automático",
  "movimiento.funcion.111": "Tourbillon",
  "movimiento.funcion.112": "GMT",
  "movimiento.funcion.113": "RESERVE-START",
  "movimiento.funcion.agrupada.ANALO": "Analógico",
  "movimiento.funcion.agrupada.AUTO": "Automáticos",
  "movimiento.funcion.agrupada.CRONO": "Cronógrafo",
  "movimiento.funcion.agrupada.DIGI": "Digital",
  "movimiento.funcion.agrupada.HYBRID": "Híbridos",
  "movimiento.funcion.agrupada.MULTI": "Multifuncional",
  "movimiento.funcion.agrupada.SMART": "smartwatch",
  "movimiento.funcion.ANA-AL": "ALARME ANALÓGICO",
  "movimiento.funcion.ANA-DI": "DIGITAL ANALOG",
  "movimiento.funcion.ANA-DIGI": "DIGITAL ANALOG",
  "movimiento.funcion.AUT-AL": "ALARME AUTOMÁTICO",
  "movimiento.funcion.AUTO": "Automáticos",
  "movimiento.funcion.CRO-SUI": "CRONÓGRAFO SUÍÇO",
  "movimiento.funcion.CRO.SOL": "SOLAR CHRONO",
  "movimiento.funcion.CRONO": "Cronógrafo",
  "movimiento.funcion.CRONO-AL": "CRONO-ALARME",
  "movimiento.funcion.DI-MULTI": "MULTIFUCION DIGITAL",
  "movimiento.funcion.DIGI": "Digital",
  "movimiento.funcion.DUAL-TIM": "TEMPO DUPLO",
  "movimiento.funcion.HYBRID": "Híbridos",
  "movimiento.funcion.MULTI": "Multifuncional",
  "movimiento.funcion.QUTZ-ANA": "QUARTZO / ANALÓGICO",
  "movimiento.funcion.SI": "Simples",
  "movimiento.funcion.SIM": "Simples",
  "movimiento.funcion.SIM-SUI": "SIMPLE SWISS",
  "movimiento.funcion.SMART": "RELÓGIO INTELIGENTE",
  "movimiento.tipo.01": "Digital",
  "movimiento.tipo.1": "SIMPLES (CAL)",
  "movimiento.tipo.02": "DIGITAL ANALOG",
  "movimiento.tipo.03": "QUARTZO / ANALÓGICO",
  "movimiento.tipo.04": "Automático",
  "movimiento.tipo.05": "HIBRID",
  "movimiento.tipo.06": "RELÓGIO INTELIGENTE",
  "movimiento.tipo.07": "SOLAR",
  "movimiento.tipo.7": "MULTIF (CAL)",
  "movimiento.tipo.12": "RESERVE-START",
  "movimiento.tipo.ANA-AL": "ALARME ANALÓGICO",
  "movimiento.tipo.ANA-DI": "DIGITAL ANALOG",
  "movimiento.tipo.ANA-DIGI": "DIGITAL ANALOG",
  "movimiento.tipo.AUT-AL": "ALARME AUTOMÁTICO",
  "movimiento.tipo.AUTO": "Automático",
  "movimiento.tipo.AUTO-CRO": "CRONÓGRAFO AUTOMÁTICO",
  "movimiento.tipo.AUTOM": "Automático",
  "movimiento.tipo.C": "corda",
  "movimiento.tipo.CRO-SUI": "CRONÓGRAFO SUÍÇO",
  "movimiento.tipo.CRONO": "Cronógrafo",
  "movimiento.tipo.CUER-MEC": "CORDA / MECÂNICA",
  "movimiento.tipo.CUER.": "corda",
  "movimiento.tipo.D": "Digital",
  "movimiento.tipo.DI": "Digital",
  "movimiento.tipo.DIGI": "Digital",
  "movimiento.tipo.DUAL-TIM": "TEMPO DUPLO",
  "movimiento.tipo.F": "SOLAR",
  "movimiento.tipo.HIBRID": "HIBRID",
  "movimiento.tipo.ME.CRO.": "MECA.CRONO",
  "movimiento.tipo.ME.SIM.": "MECA SIMPLES",
  "movimiento.tipo.MULTI": "Multifuncional",
  "movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
  "movimiento.tipo.PROF.": "MEDIDOR DE PROFUNDIDADE",
  "movimiento.tipo.QUTZ-ANA": "QUARTZO / ANALÓGICO",
  "movimiento.tipo.SIM": "Simples",
  "movimiento.tipo.SIM-SUI": "SIMPLE SWISS",
  "movimiento.tipo.SMART": "RELÓGIO INTELIGENTE",
  "movimiento.tipo.TOURBILLON": "TOURBILLON",
  "numeraciones.1A+AP": "1AR.AP + AR.PI",
  "numeraciones.1A+CC": "1AR.AP + CRIRC",
  "numeraciones.1A+IA": "1AR.AP + IN.AP",
  "numeraciones.1A+IP": "1AR.AP + IN.PI",
  "numeraciones.1A.AP": "1AR.AP",
  "numeraciones.1A.PI": "1AR.PI",
  "numeraciones.1AA": "1AR.AP",
  "numeraciones.1AP+I": "1AR.PI + IN.AP",
  "numeraciones.1AP+P": "1AR.PI + IN.PI",
  "numeraciones.1BR+I": "1BRILL + IN.AP",
  "numeraciones.1CC": "1CIRC",
  "numeraciones.1CC+I": "1CIRCO + IN.AP",
  "numeraciones.1CC+IA": "1CIRC + IN.AP",
  "numeraciones.1CIR+I": "1CIRCO + IN.AP.",
  "numeraciones.1D+IN": "1DIAM + IND APLI",
  "numeraciones.1I.AP": "1IN.AP",
  "numeraciones.1IA": "1IN.AP",
  "numeraciones.1IP": "1IN.PI",
  "numeraciones.1R+IA": "1RO.AP + IN.AP",
  "numeraciones.1R+IP": "1RO.AP + IN.PI",
  "numeraciones.1R+RP": "1RO.AP + RO.PI",
  "numeraciones.1R.AP": "1RO.AP",
  "numeraciones.1R.P": "1RO.PI",
  "numeraciones.1R.PI": "1RO.PI",
  "numeraciones.1RA": "1RO.AP.",
  "numeraciones.1RA+P": "1R.APL + RO.PIN.",
  "numeraciones.1RP+I": "1RO.PI + IN.AP",
  "numeraciones.1RP+P": "1RO.PI + IN.PI",
  "numeraciones.2A+AP": "2AR.AP + AR.PI",
  "numeraciones.2A+CC": "2AR.AP + CIRC",
  "numeraciones.2A+IA": "2AR.AP + IN.AP",
  "numeraciones.2A+IP": "2AR.AP + IN.PI",
  "numeraciones.2A.AP": "2AR.AP",
  "numeraciones.2A.PI": "2AR.PI",
  "numeraciones.2AA+2RA": "2AR.AP + 2RO.AP",
  "numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+I": "2AR.PI + IN.AP",
  "numeraciones.2AP+P": "2AR.PI + IN.PI",
  "numeraciones.2CC": "2CIRC.",
  "numeraciones.2CC+I": "2CIRC + IN.AP",
  "numeraciones.2CC+IA": "2CIRC + IN.AP",
  "numeraciones.2I+RP": "2IN.AP + 10RO.PI",
  "numeraciones.2I.AP": "2IN.AP",
  "numeraciones.2IA": "2IN.AP",
  "numeraciones.2IA+P": "2IN.AP + IN.PI.",
  "numeraciones.2IP": "2IN.PI",
  "numeraciones.2R+CC": "2RO.AP + CIRC",
  "numeraciones.2R+IA": "2RO.AP + IN.AP",
  "numeraciones.2R+IP": "2RO.AP + IN.PI",
  "numeraciones.2R+RP": "2RO.AP + RO.PI",
  "numeraciones.2R.AP": "2RO.AP",
  "numeraciones.2R.P": "2RO.PI.",
  "numeraciones.2R.PI": "2RO.PI",
  "numeraciones.2RA": "2ROM.APL.",
  "numeraciones.2RP+C": "2RO.PI + CIRC",
  "numeraciones.2RP+I": "2RO.PI + IN.AP",
  "numeraciones.2RP+P": "2RO.PI + IN.PI",
  "numeraciones.3A+AP": "3AR.AP + AR.PI",
  "numeraciones.3A+IA": "3AR.AP + IN.AP",
  "numeraciones.3A+IP": "3AR.AP + IN.PI",
  "numeraciones.3A.AP": "3AR.AP",
  "numeraciones.3A.PI": "3AR.PI",
  "numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
  "numeraciones.3AP+I": "3AR.PI + IN.AP.",
  "numeraciones.3AP+P": "3AR.PI + IN.PI",
  "numeraciones.3CC": "3CIRC.",
  "numeraciones.3CC+IA": "3CIRC + IN.AP",
  "numeraciones.3I+RP": "3IN.AP + RO.PI",
  "numeraciones.3I.AP": "3IN.AP",
  "numeraciones.3IA": "3IN.AP",
  "numeraciones.3IP": "3IN.PI",
  "numeraciones.3R+CC": "3ROM + ZIRCONS",
  "numeraciones.3R+IA": "3RO.AP + IN.AP",
  "numeraciones.3R+IP": "3RO.AP + IN.PI",
  "numeraciones.3R+IR": "3/4 ROMANOS + IND.APL",
  "numeraciones.3R+RP": "3RO.AP + RO.PI",
  "numeraciones.3R.AP": "3RO.AP",
  "numeraciones.3R.P": "3RO.PI.",
  "numeraciones.3R.PI": "3RO.PI",
  "numeraciones.3RA+CC": "3RA.AP + CC",
  "numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
  "numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
  "numeraciones.3RP+I": "3RO.PI + IN.AP",
  "numeraciones.3RP+P": "3RO.PI + IN.PI",
  "numeraciones.4A+4A": "4AR.PI + 4A.PI",
  "numeraciones.4A+4AP": "4AR.AP + 4AR.P",
  "numeraciones.4A+4R": "4AR + IA / 4R + IA.",
  "numeraciones.4A+AP": "4AR.AP + AR.PI",
  "numeraciones.4A+CC": "4ARA.AP + CIRC",
  "numeraciones.4A+IA": "4AR.AP + IN.AP",
  "numeraciones.4A+IP": "4AR.AP + IN.PI",
  "numeraciones.4A+P": "4ARP + IND.PIN.",
  "numeraciones.4A.AP": "4AR.AP",
  "numeraciones.4A.PI": "4AR.PI",
  "numeraciones.4AA+6": "4AR.A + 6AR.P",
  "numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
  "numeraciones.4AP+I": "4AR.PI + IN.AP",
  "numeraciones.4AP+P": "4AR.PI + IN.PI",
  "numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
  "numeraciones.4CC": "4CIRC.",
  "numeraciones.4DIA": "4 DIAMANTES",
  "numeraciones.4I+CC": "4IN.AP + CIRC",
  "numeraciones.4IA": "4IN.AP",
  "numeraciones.4IA+P": "4IN.AP + IN.PI",
  "numeraciones.4IN.A": "4 ÍNDICES APLICADOS",
  "numeraciones.4INDS": "4 ÍNDICES",
  "numeraciones.4IP": "4IN.PI",
  "numeraciones.4R+CC": "4 ROM. + CIRC.",
  "numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
  "numeraciones.4R+IA": "4RO.AP + IN.AP",
  "numeraciones.4R+IP": "4RO.AP + IN.PI",
  "numeraciones.4R+RP": "4RO.AP + RO.PI",
  "numeraciones.4R.AP": "4RO.AP",
  "numeraciones.4R.P": "4RO.PI.",
  "numeraciones.4R.PI": "4RO.PI",
  "numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
  "numeraciones.4RA+P": "4RO.AP + 8RO.PI",
  "numeraciones.4RE+E": "4RO.EST + IN.EST.",
  "numeraciones.4ROM": "4 ROMANOS",
  "numeraciones.4RP+I": "4RO.PI + IN.AP",
  "numeraciones.4RP+P": "4RO.PI + IN.PI",
  "numeraciones.5A+5CC": "5AR.AP + 5CIRC",
  "numeraciones.5A+AP": "5AR.AP + AR.PI",
  "numeraciones.5A+IA": "5AR.AP + IN.AP",
  "numeraciones.5A+IP": "5AR.AP + IN.PI",
  "numeraciones.5A.AP": "5AR.AP",
  "numeraciones.5A.PI": "5AR.PI",
  "numeraciones.5AP+I": "5AR.PI + IN.AP",
  "numeraciones.5AP+P": "5AR.PI + IN.PI",
  "numeraciones.5CC": "5CIRC.",
  "numeraciones.5D+IA": "5 DIAM + IND. APLI",
  "numeraciones.5DIAM": "5 DIAMANTES",
  "numeraciones.5IA": "5IN.AP",
  "numeraciones.5IP": "5IN.PI",
  "numeraciones.5R+IA": "5RO.AP + IN.AP",
  "numeraciones.5R+IP": "5RO.AP + IN.PI",
  "numeraciones.5R+RP": "5RO.AP + RO.PI",
  "numeraciones.5R.AP": "5RO.AP",
  "numeraciones.5R.PI": "5RO.PI",
  "numeraciones.5RP+I": "5RO.PI + IN.AP",
  "numeraciones.5RP+P": "5RO.PI + IN.PI",
  "numeraciones.6A+AP": "6AR.AP + AR.PI",
  "numeraciones.6A+CC": "6AR.AP + CIRC.",
  "numeraciones.6A+IA": "6AR.AP + IN.AP",
  "numeraciones.6A+IP": "6AR.AP + IN.PI",
  "numeraciones.6A.AP": "6AR.AP",
  "numeraciones.6A.PI": "6AR.PI",
  "numeraciones.6AP+I": "6AR.PI + IN.AP",
  "numeraciones.6AP+P": "6AR.PI + IN.PI.",
  "numeraciones.6CC.": "6CIRC.",
  "numeraciones.6IA": "6IN.AP",
  "numeraciones.6IP": "6IN.PI",
  "numeraciones.6R+IA": "6RO.AP + IN.AP",
  "numeraciones.6R+IP": "6RO.AP + IN.PI",
  "numeraciones.6R+RP": "6RO.AP + RO.PI",
  "numeraciones.6R.AP": "6RO.AP",
  "numeraciones.6R.P": "6RO.PIN.",
  "numeraciones.6RP+I": "6RO.PI + IN.AP",
  "numeraciones.6RP+P": "6RO.PI + IN.PI",
  "numeraciones.7A+IA": "7AR.AP + IN.AP",
  "numeraciones.7AR.P": "7AR.P",
  "numeraciones.7DIA": "7 DIAMANTES",
  "numeraciones.8A+IA": "8AR.AP + IN.AP",
  "numeraciones.8AP": "8ARA.PIN.",
  "numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
  "numeraciones.8R+IA": "8RO.AP + IN.AP",
  "numeraciones.8RP+P": "8RO.PI + IN.PI.",
  "numeraciones.9ARAB": "9 ARABS",
  "numeraciones.10ARA": "10 ÁRABES",
  "numeraciones.A+R.P": "ARA + ROM.PIN.",
  "numeraciones.AA+AP": "ARA PINT + ARA APLIC",
  "numeraciones.AP+AA": "ARA PINT + ARA APLIC",
  "numeraciones.AP+IA": "ARA.PIN + IND.A",
  "numeraciones.AP+IP": "AR.PI + IN.PI",
  "numeraciones.AR+DI": "RA. + 10 DIAMANTES",
  "numeraciones.AR.AP": "ÁRABES APLICADOS",
  "numeraciones.AR.PI": "ÁRABES PINTADOS",
  "numeraciones.AR4/4": "ÁRABES 4/4 + ÍNDICES",
  "numeraciones.ARA.A": "ÁRABES APLICADOS",
  "numeraciones.ARA.E": "ÁRABES IMPRESSOS",
  "numeraciones.ARA.P": "ÁRABES PINTADOS",
  "numeraciones.ARAB": "ARABES",
  "numeraciones.ARABE": "ARABES 12/12",
  "numeraciones.BAND": "Bandeiras",
  "numeraciones.BOLAS": "2N18 BOLAS",
  "numeraciones.CIRC": "Zircônia",
  "numeraciones.CR": "SWAROVSKI CRYSTAL",
  "numeraciones.DIAM": "12 DIAMANTES",
  "numeraciones.DIAMG": "BIG DIAMONDS",
  "numeraciones.DIAN": "DIGITAL ANALOG",
  "numeraciones.DIAR": "DIGITAL ARABES",
  "numeraciones.DIG": "Digital",
  "numeraciones.DIIN": "ÍNDICES DIGITAIS",
  "numeraciones.FANT": "Fantasia",
  "numeraciones.FL": "FLORES",
  "numeraciones.IN+CC": "IND + ZIRCONS",
  "numeraciones.IN.AP": "ÍNDICES APLICADOS",
  "numeraciones.IN.PI": "ÍNDICES PINTADOS",
  "numeraciones.INAR": "12INDIC + 12ARAB",
  "numeraciones.IND.A": "ÍNDICES APLICADOS",
  "numeraciones.IND.E": "IND.EST.",
  "numeraciones.IND.P": "INDÚSTRIAS PINTADAS",
  "numeraciones.INDIC": "Índices",
  "numeraciones.INFAN": "ÍNDICES DE FANTASIA",
  "numeraciones.INPIN": "IND.PIN.",
  "numeraciones.INRO": "12 ÍNDICES + 12 ROMANOS",
  "numeraciones.IP+AP": "12IN.PI + 12AR.PI",
  "numeraciones.NEO": "NÃO LEVA",
  "numeraciones.RA+RP": "4ROM APLI + 4ROM PIN",
  "numeraciones.RO+IN": "ÍNDICE ROMANOS 12 + 10",
  "numeraciones.RO.AP": "ROMANOS APLICADOS",
  "numeraciones.RO.PI": "ROMANOS PINTADOS",
  "numeraciones.RO2/2": "ROMANOS 2/2 + ÍNDICE",
  "numeraciones.RO4/4": "ROMANOS 4/4 + ÍNDICES",
  "numeraciones.ROES": "ROM.STAMPINGS",
  "numeraciones.ROM": "ROMANOS",
  "numeraciones.ROM.A": "ROMANS APPLIQUES",
  "numeraciones.ROM.E": "ROMANOS CARIMBADOS",
  "numeraciones.ROM.P": "ROMANOS PINTADOS",
  "numeraciones.ROMAN": "12/12 ROMANOS",
  "numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
  "numeraciones.RP+IP": "12RO.PI + 12IN.PI",
  "origen.1": "Suíça",
  "origen.2": "Japão",
  "piedras.tipo.agrupada.ABA": "BEADS DIFFERENT FORMS",
  "piedras.tipo.agrupada.ACBA": "BOLA DE AÇO",
  "piedras.tipo.agrupada.AGA": "ÁGATA",
  "piedras.tipo.agrupada.AGBA": "SILVER BALL",
  "piedras.tipo.agrupada.AMA": "Ametista",
  "piedras.tipo.agrupada.AMAN": "AMANZONITE",
  "piedras.tipo.agrupada.ARCI": "ANEL DE PRATA COM ZIRCÃO",
  "piedras.tipo.agrupada.ARGO": "Anel",
  "piedras.tipo.agrupada.AVEN": "ADVENTURINA",
  "piedras.tipo.agrupada.BAEN": "BAR SET ZIRCONIA",
  "piedras.tipo.agrupada.BAPA": "PAVE BAR",
  "piedras.tipo.agrupada.BGSP": "SACOS SEM PREÇO",
  "piedras.tipo.agrupada.BOL": "BOLA DE PRATA FACETADA",
  "piedras.tipo.agrupada.BRI": "SPARKLY",
  "piedras.tipo.agrupada.BS": "BS",
  "piedras.tipo.agrupada.BUTT": "BOTÃO PÉROLA CULTIVADA",
  "piedras.tipo.agrupada.CALC": "CALCEDÔNIA",
  "piedras.tipo.agrupada.CER": "CERÂMICA",
  "piedras.tipo.agrupada.CIR": "ZIRCÔNIA AMARELA (CZ-YE1)",
  "piedras.tipo.agrupada.CIRC": "SET ZIRCONIA",
  "piedras.tipo.agrupada.COR": "CORNALINA",
  "piedras.tipo.agrupada.CRBA": "Bola de cristal",
  "piedras.tipo.agrupada.CRI": "Cristal",
  "piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
  "piedras.tipo.agrupada.CUAR": "QUARTZO",
  "piedras.tipo.agrupada.DIAM": "Diamante",
  "piedras.tipo.agrupada.DISP": "0",
  "piedras.tipo.agrupada.ESMA": "ESMALTE",
  "piedras.tipo.agrupada.ESP": "Spinel",
  "piedras.tipo.agrupada.FERI": "Feriado",
  "piedras.tipo.agrupada.G030": "FO1030",
  "piedras.tipo.agrupada.G031": "FO1031",
  "piedras.tipo.agrupada.G034": "BRILHANTE",
  "piedras.tipo.agrupada.G036": "FO1036",
  "piedras.tipo.agrupada.G050": "FO1050",
  "piedras.tipo.agrupada.GI10": "STONE 0,90",
  "piedras.tipo.agrupada.GI11": "38640",
  "piedras.tipo.agrupada.GI12": "MOD FO282",
  "piedras.tipo.agrupada.GI13": "SHOCKWAVE CRIMPED BEZEL",
  "piedras.tipo.agrupada.GI14": "STONES 1,6DIAM",
  "piedras.tipo.agrupada.GI15": "STONE 0,80",
  "piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0,015K",
  "piedras.tipo.agrupada.GIL1": "STONES 1,20 + 1,50",
  "piedras.tipo.agrupada.GIL2": "FO436",
  "piedras.tipo.agrupada.GIL3": "DIAMOND 1,10",
  "piedras.tipo.agrupada.GIL4": "MODELO DE PEDRA LO314",
  "piedras.tipo.agrupada.GIL5": "STONES MODEL FO323",
  "piedras.tipo.agrupada.GIL6": "PEDRAS DE 1,20",
  "piedras.tipo.agrupada.GIL7": "STONES 1,40",
  "piedras.tipo.agrupada.GIL8": "STONES 1.30",
  "piedras.tipo.agrupada.GIL9": "STONE 1,00",
  "piedras.tipo.agrupada.GLAM": "GLAMOUR",
  "piedras.tipo.agrupada.HEMA": "HEMATITA",
  "piedras.tipo.agrupada.JADE": "JADE",
  "piedras.tipo.agrupada.LABR": "LABRADORITE",
  "piedras.tipo.agrupada.LAPI": "LÁPIS LAZÚLI",
  "piedras.tipo.agrupada.LAVA": "LAVA BALL",
  "piedras.tipo.agrupada.MP": "MÃE PÉROLA",
  "piedras.tipo.agrupada.NAC": "NÁCAR",
  "piedras.tipo.agrupada.OJGA": "OLHO DE GATO",
  "piedras.tipo.agrupada.ONI": "ÔNIX",
  "piedras.tipo.agrupada.OPPE": "OPALA PERUANA",
  "piedras.tipo.agrupada.OTIG": "OLHO DE TIGRE (GARNET)",
  "piedras.tipo.agrupada.OTUR": "OLHO TURCO",
  "piedras.tipo.agrupada.PABA": "PAVE BALL",
  "piedras.tipo.agrupada.PEBA": "PÉROLA BARROCA CULTIVADA",
  "piedras.tipo.agrupada.PECU": "PÉROLA CULTIVADA",
  "piedras.tipo.agrupada.PERL": "PÉROLA NATURAL",
  "piedras.tipo.agrupada.PESY": "PÉROLA SINTÉTICA",
  "piedras.tipo.agrupada.PILU": "MOONSTONE",
  "piedras.tipo.agrupada.QURS": "QUARTZO ROSA",
  "piedras.tipo.agrupada.RES": "ESFERA DE RESINA",
  "piedras.tipo.agrupada.RESI": "RESINA",
  "piedras.tipo.agrupada.RORU": "RHODIUM RUTENIUM",
  "piedras.tipo.agrupada.RUB": "Rubi",
  "piedras.tipo.agrupada.SWA": "SWAROVSKI",
  "piedras.tipo.agrupada.SZA1": "STONE 1,50",
  "piedras.tipo.agrupada.SZA2": "STONE 1.20",
  "piedras.tipo.agrupada.TUCI": "ZIRCONIA TUBE",
  "piedras.tipo.agrupada.TUR": "TURQUESA",
  "producto.coleccion": "coleção",
  "producto.tipo.accesorio": "acessório",
  "producto.tipo.joya": "Jóia",
  "producto.tipo.reloj": "Relogio",
  "tipo.movimiento.agrupado.01": "Digital",
  "tipo.movimiento.agrupado.02": "QUARTZO-DIGITAL",
  "tipo.movimiento.agrupado.03": "Manual",
  "tipo.movimiento.agrupado.04": "Automáticos",
  "tipo.movimiento.agrupado.05": "Híbridos",
  "tipo.movimiento.agrupado.06": "SMARTWATCH",
  "tipo.movimiento.agrupado.07": "QUARTZO",
  "tipolente.POLARIZED": "Polarizado"
}
