import { Location } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RedirectService } from '@fgp/utils/redirect';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  bindToComponentInputs: true
};

export const routes: Routes = [
  {
    path: 'caja',
    loadComponent: () => import('./layout/checkout-layout/checkout-layout.component').then(m => m.CheckoutLayoutComponent),
    data: { breadcrumb: 'breadcrumb.cesta', skipRouteLocalization: true },
    loadChildren: () => import('./pages/caja/caja.module').then(m => m.CajaModule)
  },
  {
    path: '',
    loadComponent: () => import('./layout/main-layout/main-layout.component').then(m => m.MainLayoutComponent),
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: '**',
    component: AppComponent,
    data: { skipRouteLocalization: true },
    canActivate: [
      () => {
        inject(RedirectService).redirect404();
        return false;
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, environment.idiomasAplicacion, 'ROUTES.', '!'),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      },
      cacheMechanism: CacheMechanism.LocalStorage,
      cookieFormat: '{{value}};{{expires}};path=/',
      defaultLangFunction: (languages: string[], cachedLang?: string, browserLang?: string) => {
        return defaultLocale(languages, cachedLang, browserLang);
      }
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

function defaultLocale(languages: string[], cachedLang?: string, browserLang?: string): string {
  // Por alguna razón browserLang es nulo. Decido copiar partes de la librería de ngx-translate

  // TODO pendiente eliminar console.log
  // console.log('browserLang', browserLang);
  // console.log('cached', returnIfInLocales(languages, cachedLang));
  // console.log('navegador', getIdiomaNavegador(languages, getBrowserCultureLang()));
  // console.log('defecto', environment.idiomaPorDefecto);
  return returnIfInLocales(languages, cachedLang) || getIdiomaNavegador(languages, getBrowserCultureLang()) || environment.idiomaPorDefecto;
}

/**
 * Check if value exists in locales list
 */
function returnIfInLocales(languages: string[], value: string): string {
  if (value && languages.indexOf(value) !== -1) {
    return value;
  }
  return null;
}

/**
 * Returns the culture language code name from the browser, e.g. "de-DE"
 */
function getBrowserCultureLang(): string {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }

  let browserCultureLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
  browserCultureLang =
    browserCultureLang || window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];

  return browserCultureLang;
}

/**
 * Devuelve el idioma por defecto, en función del idioma del navegador
 */
function getIdiomaNavegador(languages: string[], browserLanguage: string): string {
  if (browserLanguage) {
    if (browserLanguage.indexOf(environment.idiomaCulturalSeparador) === -1) {
      for (const idiomaAplicacion of languages) {
        if (idiomaAplicacion.substring(0, 2) === browserLanguage) {
          return idiomaAplicacion;
        }
      }
    } else {
      if (languages.includes(browserLanguage)) {
        return browserLanguage;
      }
    }
  }

  return null;
}
