{
  "name": "fgp",
  "version": "17.24.3",
  "license": "MIT",
  "private": true,
  "scripts": {
    "ng": "nx",
    "postinstall": "node ./decorate-angular-cli.js",
    "nx": "nx",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "nx workspace-lint && ng lint",
    "e2e": "ng e2e",
    "affected:apps": "nx affected:apps",
    "affected:libs": "nx affected:libs",
    "affected:build": "nx affected:build",
    "affected:e2e": "nx affected:e2e",
    "affected:test": "nx affected:test",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected": "nx affected",
    "format": "nx format:write",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "nx g @nx/cypress:migrate-to-cypress-10",
    "workspace-generator": "nx workspace-generator",
    "dep-graph": "nx dep-graph",
    "prebuild": "npm run svg",
    "svg": "svg-generator"
  },
  "svgGenerator": {
    "outputPath": "./libs/icon/src/ts",
    "srcPath": "./libs/icon/src/assets",
    "svgoConfig": {
      "plugins": [
        "removeDimensions",
        "cleanupAttrs",
        {
          "name": "convertColors",
          "params": {
            "currentColor": true
          }
        }
      ]
    }
  },
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "19.1.2",
    "@angular/common": "19.1.4",
    "@angular/compiler": "19.1.4",
    "@angular/core": "19.1.4",
    "@angular/forms": "19.1.4",
    "@angular/google-maps": "19.1.2",
    "@angular/platform-browser": "19.1.4",
    "@angular/platform-browser-dynamic": "19.1.4",
    "@angular/platform-server": "19.1.4",
    "@angular/router": "19.1.4",
    "@angular/service-worker": "19.1.4",
    "@angular/ssr": "19.1.5",
    "@auth0/angular-jwt": "^5.0.2",
    "@fortawesome/fontawesome-free": "^6.7.2",
    "@ng-bootstrap/ng-bootstrap": "^11.0.1",
    "@ng-select/ng-select": "^9.0.2",
    "@ngneat/dialog": "^5.1.2",
    "@ngneat/svg-icon": "^7.1.6",
    "@ngrx/component": "19.0.0",
    "@ngrx/effects": "19.0.0",
    "@ngrx/entity": "19.0.0",
    "@ngrx/operators": "19.0.0",
    "@ngrx/router-store": "19.0.0",
    "@ngrx/store": "19.0.0",
    "@ngx-lite/json-ld": "^0.6.4",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@nx/angular": "20.4.0",
    "@nx/devkit": "20.4.0",
    "@paypal/paypal-js": "6.0.0",
    "@sentry/angular": "^8.48.0",
    "@sentry/cli": "^2.40.0",
    "@stripe/stripe-js": "^5.6.0",
    "@vercel/speed-insights": "^1.0.12",
    "@vercel/toolbar": "^0.1.26",
    "angular-google-tag-manager": "^1.11.0",
    "bootstrap": "^4.6.0",
    "cookie-parser": "^1.4.5",
    "dayjs": "^1.10.6",
    "express": "^4.18.2",
    "gsap": "^3.7.1",
    "jquery": "^3.6.0",
    "jsbarcode": "^3.11.5",
    "lodash": "^4.17.21",
    "memo-decorator": "^2.0.1",
    "ng-recaptcha": "^10.0.0",
    "ngx-cookie-service": "^14.0.1",
    "ngx-filesaver": "^16.0.0",
    "ngx-infinite-scroll": "^15.0.0",
    "ngx-lightbox": "^3.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-print": "1.3.1",
    "ngx-spinner": "^14.0.0",
    "ngx-stripe": "^19.0.0",
    "qs": "^6.13.1",
    "rxjs": "7.8.0",
    "schema-dts": "^1.1.2",
    "storybook": "^8.4.7",
    "sweetalert2": "^11.3.6",
    "swiper": "8.4.7",
    "tailwindcss-animated": "^1.1.2",
    "tslib": "^2.3.0",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.5",
    "@angular-devkit/core": "19.1.5",
    "@angular-devkit/schematics": "19.1.5",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "~19.1.0",
    "@angular/compiler-cli": "19.1.4",
    "@angular/language-service": "19.1.4",
    "@ngneat/svg-generator": "^7.0.3",
    "@ngrx/schematics": "19.0.0",
    "@ngrx/store-devtools": "19.0.0",
    "@nx/cypress": "20.4.0",
    "@nx/eslint": "20.4.0",
    "@nx/eslint-plugin": "20.4.0",
    "@nx/jest": "20.4.0",
    "@nx/js": "20.4.0",
    "@nx/plugin": "20.4.0",
    "@nx/storybook": "20.4.0",
    "@nx/web": "20.4.0",
    "@nx/workspace": "20.4.0",
    "@schematics/angular": "19.1.5",
    "@semantic-release/git": "^10.0.1",
    "@semantic-release/gitlab": "^7.0.4",
    "@semantic-release/npm": "^8.0.3",
    "@storybook/addon-a11y": "^8.6.3",
    "@storybook/addon-essentials": "8.4.6",
    "@storybook/addon-interactions": "8.4.6",
    "@storybook/angular": "8.4.6",
    "@storybook/core-server": "8.4.6",
    "@storybook/jest": "^0.2.3",
    "@storybook/test-runner": "0.19.0",
    "@storybook/testing-library": "^0.2.2",
    "@swc-node/register": "~1.9.1",
    "@swc/core": "~1.5.7",
    "@swc/helpers": "~0.5.11",
    "@types/estree": "^1.0.0",
    "@types/express": "^4.17.21",
    "@types/google.maps": "^3.53.1",
    "@types/jest": "^29.5.12",
    "@types/lodash": "^4.14.191",
    "@types/node": "18.19.31",
    "@types/qs": "^6.9.7",
    "@types/webpack": "^5.28.0",
    "@typescript-eslint/eslint-plugin": "6.13.2",
    "@typescript-eslint/parser": "6.13.2",
    "@typescript-eslint/utils": "^7.16.0",
    "autoprefixer": "^10.4.0",
    "browser-sync": "^3.0.0",
    "cypress": "13.13.0",
    "dotenv": "10.0.0",
    "esbuild-visualizer": "^0.7.0",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-cypress": "2.13.4",
    "jasmine-marbles": "~0.9.2",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-preset-angular": "14.4.2",
    "jsonc-eslint-parser": "^2.1.0",
    "ng-packagr": "19.1.2",
    "nx": "20.4.0",
    "postcss": "^8.4.5",
    "postcss-url": "~10.1.3",
    "prettier": "^3.2.5",
    "prettier-plugin-tailwindcss": "^0.5.14",
    "semantic-release": "^18.0.1",
    "sitemap": "^7.1.0",
    "sitemap-generator": "^8.5.1",
    "tailwind-scrollbar": "^3.1.0",
    "tailwindcss": "^3.0.2",
    "ts-jest": "29.1.0",
    "ts-node": "10.9.1",
    "typescript": "5.7.3"
  },
  "optionalDependencies": {
    "@lmdb/lmdb-linux-x64": "*",
    "@nx/nx-linux-x64-gnu": "16.5.1",
    "@rollup/rollup-linux-x64-musl": "4.18.0"
  },
  "overrides": {
    "@ng-select/ng-select": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0",
      "@angular/forms": "^13.0.0 || ^19.0.0"
    },
    "@ng-bootstrap/ng-bootstrap": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0",
      "@angular/forms": "^13.0.0 || ^19.0.0",
      "@angular/localize": "^19.0.0",
      "@angular/compiler": "^13.0.0 || ^19.0.0",
      "@angular/compiler-cli": "^19.0.0",
      "rxjs": "^7.0.0"
    },
    "@ngx-lite/json-ld": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0",
      "@angular/forms": "^13.0.0 || ^19.0.0",
      "@angular/platform-browser": "^13.0.0 || ^19.0.0"
    },
    "angular-google-tag-manager": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/compiler": "^13.0.0 || ^19.0.0"
    },
    "ng-recaptcha": {
      "@angular/core": "^13.0.0 || ^19.0.0"
    },
    "ngx-cookie-service": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0"
    },
    "ngx-spinner": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0"
    },
    "ngx-infinite-scroll": {
      "@angular/common": "^13.0.0 || ^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0"
    },
    "jest-preset-angular": {
      "@angular-devkit/build-angular": "^19.0.0",
      "@angular/compiler-cli": "^19.0.0",
      "@angular/core": "^13.0.0 || ^19.0.0",
      "@angular/platform-browser-dynamic": "^19.0.0"
    },
    "@angular/localize": {
      "@angular/compiler": "^19.0.0",
      "@angular/compiler-cli": "^19.0.0"
    }
  }
}
