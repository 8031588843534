{
  "filtro.color.AM": "amarillos",
  "filtro.color.AZ": "azules",
  "filtro.color.AZRS": "azules/rosas",
  "filtro.color.BL": "blancos",
  "filtro.color.BLRY": "blancos rayados",
  "filtro.color.CABA": "caballos",
  "filtro.color.CHA": "chapados",
  "filtro.color.CIRC": "circonitas",
  "filtro.color.DI": "digitales",
  "filtro.color.DIAM": "diamantes",
  "filtro.color.DO": "dorados",
  "filtro.color.FU": "fucsias",
  "filtro.color.GATO": "gatos",
  "filtro.color.GN": "granates",
  "filtro.color.GR": "grises",
  "filtro.color.LI": "lilas",
  "filtro.color.MA": "marrones",
  "filtro.color.MAAG": "marrones/plata",
  "filtro.color.MF": "marfiles",
  "filtro.color.MULT": "multicolores",
  "filtro.color.NE": "negros",
  "filtro.color.NEAM": "negros amarillos",
  "filtro.color.NJ": "naranjas",
  "filtro.color.PL": "plateados",
  "filtro.color.PLAZ": "plateados azules",
  "filtro.color.PLNE": "plateados/negros",
  "filtro.color.PLRS": "plateados rosas",
  "filtro.color.RO": "rojos",
  "filtro.color.RONE": "rojos negros",
  "filtro.color.RS": "rosas",
  "filtro.color.TRAN": "transparentes",
  "filtro.color.VE": "verdes",
  "filtro.color.VI": "violetas",
  "filtro.estilo.CA": "casuales",
  "filtro.estilo.CL": "clásicos",
  "filtro.estilo.LE": "limited edition",
  "filtro.estilo.SP": "deportivos",
  "filtro.funcion.ANALO": "analógicos",
  "filtro.funcion.CRONO": "cronógrafos",
  "filtro.funcion.DIGI": "digitales",
  "filtro.funcion.SMART": "smartwatches",
  "filtro.marca.01": "Lotus",
  "filtro.marca.02": "Festina",
  "filtro.marca.03": "Jaguar",
  "filtro.marca.06": "Calypso",
  "filtro.marca.11": "Lotus Style",
  "filtro.marca.12": "Lotus Silver",
  "filtro.marca.16": "Kronaby",
  "filtro.marca.50": "Candino",
  "filtro.marca.TR": "Time Road",
  "filtro.tipoAccesorio.WRITING": "",
  "filtro.tipoJoya.ANILLO": "anillos",
  "filtro.tipoJoya.BROCHE": "broches",
  "filtro.tipoJoya.COLGANTE": "colgantes",
  "filtro.tipoJoya.COLLAR": "collares",
  "filtro.tipoJoya.CONJUNTO": "conjuntos",
  "filtro.tipoJoya.EARCUFF": "Ear Cuff",
  "filtro.tipoJoya.GEMELOS": "gemelos",
  "filtro.tipoJoya.PENDIENTES": "pendientes",
  "filtro.tipoJoya.PIERCING": "piercings",
  "filtro.tipoJoya.PULSERA": "pulseras",
  "filtro.tipoJoya.TOBILLERA": "tobilleras"
}
