{
  "reloj": {
    "h1": "Horloges",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges van de beste merken en vind het perfecte model voor jou. {{numPagina}}",
    "title": "Horloges {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Horloges {{colorCorrea}}",
    "meta": "Heeft u een zwak voor klokken {{colorCorrea}}? Ontdek de meest persoonlijke modellen van de beste merken en koop uw perfecte {{colorCorrea}} horloge. {{numPagina}}",
    "title": "Horloges {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Horloges {{colorEsfera}}",
    "meta": "Heeft u een zwak voor klokken {{colorEsfera}}? Ontdek de meest persoonlijke modellen van de beste merken en koop uw perfecte {{colorEsfera}} horloge. {{numPagina}}",
    "title": "Horloges {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Klokken {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges van de beste merken en vind het perfecte model voor jou. {{numPagina}}",
    "robots": "false",
    "title": "Klokken {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Horloges {{estilo}}",
    "meta": "In onze online winkel vindt u een brede selectie {{estilo}} horloges die perfect bij u passen. Koop uw favoriete {{estilo}} Bekijk nu online! {{numPagina}}",
    "title": "Horloges {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Horloges {{funcion}}",
    "meta": "Op zoek naar een klok {{funcion}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Op zoek naar een klok {{funcion}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "{{funcion}} | Candino"
  },
  "reloj.gama": {
    "h1": "Horloges voor {{gama}}",
    "meta": "Koop horloges voor {{gama}} die het beste bij uw stijl passen. Bekijk onze catalogus en geniet van onze beste modellen. {{numPagina}}",
    "title": "Horloges voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Horloges {{colorCorrea}} voor {{gama}}",
    "meta": "Ontdek de beste klokmodellen voor {{gama}} {{colorCorrea}}. Vind het ideale horloge voor jou in onze online catalogus. Bezoek ons nu! {{numPagina}}",
    "title": "Horloges {{colorCorrea}} voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Houdt {{colorCorrea}} in de gaten voor {{gama}}",
    "meta": "Ontdek de beste modellen van Watch {{colorCorrea}} voor {{gama}}. Vind het ideale horloge voor u in onze online catalogus, bezoek ons nu! {{numPagina}}",
    "title": "Horloges {{colorCorrea}} voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Horloges {{gama}} Wijzerplaat {{colorEsfera}}",
    "meta": "Ontdek de beste klokmodellen voor {{gama}} met kleur wijzerplaat {{colorEsfera}}. Vind het ideale horloge voor jou in onze online catalogus. Bezoek ons nu! {{numPagina}}",
    "title": "Horloges {{gama}} Wijzerplaat {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Houdt {{colorEsfera}} in de gaten voor {{gama}}",
    "meta": "Ontdek de beste modellen van Watch {{colorEsfera}} voor {{gama}}. Vind het ideale horloge voor u in onze online catalogus, bezoek ons nu! {{numPagina}}",
    "title": "Horloges {{gama}} Wijzerplaat {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.default": {
    "h1": "Klokken {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges van de beste merken en vind het perfecte model voor jou. {{numPagina}}",
    "title": "Klokken {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Horloges {{gama}} {{estilo}}",
    "meta": "Bekijk onze selectie {{gama}} {{estilo}} horloges die zijn gemaakt met de beste materialen en vind het perfecte model voor jou. {{numPagina}}",
    "title": "Horloges {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Klokken voor {{gama}} {{funcion}}",
    "meta": "Bent u op zoek naar {{gama}} {{funcion}}horloges? Bekijk ons brede assortiment horloges voor {{gama}} en kies het perfecte model voor jou. {{numPagina}}",
    "title": "Horloges voor {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Houdt {{funcion}} in de gaten voor {{gama}}",
    "meta": "Op zoek naar {{funcion}}horloges? Neem een kijkje in ons ruime assortiment horloges voor {{gama}} en kies het perfecte model voor u. {{numPagina}}",
    "title": "Horloges {{funcion}} voor {{gama}} | Candino"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch voor {{gama}}",
    "meta": "Koop nu je Smartwatch voor {{gama}}! Ontdek al onze modellen en vind de uwe. Ideaal voor elke gelegenheid.",
    "title": "Smartwatch voor {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": " {{gama}} horloges met {{materialCaja}}behuizing",
    "meta": "Schaf {{gama}} van {{materialCaja}} horloges van de beste merken aan en ervaar comfort om je pols. Bezoek nu onze website! {{numPagina}}",
    "title": " {{gama}} horloges met {{materialCaja}} {{numPagina}} kast | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Horloges voor {{gama}} met band {{materialCorrea}}",
    "meta": "Koop {{gama}} horloges met {{materialCorrea}} band van de beste merken en ervaar het comfort om je pols. Bezoek nu onze website! {{numPagina}}",
    "title": "Horloges voor {{gama}} met band {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch voor {{gama}}",
    "meta": "Koop nu uw smartwatch voor {{gama}}! Ontdek al onze modellen en vind de jouwe. Ideaal voor elke gelegenheid. {{numPagina}}",
    "title": "Smartwatch voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Houdt {{tipoCierre}} in de gaten voor {{gama}}",
    "meta": "Op onze website kunt u de ruime keuze aan Horloges {{tipoCierre}} voor {{gama}} bekijken die wij aanbieden. We hebben exclusieve stukken speciaal voor jou.",
    "title": "Horloges {{tipoCierre}} voor {{gama}} | Candino"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Waterdichte Horloges {{tipoEstanqueidad}} tot {{gama}}",
    "meta": "Op zoek naar een waterbestendig maar stijlvol horloge? Bekijk onze catalogus van waterdichte horloges {{tipoEstanqueidad}} voor {{gama}} en pronk met uw Zwitserse technologie.",
    "title": "Duikhorloges {{tipoEstanqueidad}} voor {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digitale horloges voor  Candino",
    "meta": "Ben je op zoek naar een digitaal horloge voor  Candino? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Digitale horloges voor  Candino | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Horloges voor {{gama}} met Digitaal Kwarts beweging.",
    "meta": "Ben je op zoek naar Candino horloges met Digitaal Kwarts beweging? Neem een kijkje in ons uitgebreide assortiment horloges voor  Candino en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges voor {{gama}} met Digitaal Kwarts beweging | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatische horloges voor  Candino",
    "meta": "Ben je op zoek naar een automatisch horloge voor  Candino? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Automatische horloges voor  Candino | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybride horloges voor  Candino",
    "meta": "Ben je op zoek naar een hybride horloge voor  Candino? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Hybride horloges voor  Candino |{{gama}}"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches voor  Candino",
    "meta": "Ben je op zoek naar een smartwatch voor  Candino? Bij {{gama}} hebben we een grote selectie slimme horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Smartwatches voor  Candino - Slimme horloges | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Kwarts horloges voor  Candino",
    "meta": "Ben je op zoek naar een quartz horloge voor  Candino? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Kwarts horloges voor  Candino | {{gama}}"
  },
  "reloj.IN": {
    "h1": "Horloges voor kinderen",
    "meta": "Vind de beste horloges voor kinderen in onze catalogus. Help ze de tijd te leren met de beste modellen van de beste merken. {{numPagina}}",
    "title": "Kinderhorloges {{numPagina}} | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Horloges {{estilo}} voor kinderen",
    "meta": "Vind de beste {{estilo}} horloges voor kinderen op onze website. Geef de kleintjes in huis een goed cadeau en leer ze de tijd te lezen! {{numPagina}}",
    "title": "Horloges {{estilo}} voor kinderen {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Horloges {{funcion}} voor kinderen",
    "meta": "Wilt u een {{funcion}} horloge voor een kind? Vind een verscheidenheid aan kinderhorloges {{funcion}} van de beste merken. Bezoek onze catalogus! {{numPagina}}",
    "title": "Horloges {{funcion}} voor kinderen {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Smartwatch voor kinderen",
    "meta": "Ontdek de beste Smartwatch-modellen voor kinderen en laat ze met technologie beginnen. Apps, berichten, oproepen, gps en nog veel meer {{numPagina}}",
    "title": "Smartwatch voor kinderen {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Horloges {{marca}}",
    "meta": "Mis de beste modellen van Uhren {{marca}}niet. Bezoek onze website en ontdek ons ruime aanbod aan modellen. {{numPagina}}",
    "title": "Horloges {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Houdt {{marca}} in de gaten voor {{gama}}",
    "meta": "Mis de beste modellen van Horloge {{marca}} voor {{gama}}niet. Bezoek onze website en ontdek onze ruime keuze aan modellen.",
    "title": "Horloges {{marca}} voor {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Horloges {{marca}} {{colorCorrea}} voor {{gama}}",
    "meta": "Op zoek naar een klok {{marca}} voor {{gama}} {{colorCorrea}}? Vind in onze catalogus alle kleuren horloges {{marca}} voor {{gama}}. {{numPagina}}",
    "title": "Horloges {{marca}} {{colorCorrea}} voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Horloges {{marca}} {{colorCorrea}} voor {{gama}}",
    "meta": "Bent u op zoek naar Horloges {{marca}} {{colorCorrea}} voor {{gama}}? Vind in onze catalogus alle kleuren van horloges {{marca}} voor {{gama}}.",
    "title": "{\\a6} {{marca}} {{colorCorrea}} voor {{gama}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Horloges {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Op zoek naar een klok {{marca}} voor {{gama}} {{colorEsfera}}? Vind in onze catalogus alle kleuren horloges {{marca}} voor {{gama}}. {{numPagina}}",
    "title": "Horloges {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "{{marca}} {{colorEsfera}} voor {{gama}}",
    "meta": "Bent u op zoek naar Horloges {{marca}} {{colorEsfera}} voor {{gama}}? Vind in onze catalogus alle kleuren van horloges {{marca}} voor {{gama}}.",
    "title": "{\\a6} {{marca}} {{colorEsfera}} voor {{gama}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Horloges {{marca}} {{gama}} {{estilo}}",
    "meta": "Vind het laatste nieuws in Horloges {{marca}} voor {{gama}} {{estilo}}. Horloges met persoonlijkheid en uniek design. Bezoek onze catalogus! {{numPagina}}",
    "title": "Horloges {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Horloges {{marca}} {{funcion}} voor {{gama}}",
    "meta": "Bezoek onze website en ontdek ons brede assortiment horloges {{marca}} {{funcion}} voor {{gama}}. Koop het horloge dat het beste bij u past. {{numPagina}}",
    "title": "Horloges {{marca}} {{funcion}} voor {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "{{marca}} {{funcion}} voor {{gama}}",
    "meta": "Bezoek onze website en ontdek ons brede assortiment horloges {{marca}} {{funcion}} voor {{gama}}. Koop het horloge dat het beste bij u past. {{numPagina}}",
    "title": "{{marca}} {{funcion}} voor {{gama}} |Time Road"
  },
  "reloj.marca.IN": {
    "h1": "Houdt {{marca}} in de gaten voor {{gama}}",
    "meta": "Mis de beste modellen van Horloge {{marca}} voor {{gama}}niet. Bezoek onze website en ontdek onze ruime keuze aan modellen.",
    "title": "Horloges {{marca}} voor {{gama}} | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Horloges met {{materialCaja}}kast",
    "meta": "Hou je van {{materialCaja}}horloges? Ontdek onze uitgebreide catalogus en vind het horloge dat uw pols laat stralen. {{numPagina}}",
    "title": "Horloges met {{materialCaja}} {{numPagina}} kast | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Horloges {{materialCorrea}}",
    "meta": "Houd je van horloges met een {{materialCorrea}}riempje? Ontdek onze uitgebreide catalogus en vind het horloge dat uw pols laat stralen. {{numPagina}}",
    "title": "Horloges {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Horloges {{tipoCierre}}",
    "meta": "Op zoek naar een klok {{tipoCierre}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Horloges {{tipoCorrea}}",
    "meta": "Op zoek naar een klok {{tipoCorrea}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digitale horloges",
    "meta": "Ben je op zoek naar een digitale klok? Bij Candino hebben we een grote selectie digitale klokken beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Digitale horloges | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Horloges met beweging Digitaal Kwarts",
    "meta": "Ben je op zoek naar horloges met Digitaal Kwarts beweging? Neem een kijkje in ons uitgebreide assortiment horloges voor  Candino en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges met beweging Digitaal Kwarts | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Horloges met beweging Handmatig",
    "meta": "Ben je op zoek naar horloges met Handmatig beweging? Neem een kijkje in ons uitgebreide assortiment horloges en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges met beweging Handmatig | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatische horloges",
    "meta": "Ben je op zoek naar een automatisch horloge? Bij Candino hebben we een grote selectie automatische horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Automatische horloges | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybride horloges",
    "meta": "Ben je op zoek naar een automatisch horloge? Bij Candino hebben we een grote selectie automatische horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Hybride horloges | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Slimme horloges",
    "meta": "Ben je op zoek naar een smartwatch? Bij Candino hebben we een grote selectie slimme horloges beschikbaar. Kom binnen en kies degene die het beste bij jouw stijl past.",
    "title": "Smartwatches - Slimme horloges | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Kwarts horloges",
    "meta": "Ben je op zoek naar een kwarts horloge? Bij Candino hebben we een grote selectie kwarts horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Kwarts horloges | Candino"
  }
}
