import { createFeature, createReducer, on } from '@ngrx/store';
import { RedirectByCountryActions } from './redirect-by-country.actions';
export interface RedirectByCountryState {
  storeUrl: string;
  country: string;
  countryName: string;
}

export const initialState: RedirectByCountryState = {
  storeUrl: null,
  country: null,
  countryName: null
};

export const redirectByCountryFeature = createFeature({
  name: 'redirectByCountry',
  reducer: createReducer(
    initialState,
    on(RedirectByCountryActions.getLocationSuccess, (state, { country }) => ({ ...state, country })),
    on(RedirectByCountryActions.showSuggestStoreUrl, (state, { storeUrl, countryName }) => ({ ...state, storeUrl, countryName }))
  )
});

const { name, reducer, ...selectors } = redirectByCountryFeature;
export const RedirectByCountrySelectors = selectors;
