import { ChangeDetectionStrategy, Component, Input, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BiselType, ICollectionType, IProductoCatalogo, IProductoModeloCatalogo } from '@fgp/product/interfaces';
import { IProductGTM } from '@fgp/shared/interfaces';
import { GtmEventsService } from '@fgp/shared/services/gtm-events';
import { breakpointSelectors } from '@fgp/utils/breakpoint';
import { RoutingDataService } from '@fgp/utils/routing-data';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'fgp-product-item-list-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductItemListBaseComponent {
  private gtmService = inject(GtmEventsService);
  private router = inject(Router);
  private routingDataService = inject(RoutingDataService);
  private translator = inject(TranslateService);
  store = inject(Store);

  @Input() producto: IProductoModeloCatalogo;
  @Input() cargarLazy = false;
  @Input() size?: number;
  @ViewChild('modelSwiper', { static: false }) public modelSwiper: SwiperComponent;

  languageUrl = this.routingDataService.getUrlLanguage(this.translator.currentLang);
  isDesktop$ = this.store.selectSignal(breakpointSelectors.isDesktop);
  isMobileOrTablet$ = this.store.selectSignal(breakpointSelectors.isMobileOrTablet);
  showCarousel = false;
  cartQuantity = 1;
  collectionType = ICollectionType;
  biselType = BiselType;
  ceramicCode = 'CERAMICA';

  swiperModelConfig: SwiperOptions = {
    observer: true,
    observeParents: true,
    slidesPerView: 4
  };

  obtenerInformacionProducto(item: IProductoCatalogo) {
    this.producto.productoPrincipal = item;
  }

  gtmProductClick() {
    const products = [
      {
        id: this.producto?.productoPrincipal?.idProducto,
        reference: this.producto?.productoPrincipal?.codigo,
        name: this.producto?.productoPrincipal?.nombre,
        price: this.producto?.productoPrincipal?.pvp,
        path: this.producto?.productoPrincipal?.urlPathProducto,
        brand: this.producto?.productoPrincipal?.nombreMarca,
        category: this.producto?.productoPrincipal?.coleccion,
        variant: this.producto?.productoPrincipal?.tipoProducto,
        list_name: this.router.url
      }
    ] as IProductGTM[];
    this.gtmService.productClickEvent(products);
  }
}
