import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { BasketActions } from '@fgp/basket/data-access';
import { AlertType } from '@fgp/shared/interfaces';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { AlertService } from '@fgp/utils/alert';
import { RedirectService } from '@fgp/utils/redirect';
import { ValidarResponse } from '@fgp/utils/validate-response';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  isBrowser = this.deviceDetectorService.isBrowser();

  constructor(
    private adminSession: AdminSessionService,
    private alertService: AlertService,
    private redirectService: RedirectService,
    @Inject(APP_CONFIG) private appConfig,
    private deviceDetectorService: DeviceDetectorService,
    private spinner: NgxSpinnerService,
    private store: Store
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.appConfig.apiPublicUrl)) {
      return next.handle(req).pipe(
        tap({
          next: event => {
            if (event instanceof HttpResponse) {
              if (ValidarResponse.notFound(event.body)) {
                this.redirectService.redirect404();
              } else if (ValidarResponse.redirectUrl(event.body)) {
                const url = new URL(event.body.content);
                this.redirectService.redirect(url.pathname);
              }
            }
            return event;
          },
          error: (error: HttpErrorResponse) => {
            let errorMessage = '';
            if (this.isBrowser && error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
              if (this.appConfig.production) {
                console.log(errorMessage);
              } else {
                alert(errorMessage);
              }
            } else {
              // server-side error
              switch (error.status) {
                case 401: {
                  console.log('Error autenticacion');
                  this.adminSession.logout();
                  this.store.dispatch(BasketActions.clearState());
                  this.spinner.hide();
                  break;
                }
                case 404: {
                  console.log('Not found 404');
                  return;
                  //break;
                }
                default: {
                  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                  if (this.appConfig.production) {
                    console.log(errorMessage);
                  } else {
                    if (this.isBrowser) {
                      this.alertService.getInformationAlert(errorMessage, AlertType.ERROR);
                    } else {
                      console.log(errorMessage);
                    }
                  }
                  break;
                }
              }
            }
            return throwError(() => error);
          }
        })
      );
    }
    return next.handle(req);
  }
}
