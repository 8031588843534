export * from './lib/shared-http-interceptors.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './lib/auth-interceptor.service';
import { ErrorInterceptorCmsService } from './lib/error-interceptor-cms.service';
import { ErrorInterceptorService } from './lib/error-interceptor.service';

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorCmsService,
    multi: true
  }
];
