import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProductoCatalogo } from '@fgp/product/interfaces';
import { UtilWebpModule } from '@fgp/shared/pipes/util-webp';
import { GtmEventsService } from '@fgp/shared/services/gtm-events';
import { SpecificationsModule } from '@fgp/utils/specifications';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { SpecificationsPipe } from 'libs/utils/specifications/src/lib/specifications.pipe';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';

@Component({
  selector: 'fgp-product-swiper',
  imports: [CommonModule, TranslateModule, UtilWebpModule, SwiperModule, SpecificationsModule, SvgIconComponent],
  providers: [SpecificationsPipe],
  templateUrl: './product-swiper.component.html',
  styleUrl: './product-swiper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSwiperComponent {
  @Input({ required: true }) productos!: IProductoCatalogo[];
  @Input({ required: true }) showCarousel!: boolean;
  @Input({ required: true }) codigo!: string;
  @Input({ required: true }) swiperModelConfig!: SwiperOptions;
  @Input() showModels = false;
  @ViewChild('modelSwiper', { static: false }) public modelSwiper!: SwiperComponent;
  @Output() selectedProduct = new EventEmitter<IProductoCatalogo>();

  private gtmEventService = inject(GtmEventsService);
  private activateRoute = inject(ActivatedRoute);

  obtenerInformacionProducto(item: IProductoCatalogo) {
    let categoria;
    if (this.activateRoute.snapshot.data['id'] === 'home') {
      categoria = 'home';
    }
    if (!categoria) {
      const componentName = this.activateRoute.snapshot?.component?.name;
      const landingsComponentName = '_FeatureLandingCmsComponent';
      categoria = componentName === landingsComponentName ? 'generic' : 'category';
      this.gtmEventService.emitProductVarianteEvent(categoria, item.codigo);
    }
    this.selectedProduct.emit(item);
  }

  onNextCarousel() {
    this.modelSwiper.swiperRef.slideNext();
  }

  onPrevCarousel() {
    this.modelSwiper.swiperRef.slidePrev();
  }
}
