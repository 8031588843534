{
  "filtro.color.AM": "Geel",
  "filtro.color.AZ": "blauw",
  "filtro.color.AZRS": "blauw / roze",
  "filtro.color.BL": "blanken",
  "filtro.color.BLRY": "gestreepte doelen",
  "filtro.color.CABA": "paarden",
  "filtro.color.CHA": "VERGULD",
  "filtro.color.CIRC": "zirkonen",
  "filtro.color.DI": "Digitaal",
  "filtro.color.DIAM": "DIAMANTEN",
  "filtro.color.DO": "GOUDEN",
  "filtro.color.FU": "fuchsia's",
  "filtro.color.GATO": "katten",
  "filtro.color.GN": "granaten",
  "filtro.color.GR": "GRIJS",
  "filtro.color.LI": "seringen",
  "filtro.color.MA": "bruin",
  "filtro.color.MAAG": "BRUIN / ZILVER",
  "filtro.color.MF": "ivories",
  "filtro.color.MULT": "VEELKLEURIG",
  "filtro.color.NE": "zwarten",
  "filtro.color.NEAM": "gele zwarten",
  "filtro.color.NJ": "sinaasappels",
  "filtro.color.PL": "zilverachtig",
  "filtro.color.PLAZ": "zilver blauw",
  "filtro.color.PLNE": "ZILVER ZWART",
  "filtro.color.PLRS": "zilveren rozen",
  "filtro.color.RO": "Rood",
  "filtro.color.RONE": "zwart rood",
  "filtro.color.RS": "rozen",
  "filtro.color.TRAN": "Transparant",
  "filtro.color.VE": "Groen",
  "filtro.color.VI": "viooltjes",
  "filtro.estilo.CA": "GEWOONTJES",
  "filtro.estilo.CL": "klassiekers",
  "filtro.estilo.LE": "BEPERKTE EDITIE",
  "filtro.estilo.SP": "sport-",
  "filtro.funcion.ANALO": "Analoog",
  "filtro.funcion.CRONO": "chronografen",
  "filtro.funcion.DIGI": "Digitaal",
  "filtro.funcion.SMART": "smartwatches",
  "filtro.marca.01": "Lotus",
  "filtro.marca.02": "Festina",
  "filtro.marca.03": "Jaguar",
  "filtro.marca.06": "Calypso",
  "filtro.marca.11": "Lotus Style",
  "filtro.marca.12": "Lotus Silver",
  "filtro.marca.16": "Kronaby",
  "filtro.marca.50": "Candino",
  "filtro.marca.TR": "Time Road",
  "filtro.tipoAccesorio.WRITING": "",
  "filtro.tipoJoya.ANILLO": "Ringen",
  "filtro.tipoJoya.BROCHE": "broches",
  "filtro.tipoJoya.COLGANTE": "hangers",
  "filtro.tipoJoya.COLLAR": "kettingen",
  "filtro.tipoJoya.CONJUNTO": "sets",
  "filtro.tipoJoya.EARCUFF": "oor manchet",
  "filtro.tipoJoya.GEMELOS": "Tweelingen",
  "filtro.tipoJoya.PENDIENTES": "hellingen",
  "filtro.tipoJoya.PIERCING": "piercings",
  "filtro.tipoJoya.PULSERA": "ARMBANDEN",
  "filtro.tipoJoya.TOBILLERA": "enkelbandje"
}
