{
  "title": "candino.com",
  "description": "Ecommerce watches of FESTINA LOTUS GROUP S.A.",
  "keywords": "candino, correa cuero, correa acero, automatico, acero, Swiss made",
  "image": "",
  "url": "",
  "robots": true,
  "canonical": true,
  "listaUrlPaths": "",
  "site": "@CandinoWatchUK",
  "siteName": "candino.com"
}
