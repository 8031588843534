import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private translate: TranslateService,
    public adminSessionService: AdminSessionService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.appConfig.apiPublicUrl)) {
      req = req.clone({
        headers: this.addHeaders(req.headers)
      });
    }
    return next.handle(req);
  }

  private addHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Tienda-Codigo', this.appConfig.tiendaCodigoAES);
    headers = headers.append('Accept-Language', this.translate.currentLang ?? this.appConfig.idiomaPorDefecto);
    headers = headers.append('Accept-Country', this.adminSessionService.getCountry() ?? this.appConfig.countrydefault);
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');

    return headers;
  }
}
