<article class="card card-plain producto-catalogo" (mouseover)="showCarousel = true" (mouseleave)="showCarousel = false">
  <fgp-favorite-icon [product]="producto"></fgp-favorite-icon>
  <a
    (click)="gtmProductClick()"
    routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
    [queryParams]="
      collectionType[collectionType.private] === producto?.productoPrincipal?.coleccionType
        ? {
            val1: producto?.productoPrincipal?.idColeccion
          }
        : ''
    "
    class="text-center d-block position-relative bg-light-grayish-orange-color"
    [title]="producto?.productoPrincipal?.nombre"
  >
    @if (producto?.novedad) {
      <p class="novedad bg-gris11 text-uppercase paragraph-3 mx-1 color-white">
        {{ 'detalle.producto.festina.novedad' | translate }}
      </p>
    }

    <picture>
      <source [srcset]="producto?.productoPrincipal?.urlImg | webp" type="image/webp" />
      <img
        loading="lazy"
        alt="{{ producto?.productoPrincipal?.nombre }}"
        class="img-fluid mx-auto"
        [ngClass]="{ 'w-50': !size, 'w-75': size === 75 }"
        [src]="producto?.productoPrincipal?.urlImg"
        height="212"
        width="127"
      />
    </picture>
    <fgp-product-icons [product]="producto" />
    @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
      <p class="descuento px-1 white-color mx-1">
        - {{ producto?.productoPrincipal?.pvp | discount: producto?.productoPrincipal?.pvpAnterior | number: '1.0-0' }}%
      </p>
    }
  </a>
  @if (isDesktop$()) {
    <fgp-product-swiper
      [productos]="producto?.productos"
      [showCarousel]="showCarousel && producto.productos.length > 1"
      [codigo]="producto?.productoPrincipal?.codigo"
      [swiperModelConfig]="swiperModelConfig"
      (selectedProduct)="obtenerInformacionProducto($event)"
    ></fgp-product-swiper>
  }

  <div class="card-body ml-2">
    <div class="card-description mt-2 text-center">
      <a
        (click)="gtmProductClick()"
        routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
        [queryParams]="{ val1: producto?.productoPrincipal?.idColeccion }"
        [title]="producto?.productoPrincipal?.nombre"
      >
        <h3 class="paragraph-3 text-uppercase font-weight-bolder">
          {{ producto?.productoPrincipal?.nombre }}
        </h3>
      </a>
    </div>
    <div class="d-flex flex-column mt-1 price">
      <p
        class="paragraph-2 dark-gray-color font-weight-bolder"
        [ngClass]="{ 'text-danger': producto?.productoPrincipal?.mostrarPvpAnterior }"
      >
        {{ producto?.productoPrincipal?.pvp | utilCurrency }}
        @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
          <span class="p strike pl-3">
            {{ producto?.productoPrincipal?.pvpAnterior | utilCurrency }}
          </span>
        }
      </p>
      @if (producto?.productoPrincipal?.preSale) {
        <fgp-ui-pre-sale-chip />
      }
    </div>
    @if (producto.productos.length > 1 && isDesktop$()) {
      <p class="dark-gray-color fade cantidad-modelos paragraph-3 font-weight-light">
        {{ producto.productos?.length }}
        <span class="paragraph-3 font-weight-light">{{
          (producto.productos.length === 1 ? 'generic.strings.modelo' : 'generic.strings.modelos') | translate
        }}</span>
      </p>
    }
  </div>
</article>
