import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { calypsoIcons } from './ts/calypso/index';
import { candinoIcons } from './ts/candino/index';
import { festinaIcons } from './ts/festina/index';
import { jaguarIcons } from './ts/jaguar/index';
import { kronabyIcons } from './ts/kronaby/index';
import { lotusIcons } from './ts/lotus/index';
import { maitresIcons } from './ts/maitres/index';
import { perreletIcons } from './ts/perrelet/index';
import { sharedIcons } from './ts/shared';
import { timeroadIcons } from './ts/timeroad/index';
export * from './ts/calypso/index';
export * from './ts/candino/index';
export * from './ts/festina/index';
export * from './ts/jaguar/index';
export * from './ts/kronaby/index';
export * from './ts/lotus/index';
export * from './ts/maitres/index';
export * from './ts/perrelet/index';
export * from './ts/timeroad/index';

export const allIcons = [...sharedIcons];
export type Icon = (typeof allIcons)[number]['name'];
export const defaultConfig = {
  icons: allIcons,
  sizes: {
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '32px',
    xl: '48px',
    xxl: '65px'
  }
};
export const iconProvider = provideSvgIconsConfig(defaultConfig);
export const iconProviderCalypso = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...calypsoIcons]
});
export const iconProviderCandino = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...candinoIcons]
});
export const iconProviderFestina = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...festinaIcons]
});
export const iconProviderJaguar = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...jaguarIcons]
});
export const iconProviderKronaby = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...kronabyIcons]
});
export const iconProviderLotus = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...lotusIcons]
});
export const iconProviderMaitres = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...maitresIcons]
});
export const iconProviderPerrelet = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...perreletIcons]
});
export const iconProviderTimeroad = provideSvgIconsConfig({
  ...defaultConfig,
  icons: [...defaultConfig.icons, ...timeroadIcons]
});
