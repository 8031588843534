{
  "reloj": {
    "h1": "Orologi",
    "meta": "Cerchi un orologio unico ed elegante? Scopri i nostri orologi delle migliori marche e trova il modello perfetto per te. {{numPagina}}",
    "title": "Orologi {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Orologi {{colorCorrea}}",
    "meta": "Hai un debole per gli orologi {{colorCorrea}}? Scopri i modelli più personali delle migliori marche e acquista il tuo orologio {{colorCorrea}} perfetto. {{numPagina}}",
    "title": "Orologi {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Orologi {{colorEsfera}}",
    "meta": "Hai un debole per gli orologi {{colorEsfera}}? Scopri i modelli più personali delle migliori marche e acquista il tuo orologio {{colorEsfera}} perfetto. {{numPagina}}",
    "title": "Orologi {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Orologi {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Cerchi un orologio unico ed elegante? Scopri i nostri orologi delle migliori marche e trova il modello perfetto per te. {{numPagina}}",
    "robots": "false",
    "title": "Orologi {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Orologi {{estilo}}",
    "meta": "Nel nostro negozio online troverai un'ampia selezione di {{estilo}} orologi perfetti per te. Acquista ora il tuo {{estilo}} preferito Guarda online! {{numPagina}}",
    "title": "Orologi {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Orologi {{funcion}}",
    "meta": "Cerchi un orologio {{funcion}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Cerchi un orologio {{funcion}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "{{funcion}} | Candino"
  },
  "reloj.gama": {
    "h1": "Orologi per {{gama}}",
    "meta": "Cerchi un orologio adatto al tuo stile di vita? Consulta il nostro catalogo di orologi per {{gama}} e sfoggia il modello fabbricato in Svizzera di eccellente qualità.",
    "title": "Orologi per {{gama}} | Orologio Svizzero Candino"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Orologi {{colorCorrea}} per {{gama}}",
    "meta": "Scopri i migliori modelli di orologio per {{gama}} {{colorCorrea}}. Trova l'orologio ideale per te nel nostro catalogo online. Venite a trovarci ora! {{numPagina}}",
    "title": "Orologi {{colorCorrea}} per {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Orologi per {{gama}} con {{colorCorrea}}",
    "meta": "Cerchi un orologio che si adatti al tuo stile di vita? Consulta il nostro catalogo di orologi con cinturino {{colorCorrea}} per {{gama}} e sfoggia il modello fabbricato in Svizzera.",
    "title": "Orologi per {{gama}} con {{colorCorrea}} | Candino"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Orologi {{gama}} Quadrante {{colorEsfera}}",
    "meta": "Scopri i migliori modelli di orologio per {{gama}} con quadrante colorato {{colorEsfera}}. Trova l'orologio ideale per te nel nostro catalogo online. Venite a trovarci ora! {{numPagina}}",
    "title": "Orologi {{gama}} Quadrante {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Orologi con Quadrante colorato {{colorEsfera}} da {{gama}}",
    "meta": "Cerchi un orologio che si adatti al tuo stile di vita?Consulta il nostro catalogo di orologi con quadrante {{colorEsfera}} per {{gama}} e sfoggia il modello fabbricato in Svizzera",
    "title": "Orologi con Quadrante colorato {{colorEsfera}} da {{gama}} | Candino"
  },
  "reloj.gama.default": {
    "h1": "Orologi {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Cerchi un orologio unico ed elegante? Scopri i nostri orologi delle migliori marche e trova il modello perfetto per te. {{numPagina}}",
    "title": "Orologi {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Orologi {{gama}} {{estilo}}",
    "meta": "Esplora la nostra selezione di {{gama}} {{estilo}} orologi realizzati con i migliori materiali e trova il modello perfetto per te. {{numPagina}}",
    "title": "Orologi {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Orologi per {{gama}} {{funcion}}",
    "meta": "Stai cercando {{gama}} {{funcion}}orologi? Dai un'occhiata alla nostra vasta gamma di orologi per {{gama}} e scegli il modello perfetto per te. {{numPagina}}",
    "title": "Orologi per {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Orologi {{funcion}} per {{gama}}",
    "meta": "Cerchi un orologio adatto al tuo stile di vita? Consulta il nostro catalogo di orologi {{funcion}} per {{gama}} e sfoggia il modello fabbricato in Svizzera",
    "title": "Orologi {{funcion}} per {{gama}}| Orologio Svizzero Candino"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch per {{gama}}",
    "meta": "Ottieni ora il tuo Smartwatch per {{gama}}! Scopri tutti i nostri modelli e trova il tuo. Ideale per ogni occasione.",
    "title": "Smartwatch per {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": " {{gama}} orologi con cassa {{materialCaja}}",
    "meta": "Acquista {{gama}} da {{materialCaja}} orologi delle migliori marche e prova il comfort al tuo polso. Visitate subito il nostro sito web! {{numPagina}}",
    "title": " {{gama}} orologi con cassa {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Orologi per {{gama}} con {{materialCorrea}}",
    "meta": "Cerchi un orologio che si adatti al tuo stile di vita? Consulta il nostro catalogo di orologi {{materialCorrea}} per {{gama}} e sfoggia il modello fabbricato in Svizzera",
    "title": "Orologi per {{gama}} con {{materialCorrea}} Candino"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch per {{gama}}",
    "meta": "Acquista subito il tuo smartwatch per {{gama}}! Scopri tutti i nostri modelli e trova il tuo. Ideale per ogni occasione. {{numPagina}}",
    "title": "Smartwatch per {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Orologi per {{gama}} con {{tipoCierre}}",
    "meta": "Cerchi un orologio che si adatti al tuo stile di vita? Consulta il nostro catalogo di orologi con {{tipoCierre}} per {{gama}} e sfoggia il modello fabbricato in Svizzera.",
    "title": "Orologi per {{gama}} con {{tipoCierre}} | Candino"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Orologi subacquei {{tipoEstanqueidad}} per {{gama}}",
    "meta": "Cerchi un orologio resistente all'acqua ma elegante? Consulta il nostro catalogo di orologi subacquei {{tipoEstanqueidad}} per {{gama}} e metti in mostra la tecnologia svizzera.",
    "title": "Orologi subacquei {{tipoEstanqueidad}} per {{gama}} Candino"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Orologi digitali per {{gama}}",
    "meta": "Stai cercando un orologio digitale per {{gama}}? Da Candino abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi digitali per {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Orologi per {{gama}} con movimento Quarzo Digitale",
    "meta": "Stai cercando Orologi da {{gama}} con movimento Quarzo Digitale? Dai un'occhiata alla nostra vasta gamma di orologi per {{gama}} e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi per {{gama}} con movimento Quarzo Digitale | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Orologi automatici per {{gama}}",
    "meta": "Stai cercando un orologio automatico per {{gama}}? Da Candino abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi automatici per {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Orologi ibridi per{{gama}}",
    "meta": "Stai cercando un orologio ibrido per {{gama}}? Da Candino abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi ibridi per{{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches per {{gama}}",
    "meta": "Stai cercando uno smartwatch per {{gama}}? Da Candino abbiamo una vasta selezione di orologi intelligenti disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Smartwatches per {{gama}} - Orologi intelligenti | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Orologi al quarzo per {{gama}}",
    "meta": "Stai cercando un orologio al quarzo per {{gama}}? Da Candino abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi al quarzo per {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Orologi per bambini",
    "meta": "Trova i migliori orologi per bambini nel nostro catalogo. Aiutali a imparare il tempo con i migliori modelli delle migliori marche. {{numPagina}}",
    "title": "Orologi per bambini {{numPagina}} | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Orologi {{estilo}} per bambini",
    "meta": "Trova i migliori {{estilo}} orologi per bambini sul nostro sito web. Fai un bel regalo ai più piccoli di casa e insegna loro a leggere l'ora! {{numPagina}}",
    "title": "Orologi {{estilo}} per bambini {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Orologi {{funcion}} per bambini",
    "meta": "Vuoi un {{funcion}} orologio per un bambino? Trova una varietà di orologi per bambini {{funcion}} delle migliori marche. Visitate il nostro catalogo! {{numPagina}}",
    "title": "Orologi {{funcion}} per bambini {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Smartwatch per bambini",
    "meta": "Scopri i migliori modelli di smartwatch per bambini e falli iniziare a interagire con la tecnologia. App, messaggistica, chiamate, GPS e molto altro {{numPagina}}",
    "title": "Smartwatch per bambini {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Orologi {{marca}}",
    "meta": "Non perdere i migliori modelli di Orologio {{marca}}. Visita il nostro sito web e scopri la nostra vasta gamma di modelli. {{numPagina}}",
    "title": "Orologi {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Orologi {{marca}} per {{gama}}",
    "meta": "Non perdere i migliori modelli di Orologio {{marca}} per {{gama}}. Visita il nostro sito e scopri la nostra vasta gamma di modelli.",
    "title": "Orologi {{marca}} per {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Orologi {{marca}} {{colorCorrea}} per {{gama}}",
    "meta": "Cerchi un orologio {{marca}} per {{gama}} {{colorCorrea}}? Trova nel nostro catalogo tutti i colori degli orologi {{marca}} per {{gama}} {{numPagina}}",
    "title": "Orologi {{marca}} {{colorCorrea}} per {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Orologi {{marca}} {{colorCorrea}} per {{gama}}",
    "meta": "Stai cercando Orologi {{marca}} {{colorCorrea}} per {{gama}}? Trova nel nostro catalogo tutti i colori di orologi {{marca}} per {{gama}}.",
    "title": "Orologi {{marca}} {{colorCorrea}} per {{gama}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Orologi {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Cerchi un orologio {{marca}} per {{gama}} {{colorEsfera}}? Trova nel nostro catalogo tutti i colori degli orologi {{marca}} per {{gama}}. {{numPagina}}",
    "title": "Orologi {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "Orologi {{marca}} {{colorEsfera}} per {{gama}}",
    "meta": "Stai cercando Orologi {{marca}} {{colorEsfera}} per {{gama}}? Trova nel nostro catalogo tutti i colori di orologi {{marca}} per {{gama}}.",
    "title": "Orologi {{marca}} {{colorEsfera}} per {{gama}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Orologi {{marca}} {{gama}} {{estilo}}",
    "meta": "Trova le ultime notizie in Orologi {{marca}} per {{gama}} {{estilo}}. Orologi con personalità e design unico. Visitate il nostro catalogo! {{numPagina}}",
    "title": "Orologi {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Orologi {{marca}} {{funcion}} per {{gama}}",
    "meta": "Visita il nostro sito web e scopri la nostra vasta gamma di orologi {{marca}} {{funcion}} per {{gama}}. Ottieni l'orologio più adatto a te. {{numPagina}}",
    "title": "Orologi {{marca}} {{funcion}} per {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "Orologi {{marca}} {{funcion}} per {{gama}}",
    "meta": "Visita il nostro sito web e scopri la nostra vasta gamma di orologi {{marca}} {{funcion}} per {{gama}}. Ottieni l'orologio più adatto a te. {{numPagina}}",
    "title": "Orologi {{marca}} {{funcion}} per {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "Orologi {{marca}} per {{gama}}",
    "meta": "Non perdere i migliori modelli di Orologio {{marca}} per {{gama}}. Visita il nostro sito e scopri la nostra vasta gamma di modelli.",
    "title": "Orologi {{marca}} per {{gama}} | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Orologi con custodia {{materialCaja}}",
    "meta": "Ti piacciono gli {{materialCaja}}orologi? Scopri il nostro ampio catalogo e trova l'orologio che fa brillare il tuo polso. {{numPagina}}",
    "title": "Orologi con custodia {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Orologi {{materialCorrea}}",
    "meta": "Ti piacciono gli orologi con cinturino {{materialCorrea}}? Scopri il nostro ampio catalogo e trova l'orologio che fa brillare il tuo polso. {{numPagina}}",
    "title": "Orologi {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Orologi {{tipoCierre}}",
    "meta": "Cerchi un orologio {{tipoCierre}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Orologi {{tipoCorrea}}",
    "meta": "Cerchi un orologio {{tipoCorrea}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Orologi digitali",
    "meta": "Stai cercando un orologio digitale? In Candino abbiamo una vasta selezione di orologi digitali disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi digitali | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Orologi con movimento Quarzo Digitale",
    "meta": "Stai cercando orologi con movimento Quarzo Digitale? Dai un'occhiata alla nostra vasta gamma di orologi per {Genero} e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi con movimento Quarzo Digitale | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Orologi con movimento Manuale",
    "meta": "Stai cercando orologi con movimento Manuale? Dai un'occhiata alla nostra vasta gamma di orologi per Candino e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi con movimento Manuale | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Orologi automatici",
    "meta": "Stai cercando un orologio automatico? In Candino abbiamo una vasta selezione di orologi automatici disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi automatici | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Orologi ibridi",
    "meta": "Stai cercando un orologio automatico? In Candino abbiamo una vasta selezione di orologi automatici disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi ibridi | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Stai cercando uno smartwatch? Da Candino abbiamo una vasta selezione di orologi intelligenti disponibili. Entra e scegli quello che si adatta meglio al tuo stile.",
    "title": "Smartwatches - Orologi intelligenti | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Orologi al quarzo",
    "meta": "Stai cercando un orologio al quarzo? In Candino abbiamo una vasta selezione di orologi al quarzo disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi al quarzo | Candino"
  }
}
