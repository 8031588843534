// @ts-nocheck
/* eslint-disable */
import { backIcon } from './back';
import { buscadorIcon } from './buscador';
import { cestaIcon } from './cesta';
import { favoriteIcon } from './favorite';
import { heartRellenoIcon } from './heart-relleno';
import { heartIcon } from './heart';
import { heart1Icon } from './heart1';
export const perreletIcons = [backIcon, buscadorIcon, cestaIcon, favoriteIcon, heartRellenoIcon, heartIcon, heart1Icon];
