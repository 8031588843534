/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, inject, signal } from '@angular/core';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { GoToTopComponent } from 'core/ui-system';

@Component({
    selector: 'fgp-feature-go-to-top',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, GoToTopComponent],
    template: ` <fgp-go-to-top (scrollToTop)="goToTop()"></fgp-go-to-top> `
})
export class FeatureGoToTopComponent {
  deviceDetectorService = inject(DeviceDetectorService);

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.isBrowser) {
      if (this.visibilityPoint() === -1) {
        this.updateVisibilityPoint();
      }
      this.displayStyle = window.scrollY <= this.visibilityPoint() ? 'none' : 'block';
    }
  }
  @HostBinding('style.display') displayStyle = 'none';

  isBrowser = this.deviceDetectorService.isBrowser();
  showButton = signal<boolean>(false);
  visibilityPoint = signal<number>(-1);

  updateVisibilityPoint() {
    const aQuarterPageHeight = document.documentElement.scrollHeight / 4;
    this.visibilityPoint.set(aQuarterPageHeight);
  }
  goToTop() {
    if (this.isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
