{
  "reloj": {
    "h1": "Montres",
    "meta": "A la recherche d'une montre unique et élégante? Découvrez nos montres des meilleures marques et trouvez le modèle parfait pour vous. {{numPagina}}",
    "title": "Découvrez nos nouveautés dans les montres | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Montres avec bracelet {{colorCorrea}}",
    "meta": "Vous avez un faible pour les montres en {{colorCorrea}}? Obtenez votre montre parfaite. Découvrez les meilleurs modèles, visitez-nous!",
    "title": "Les meilleures montres {{colorCorrea}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Montres avec sphère {{colorEsfera}}",
    "meta": "Avez-vous un faible pour montres avec sphère {{colorEsfera}}? Découvrez les modèles des meilleures marques et obtenez votre argent montre parfaite.",
    "title": "Montres avec sphère {{colorEsfera}} | Candino"
  },
  "reloj.default": {
    "h1": "Montres {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "A la recherche d'une montre unique et élégante? Découvrez nos montres des meilleures marques et trouvez le modèle parfait pour vous. {{numPagina}}",
    "robots": "false",
    "title": "Montres {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Montres {{estilo}}",
    "meta": "Dans notre boutique en ligne, vous trouverez une large sélection de {{estilo}} montres parfaites pour vous. Achetez votre {{estilo}} regarder en ligne préféré dès maintenant! {{numPagina}}",
    "title": "Montres {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Montres {{funcion}}",
    "meta": "Vous recherchez une horloge {{funcion}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite pour vous. Consultez notre catalogue maintenant {{numPagina}}",
    "title": "Montres {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "Montre Connectée",
    "meta": "Trouvez votre nouvelle Smartwatch préférée. Découvrez tous nos modèles et faites-vous plaisir! Visitez notre site Web maintenant et consultez notre catalogue!",
    "title": "Montre Connectée | Candino"
  },
  "reloj.gama": {
    "h1": "Montres pour {{gama}}",
    "meta": "Vous cherchez une montre adaptée à votre style de vie ? Consultez notre catalogue de montres pour {{gama}} et vantez-vous d'un modèle 'made in Switzerland'.",
    "title": "Montres pour {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Montres {{colorCorrea}} pour {{gama}}",
    "meta": "Découvrez les meilleurs modèles d'horloge pour {{gama}} {{colorCorrea}}. Trouvez la montre idéale pour vous dans notre catalogue en ligne. Visitez-nous maintenant! {{numPagina}}",
    "title": "Montres {{colorCorrea}} pour {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Montres pour {{gama}} {{colorCorrea}}",
    "meta": "Vous cherchez une montre qui correspond à votre style ? Consultez notre catalogue de montres pour {{gama}} {{colorCorrea}} et montrez le modèle fabriqué en Suisse.",
    "title": "Montres pour {{gama}} {{colorCorrea}} | Candino Swiss Watch"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Montres {{gama}} Cadran {{colorEsfera}}",
    "meta": "Découvrez les meilleurs modèles d'horloge pour {{gama}} à cadran de couleur {{colorEsfera}}. Trouvez la montre idéale pour vous dans notre catalogue en ligne. Visitez-nous maintenant! {{numPagina}}",
    "title": "Montres {{gama}} Cadran {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Montres pour {{gama}} avec cadran {{colorEsfera}}",
    "meta": "Vous cherchez une montre qui correspond à votre style ? Consultez notres montres pour {{gama}} avec cadran {{colorEsfera}} et montrez le modèle fabriqué en Suisse.",
    "title": "Montres pour {{gama}} avec cadran {{colorEsfera}} | Candino Swiss Watch"
  },
  "reloj.gama.default": {
    "h1": "Montres {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "A la recherche d'une montre unique et élégante? Découvrez nos montres des meilleures marques et trouvez le modèle parfait pour vous. {{numPagina}}",
    "title": "Montres {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Montres {{gama}} {{estilo}}",
    "meta": "Découvrez notre sélection de {{gama}} {{estilo}} montres fabriquées avec les meilleurs matériaux et trouvez le modèle qui vous convient. {{numPagina}}",
    "title": "Montres {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Montres pour {{gama}} {{funcion}}",
    "meta": "Êtes-vous à la recherche de {{gama}} {{funcion}}montres? Jetez un œil à notre large gamme de montres pour {{gama}} et choisissez le modèle qui vous convient. {{numPagina}}",
    "title": "Montres pour {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Montres {{funcion}} pour {{gama}}",
    "meta": "Vous cherchez une montre {{funcion}} adaptée à votre style de vie ? Consultez notre catalogue de montres {{funcion}} pour {{gama}} 'made in Switzerland'.",
    "title": "Montres {{funcion}} pour {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Montres pour {{gama}} Connectées",
    "meta": "Obtenez votre Montre Connectée pour {{gama}} maintenant! Découvrez tous nos modèles et trouvez le vôtre. Idéal pour toute occasion.",
    "title": "Montres pour {{gama}} Connectées | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "montres {{gama}} avec boîtier {{materialCaja}}",
    "meta": "Obtenez {{gama}} de {{materialCaja}} montres des meilleures marques et profitez du confort au poignet. Visitez notre site Web et consultez notre catalogue!",
    "title": " {{gama}} montres avec {{materialCaja}} {{numPagina}} boîtier | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Montres pour {{gama}} en {{materialCorrea}}",
    "meta": "Vous cherchez une montre adaptée à votre style de vie ? Consultez notre catalogue de montres {{materialCorrea}} pour {{gama}} fabriqués en Suisse.",
    "title": "Montres pour {{gama}} en {{materialCorrea}} | Candino Swiss Watch"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch pour {{gama}}",
    "meta": "Obtenez votre montre intelligente pour {{gama}}maintenant! Découvrez tous nos modèles et trouvez le vôtre. Idéal pour toute occasion. {{numPagina}}",
    "title": "Smartwatch pour {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Montres pour {{gama}} {{tipoCierre}}",
    "meta": "Vous cherchez une montre qui correspond à votre style ? Consultez notres montres pour {{gama}} {{tipoCierre}} et montrez le modèle fabriqué en Suisse.",
    "title": "Montres pour {{gama}} {{tipoCierre}} | Candino Swiss Watch"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Montres submersibles pour {{gama}}",
    "meta": "Vous cherchez une montre étanche mais élégante ? Consultez notre catalogue de montres submersibles pour {{gama}} et montrez la technologie suisse.",
    "title": "Montres submersibles pour {{gama}}"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Montres numériques pour {{gama}}",
    "meta": "Cherchez-vous une montre numérique pour {{gama}}? ChezCandino, nous avons une grande sélection de montres disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres numériques pour {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Montres pour {{gama}} avec mouvement Digital Quartz",
    "meta": "Vous recherchez des montres pour {{gama}} avec un mouvement Digital Quartz? Jetez un œil à notre large gamme de montres pour {{gama}} et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres pour {{gama}} avec mouvement Digital Quartz | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Montres automatiques pour {{gama}}",
    "meta": "Cherchez-vous une montre automatique pour {{gama}}? ChezCandino, nous avons une grande sélection de montres disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres automatiques pour {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Montres hybrides pour{{gama}}",
    "meta": "Vous recherchez une montre hybride pour {{gama}}? ChezCandino, nous avons une grande sélection de montres disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres hybrides pour{{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Montres intelligentes pour {{gama}}",
    "meta": "Vous recherchez une montre connectée pour {{gama}}? ChezCandino, nous avons une grande sélection de montres intelligentes disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres connectées pour {{gama}} - Montres intelligentes | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Montres à quartz pour {{gama}}",
    "meta": "Vous recherchez une montre à quartz pour {{gama}}? ChezCandino, nous avons une grande sélection de montres disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres à quartz pour {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Montres enfants",
    "meta": "Trouvez les meilleures montres pour enfants dans notre catalogue. Aidez-les à apprendre l'heure avec les meilleurs modèles des meilleures marques.",
    "title": "Montres enfants | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Montres {{estilo}} pour enfants",
    "meta": "Trouvez les meilleures {{estilo}} montres pour enfants sur notre site Web. Faites un bon cadeau aux petits de la maison et apprenez-leur à lire l'heure! {{numPagina}}",
    "title": "Montres {{estilo}} pour enfants {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Montres {{funcion}} pour enfants",
    "meta": "Vous recherchez un montre {{funcion}} pour {{gama}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite. ",
    "title": "Montres {{funcion}} pour enfants {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Smartwatch pour enfants",
    "meta": "Découvrez les meilleurs modèles de Smartwatch pour enfants et faites-les commencer à interagir avec la technologie. Applications, messagerie, appels, GPS et bien plus encore {{numPagina}}",
    "title": "Smartwatch pour enfants {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Montres {{marca}}",
    "meta": "Découvrez montres de {{marca}} et retrouvez celle qui correspond le mieux à votre style dans notre boutique officielle. Consultez notre catalogue!",
    "title": "Les meilleures montres {{marca}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Montres {{marca}} pour {{gama}}",
    "meta": "Ne manquez pas les meilleurs modèles de montres {{marca}} pour {{gama}}. Visitez notre site web et découvrez notre large gamme de modèles.",
    "title": "Montres {{marca}} pour {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Montres {{marca}} {{colorCorrea}} pour {{gama}}",
    "meta": "Vous recherchez une horloge {{{marca}} pour {{gama}} {{colorCorrea}}? Retrouvez dans notre catalogue toutes les couleurs de montres {{marca}} pour {{gama}}. {{numPagina}}",
    "title": "Montres {{marca}} {{colorCorrea}} pour {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Montres {{marca}} pour {{gama}} avec bracelet {{colorCorrea}}",
    "meta": "Vous avez un faible pour les montres {{colorCorrea}} pour {{gama}}? Obtenez votre montre parfaite. Découvrez les meilleurs modèles!",
    "title": "Montres {{marca}} pour {{gama}} | Sphère {{colorCorrea}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Montres {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Vous recherchez une horloge {{marca}} pour {{gama}} {{colorEsfera}}? Retrouvez dans notre catalogue toutes les couleurs de montres {{marca}} pour {{gama}}. {{numPagina}}",
    "title": "Montres {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "Montres {{marca}} pour {{gama}} avec sphère {{colorEsfera}}",
    "meta": "Vous cherchez une montre {{marca}} {{colorEsfera}} pour {{gama}}? Retrouvez dans notre catalogue toutes les couleurs de montres {{marca}} pour {{gama}}.",
    "title": "Montres {{marca}} pour {{gama}} | Sphère {{colorEsfera}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Montres {{marca}} {{gama}} {{estilo}}",
    "meta": "Découvrez les dernières actualités de Montres {{marca}} pour {{gama}} {{estilo}}. Des montres avec personnalité et design unique. Visitez notre catalogue! {{numPagina}}",
    "title": "Montres {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Montres {{marca}} {{funcion}} pour {{gama}}",
    "meta": "Visitez notre site Web et découvrez notre large gamme de montres {{marca}} {{funcion}} pour {{gama}}. Obtenez la montre qui vous convient le mieux. {{numPagina}}",
    "title": "Montres {{marca}} {{funcion}} pour {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "Montres {{funcion}} {{marca}} pour {{gama}}",
    "meta": "Visitez notre site web et découvrez notre large gamme de montres {{funcion}} {{marca}} pour {{gama}}. Obtenez la montre qui vous convient le mieux.",
    "title": "Montres {{funcion}} {{marca}} pour {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "Montres {{marca}} pour enfants",
    "meta": "Êtes-vous à la recherche de montres {{marca}} pour enfants? Découvrez dans notre catalogue toutes les nouveautés de montres pour les plus petits.",
    "title": "Montres {{marca}} pour enfants | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Montres avec étui {{materialCaja}}",
    "meta": "Aimez-vous {{materialCaja}}montres? Découvrez notre vaste catalogue et trouvez la montre qui fait briller votre poignet. {{numPagina}}",
    "title": "Montres avec étui {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Montres {{materialCorrea}}",
    "meta": "Aimez-vous {{materialCorrea}}montres? Découvrez notre vaste catalogue et trouvez la montre qui fait briller votre poignet. Visitez notre site Web maintenant!",
    "title": "Les meilleures montres {{materialCorrea}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Montres {{tipoCierre}}",
    "meta": "Vous recherchez un montre {{tipoCierre}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite. Consultez notre catalogue!",
    "title": "Montres {{tipoCierre}}: Découvrez nos nouveautés | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Montres {{tipoCorrea}}",
    "meta": "Vous recherchez une horloge {{tipoCorrea}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite pour vous. Consultez notre catalogue maintenant {{numPagina}}",
    "title": "Découvrez les {{tipoCorrea}} de nos montres | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "",
    "meta": "",
    "robots": "",
    "title": ""
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Montres avec mouvement Digital Quartz",
    "meta": "Vous recherchez des montres avec un mouvement Digital Quartz ? Jetez un œil à notre large gamme de montres et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres avec mouvement Digital Quartz | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Montres avec mouvement Manuel ",
    "meta": "Vous recherchez des montres avec un mouvement Manuel? Jetez un œil à notre large gamme de montres et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres avec mouvement Manuel | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Montres automatiques",
    "meta": "Cherchez-vous une montre automatique? Chez Candino, nous avons une grande sélection de montres automatiques disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres automatiques | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Montres hybrides",
    "meta": "Cherchez-vous une montre automatique ? Chez Candino, nous avons une grande sélection de montres automatiques disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "robots": "false",
    "title": "Montres hybrides | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Montres intelligentes",
    "meta": "Vous recherchez une montre connectée ? Chez Candino, nous avons une grande sélection de montres intelligentes disponibles. Venez et choisissez celle qui correspond le mieux à votre style.",
    "title": "Montres intelligentes - Montres intelligentes | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Montres à quartz",
    "meta": "Cherchez-vous une montre à quartz ? ChezCandino, nous avons une grande sélection de montres à quartz disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "robots": "false",
    "title": "Montres à quartz | Candino"
  }
}
