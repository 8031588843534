import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_CONFIG, FOOTER_DATA } from '@fgp/app-config';
import { BasketSelectors } from '@fgp/basket/data-access';
import { FeatureFlagsSelectors } from '@fgp/feature-flag/data-access';
import { fromFooter } from '@fgp/footer/data-access';
import { LocalesSelectors } from '@fgp/navbar/locale-selector/data-access';
import { ShopCode } from '@fgp/shared/interfaces';
import { UtilStaticImageUrlModule } from '@fgp/shared/pipes/util-static-image-url';
import { selectIsActivePath } from '@fgp/shared/router-state';
import { breakpointSelectors } from '@fgp/utils/breakpoint';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  imports: [CommonModule, RouterModule, TranslateModule, LocalizeRouterModule, UtilStaticImageUrlModule],
  selector: 'fgp-footer-bar',
  styleUrls: ['./footer-bar.component.scss'],
  template: ` <div class="footer-bottom-bar px-4 py-2">
    <div
      class="footer__container"
      [ngClass]="{
        container: [shopCode.Calypso, shopCode.TimeRoad, shopCode.Kronaby].includes(tienda),
        'footer-top': tienda === shopCode.Perrelet,
        'px-0': tienda === shopCode.Calypso,
        'fgs-pb-40': isBasket$() && isMobileOrTablet$(),
        'fgs-pb-60': isPrivacity$() && isMobileOrTablet$()
      }"
    >
      <div class="footer__icons d-flex flex-row py-2">
        @for (icon of footerDataComputed(); track icon.src) {
          @if (!icon?.country) {
            <img
              class="mr-4"
              [src]="icon.src | staticImageUrl: 'assets/img/footer'"
              [alt]="icon.title"
              [title]="icon.title"
              loading="lazy"
              [height]="icon.height"
              [width]="icon.width"
            />
          }
          @if (icon?.country && icon?.country?.includes(country$())) {
            <img
              class="mr-4"
              [src]="icon.src | staticImageUrl: 'assets/img/footer'"
              [alt]="icon.title"
              [title]="icon.title"
              loading="lazy"
              [height]="icon.height"
              [width]="icon.width"
            />
          }
        }
      </div>
      @if (purchaseSummary$() === false) {
        <div class="footer__line col-12">
          <hr class="border-white" />
        </div>
      }
      @if (!physicalShop$()) {
        <div class="footer__legal py-2">
          @for (item of footerBar$(); track item.id) {
            <div>
              @if (item.urlPath && item.urlPath?.charAt(0) === '/') {
                <a class="paragraph-3 white-color footer-link" [routerLink]="item.urlPath | localize" [rel]="item?.atributes ?? ''">
                  {{ item.text }}
                </a>
              } @else {
                <a class="paragraph-3 white-color footer-link" [href]="item?.urlPath" target="_blank" [rel]="item?.atributes ?? ''">
                  {{ item.text }}
                </a>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterBarComponent {
  #store = inject(Store);
  tienda = inject(APP_CONFIG).tiendaCode;
  storeName = inject(APP_CONFIG).appName;
  footer_data = inject(FOOTER_DATA);
  isBasket$ = this.#store.selectSignal(selectIsActivePath('/basket', false));
  isPrivacity$ = this.#store.selectSignal(selectIsActivePath('/basket/buy', true));
  footerBar$ = this.#store.selectSignal(fromFooter.selectFooterBar);
  isMobileOrTablet$ = this.#store.selectSignal(breakpointSelectors.isMobileOrTablet);

  country$ = this.#store.selectSignal(LocalesSelectors.selectSelectedId);
  purchaseSummary$ = this.#store.selectSignal(BasketSelectors.selectPurchaseSummary);
  physicalShop$ = this.#store.selectSignal(BasketSelectors.selectPhysicalShop);
  bizumPaymentFF$ = this.#store.selectSignal(FeatureFlagsSelectors.selectFeatureFlagByCode('bizum-payment'));
  shopCode = ShopCode;
  footerDataComputed = computed(() => {
    let data = this.footer_data;
    data = this.country$() === 'ES' ? data : data.filter(footer => footer.title !== 'Bizum');
    data = this.bizumPaymentFF$() ? data : data.filter(footer => footer.title !== 'Bizum');
    return data;
  });

  @HostBinding('class') classes = this.storeName;
}
