{
  "filtro.color.AM": "Yellow",
  "filtro.color.AZ": "BLUE",
  "filtro.color.AZRS": "blue / pink",
  "filtro.color.BL": "whites",
  "filtro.color.BLRY": "striped targets",
  "filtro.color.CABA": "Horses",
  "filtro.color.CHA": "PLATED",
  "filtro.color.CIRC": "zircons",
  "filtro.color.DI": "Digital",
  "filtro.color.DIAM": "Diamonds",
  "filtro.color.DO": "Golden",
  "filtro.color.FU": "fuchsias",
  "filtro.color.GATO": "Cats",
  "filtro.color.GN": "garnets",
  "filtro.color.GR": "GRAY",
  "filtro.color.LI": "lilacs",
  "filtro.color.MA": "brown",
  "filtro.color.MAAG": "BROWN / SILVER",
  "filtro.color.MF": "ivories",
  "filtro.color.MULT": "multicolored",
  "filtro.color.NE": "blacks",
  "filtro.color.NEAM": "yellow blacks",
  "filtro.color.NJ": "Oranges",
  "filtro.color.PL": "silvery",
  "filtro.color.PLAZ": "silver blue",
  "filtro.color.PLNE": "SILVER / BLACK",
  "filtro.color.PLRS": "silver roses",
  "filtro.color.RO": "RED",
  "filtro.color.RONE": "Black red",
  "filtro.color.RS": "Roses",
  "filtro.color.TRAN": "Transparent",
  "filtro.color.VE": "GREEN",
  "filtro.color.VI": "Violets",
  "filtro.estilo.CA": "Casual",
  "filtro.estilo.CL": "classics",
  "filtro.estilo.LE": "LIMITED EDITION",
  "filtro.estilo.SP": "Sports",
  "filtro.funcion.ANALO": "Analog",
  "filtro.funcion.CRONO": "chronographs",
  "filtro.funcion.DIGI": "Digital",
  "filtro.funcion.SMART": "smartwatches",
  "filtro.marca.01": "Lotus",
  "filtro.marca.02": "Festina",
  "filtro.marca.03": "Jaguar",
  "filtro.marca.06": "Calypso",
  "filtro.marca.11": "Lotus Style",
  "filtro.marca.12": "Lotus Silver",
  "filtro.marca.16": "Kronaby",
  "filtro.marca.50": "Candino",
  "filtro.marca.TR": "Time Road",
  "filtro.tipoAccesorio.WRITING": "",
  "filtro.tipoJoya.ANILLO": "Rings",
  "filtro.tipoJoya.BROCHE": "Brooches",
  "filtro.tipoJoya.COLGANTE": "pendants",
  "filtro.tipoJoya.COLLAR": "necklaces",
  "filtro.tipoJoya.CONJUNTO": "Sets",
  "filtro.tipoJoya.EARCUFF": "Ear Cuff",
  "filtro.tipoJoya.GEMELOS": "Twins",
  "filtro.tipoJoya.PENDIENTES": "earrings",
  "filtro.tipoJoya.PIERCING": "Piercings",
  "filtro.tipoJoya.PULSERA": "BRACELETS",
  "filtro.tipoJoya.TOBILLERA": "anklets"
}
