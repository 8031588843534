import { Directive, HostListener, inject } from '@angular/core';
import { ScrollActions, ScrollSelectors } from '@fgp/scroll-data-access';
import { Store } from '@ngrx/store';
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[fgpScroll]',
    standalone: false
})
export class ScrollDirective {
  private store = inject(Store);
  scrollDirection$ = this.store.selectSignal(ScrollSelectors.selectDirectionScroll);
  lastScrollTop = 0;
  minHeight = 95;
  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event) {
    const scrollOffset = event?.target?.children?.length > 0 ? event?.target?.children[0].scrollTop : 0;
    const currentScroll = scrollOffset < 0 ? 0 : scrollOffset;
    if (currentScroll > this.lastScrollTop && this.scrollDirection$() !== 'down' && currentScroll >= this.minHeight) {
      this.store.dispatch(ScrollActions.setDirection({ direction: 'down' }));
    } else if (currentScroll < this.lastScrollTop && this.scrollDirection$() !== 'up') {
      this.store.dispatch(ScrollActions.setDirection({ direction: 'up' }));
    }
    this.lastScrollTop = currentScroll;
  }
}
