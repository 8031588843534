import { Component, HostBinding, inject, Inject, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_CONFIG } from '@fgp/app-config';
import { IAppConfig } from '@fgp/shared/interfaces';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { GtmEventsService } from '@fgp/shared/services/gtm-events';

@Component({
  selector: 'fgp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  ngZone = inject(NgZone);
  @HostBinding('class') classes = this.appConfig.appName;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private gtmEventsService: GtmEventsService,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {}
  ngOnInit(): void {
    console.log('Version', this.appConfig.appVersion);
    if (this.deviceDetectorService.isBrowser()) {
      this.ngZone.runOutsideAngular(() => {
        this.router.events.forEach(item => {
          if (item instanceof NavigationEnd) {
            this.gtmEventsService.checkCookieConsent();
            this.gtmEventsService.pageTypeEvent();
          }
        });
      });
      this.gtmEventsService.initGTM(true);
    }
  }
}
