import { CommonModule, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LanguagesSelectors, LocalesSelectors } from '@fgp/navbar/locale-selector/data-access';
import { RedirectByCountrySelectors } from '@fgp/redirect-by-country/data-access';
import { UiRedirectByCountryComponent } from '@fgp/redirect-by-country/ui-redirect-by-country';
import { selectUrl } from '@fgp/shared/router-state';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fgp-feature-redirect-by-country',
  imports: [CommonModule, UiRedirectByCountryComponent, SvgIconComponent],
  styles: [
    `
      .remove-icon {
        font-size: 1.4rem;
      }
    `
    ],
    template: `
    <div class="modal-header border-0 pb-0">
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <svg-icon size="md" key="x-light" />
      </button>
    </div>
    <div class="modal-body">
      <fgp-ui-redirect-by-country
        [goToCountry]="goToCountry$()"
        [goToCountryUrl]="storeUrlCms$()"
        [countryName]="countryName$()"
        [stayCountry]="stayCountry$()"
        [countries]="adminSessionService.getCupon() ? suizaCountry$() : countries$()"
        (closeModalEvent)="closeModal()"
      />
    </div>
  `
})
export class FeatureRedirectByCountryComponent {
  private activeModal = inject(NgbActiveModal);
  private store = inject(Store);
  adminSessionService = inject(AdminSessionService);
  location = inject(Location);

  languages$ = this.store.selectSignal(LanguagesSelectors.selectLanguagesIds);
  countries$ = this.store.selectSignal(LocalesSelectors.selectFiltersLocale);
  suizaCountry$ = this.store.selectSignal(LocalesSelectors.selectSuizaLocale);
  storeUrlCms$ = this.store.selectSignal(RedirectByCountrySelectors.selectStoreUrl);
  goToCountry$ = this.store.selectSignal(RedirectByCountrySelectors.selectCountry);
  countryName$ = this.store.selectSignal(RedirectByCountrySelectors.selectCountryName);
  stayCountry$ = this.store.selectSignal(LocalesSelectors.selectSelectedId);
  url$ = this.store.selectSignal(selectUrl);

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }

  onChangeCountry() {
    this.closeModal();
  }
}
