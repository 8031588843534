<div class="imagen-modelos" [ngClass]="{ invisible: !showCarousel }">
  <div class="row no-gutters">
    @if (productos.length > 4) {
      <div
        [class.invisible]="modelSwiper.swiperRef?.isBeginning"
        class="col-1 align-self-center cursor-pointer text-right"
        (click)="onPrevCarousel()"
      >
        <svg-icon key="chevon-chevon-left-light" size="xs" />
      </div>
    }
    <div class="col-10" [class.col-12]="productos.length <= 4">
      <swiper ngSkipHydration #modelSwiper [config]="swiperModelConfig">
        @for (item of productos; track item?.idProducto) {
          <ng-template swiperSlide>
            <div
              (click)="obtenerInformacionProducto(item)"
              [ngClass]="{
                selected: codigo === item?.codigo
              }"
              class="simpleItem d-flex justify-content-center align-items-center cursor-pointer"
            >
              <picture>
                <source [srcset]="item.urlImgThumb ?? '' | webp" type="image/webp" />
                <img loading="lazy" class="img-fluid miniatura" [src]="item.urlImgThumb" [alt]="item?.nombre" />
              </picture>
            </div>
          </ng-template>
        }
      </swiper>
    </div>
    @if (productos.length > 4) {
      <div [class.invisible]="modelSwiper.swiperRef?.isEnd" class="col-1 align-self-center cursor-pointer" (click)="onNextCarousel()">
        <svg-icon key="chevon-chevon-rigth-light" size="xs" />
      </div>
    }
  </div>
  @if (productos.length > 1 && showModels) {
    <p class="models paragraph-3" [ngClass]="{ invisible: showCarousel }">
      {{ productos.length }} {{ 'generic.strings.modelos' | translate }}
    </p>
  }
</div>
