import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { ICmsResponse, IResponse } from '@fgp/shared/interfaces';
import { QueryStringService } from '@fgp/shared/querystring';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectByCountryService {
  qs = inject(QueryStringService);
  http = inject(HttpClient);
  appConfig = inject(APP_CONFIG);
  urlCMSApiEndpoint = `${this.appConfig.cms.endpoint}/api/country-store-urls`;
  private urlApiPublica: string;

  constructor() {
    this.urlApiPublica = this.appConfig.apiPublicUrl;
  }

  getCurrentLocation() {
    return this.http.get<IResponse>(`${this.urlApiPublica}util/header/clientGeoLocation`);
  }

  getStores(country: string | string[]): Observable<ICmsResponse> {
    const query = this.qs.stringifyNotDefaultFilters({
      filters: {
        store: {
          $eq: this.appConfig.tiendaCode
        },
        country: {
          $eq: country
        }
      }
    });
    return this.http.get<ICmsResponse>(`${this.urlCMSApiEndpoint}?${query}`);
  }
}
