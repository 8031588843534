import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RedirectByCountryEffects } from './+state/redirect-by-country.effects';
import { redirectByCountryFeature } from './+state/redirect-by-country.reducer';

@NgModule({
  imports: [StoreModule.forFeature(redirectByCountryFeature), EffectsModule.forFeature([RedirectByCountryEffects])]
})
export class RedirectByCountryDataAccessModule {}
