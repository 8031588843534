{
  "reloj": {
    "h1": "Relógios",
    "meta": "Procurando um relógio exclusivo e elegante? Descubra os nossos relógios das melhores marcas e encontre o modelo perfeito para si. {{numPagina}}",
    "title": "Relógios {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Relógios {{colorCorrea}}",
    "meta": "Você tem um fraco por relógios {{colorCorrea}}? Descubra os modelos mais pessoais das melhores marcas e obtenha o seu relógio {{colorCorrea}} perfeito. {{numPagina}}",
    "title": "Relógios {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Relógios {{colorEsfera}}",
    "meta": "Você tem um fraco por relógios {{colorEsfera}}? Descubra os modelos mais pessoais das melhores marcas e obtenha o seu relógio {{colorEsfera}} perfeito. {{numPagina}}",
    "title": "Relógios {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Relógios {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Procurando um relógio exclusivo e elegante? Descubra os nossos relógios das melhores marcas e encontre o modelo perfeito para si. {{numPagina}}",
    "robots": "false",
    "title": "Relógios {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Relógios {{estilo}}",
    "meta": "Em nossa loja online, você encontrará uma ampla seleção de {{estilo}} relógios perfeitos para você. Compre seu {{estilo}} Assistir online agora! {{numPagina}}",
    "title": "Relógios {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Relógios {{funcion}}",
    "meta": "Procurando um relógio {{funcion}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Procurando um relógio {{funcion}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "{{funcion}} | Candino"
  },
  "reloj.gama": {
    "h1": "Relogios para {{gama}}",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios para {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Relógios {{colorCorrea}} para {{gama}}",
    "meta": "Descubra os melhores modelos de relógios para {{gama}} {{colorCorrea}}. Encontre o relógio ideal para você em nosso catálogo online. Visite-nos agora! {{numPagina}}",
    "title": "Relógios {{colorCorrea}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Relogios para {{gama}} com {{colorCorrea}}",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios com correia {{colorCorrea}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios para {{gama}} com {{colorCorrea}} | Candino"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Relógios {{gama}} Discagem {{colorEsfera}}",
    "meta": "Descubra os melhores modelos de relógios para {{gama}} com discagem colorida {{colorEsfera}}. Encontre o relógio ideal para você em nosso catálogo online. Visite-nos agora! {{numPagina}}",
    "title": "Relógios {{gama}} Discagem {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Relogios com Esfera de côr {{colorEsfera}} para {{gama}}",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios com esfera {{colorEsfera}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios com Esfera de côr {{colorEsfera}} para {{gama}} | Candino"
  },
  "reloj.gama.default": {
    "h1": "Relógios {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Procurando um relógio exclusivo e elegante? Descubra os nossos relógios das melhores marcas e encontre o modelo perfeito para si. {{numPagina}}",
    "title": "Relógios {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Relógios {{gama}} {{estilo}}",
    "meta": "Explore nossa seleção de {{gama}} {{estilo}} relógios feitos com os melhores materiais e encontre o modelo perfeito para você. {{numPagina}}",
    "title": "Relógios {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Relógios para {{gama}} {{funcion}}",
    "meta": "Você está procurando {{gama}} {{funcion}}relógios? Dê uma olhada em nossa ampla gama de relógios para {{gama}} e escolha o modelo perfeito para você. {{numPagina}}",
    "title": "Relógios para {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Relógios {{funcion}} para {{gama}}",
    "meta": "Procuras um relogio {{funcion}} que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios {{funcion}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios {{funcion}} para {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch para {{gama}}",
    "meta": "Obtenha agora o seu Smartwatch para {{gama}}! Descubra todos os nossos modelos e encontre o seu. Ideal para qualquer ocasião.",
    "title": "Smartwatch para {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": " {{gama}} relógios com {{materialCaja}}caixa",
    "meta": "Obtenha {{gama}} de {{materialCaja}} relógios das melhores marcas e experimente conforto no seu pulso. Visite nosso site agora! {{numPagina}}",
    "title": " {{gama}} relógios com {{materialCaja}} {{numPagina}} caixa | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Relogios para {{gama}} com {{materialCorrea}}",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios {{materialCorrea}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios para {{gama}} com {{materialCorrea}} | Candino"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch para {{gama}}",
    "meta": "Obtenha seu Smartwatch por {{gama}}agora! Descubra todos os nossos modelos e encontre o seu. Ideal para qualquer ocasião. {{numPagina}}",
    "title": "Smartwatch para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Relogios para {{gama}} com {{tipoCierre}}",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios com {{tipoCierre}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios para {{gama}} com {{tipoCierre}} | Candino"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Relogios Submerssíveis {{tipoEstanqueidad}} para {{gama}} | Candino",
    "meta": "Procuras um relogio que se adapte ao teu estilo de vida? Consulta o nosso catálogo de Relogios submersos {{tipoEstanqueidad}} para {{gama}} e reluz com un modelo 'made in Suiça', de qualidade exclusiva.",
    "title": "Relogios Submerssíveis {{tipoEstanqueidad}} para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Relógios digitais para {{gama}}",
    "meta": "Está à procura de um relógio digital para {{gama}}? Na Candino temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios digitais para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Relógios para {{gama}} com movimento Quartzo-digital",
    "meta": "Está à procura de relógios de {{gama}} com movimento Quartzo-digital? Dê uma olhada na nossa ampla gama de relógios para {{gama}} e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios para {{gama}} com movimento Quartzo-digital | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Relógios automáticos para {{gama}}",
    "meta": "Está à procura de um relógio automático para {{gama}}? Na Candino temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios automáticos para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Relógios Híbridos para {{gama}}",
    "meta": "Está procurando um relógio híbrido para {{gama}}? Na Candino temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios Híbridos para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches para {{gama}} (Relógios inteligentes para {{gama}})",
    "meta": "Está à procura de um smartwatch para {{gama}}? Na Candino temos uma grande seleção de relógios inteligentes disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Smartwatches para {{gama}} - Relógios Inteligentes | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Relógios de Quartzo para {{gama}}",
    "meta": "Está à procura de um relógio de quartzo para {{gama}}? Na Candino temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios de Quartzo para {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Relógios infantis",
    "meta": "Encontre os melhores Relógios para Crianças em nosso catálogo. Ajude-os a aprender o tempo com os melhores modelos das melhores marcas. {{numPagina}}",
    "title": "Relógios infantis {{numPagina}} | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Relógios {{estilo}} para crianças",
    "meta": "Encontre os melhores {{estilo}} Relógios para Crianças em nosso site. Faça um bom presente para os pequeninos da casa e ensine-os a ler o tempo! {{numPagina}}",
    "title": "Relógios {{estilo}} para crianças {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Relógios {{funcion}} para crianças",
    "meta": "Você quer um {{funcion}} relógio para uma criança? Encontre uma variedade de relógios infantis {{funcion}} das melhores marcas. Visite nosso catálogo! {{numPagina}}",
    "title": "Relógios {{funcion}} para crianças {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Kids Smartwatch",
    "meta": "Descubra os melhores modelos de Smartwatch para crianças e faça com que elas comecem a interagir com a tecnologia. Aplicativos, mensagens, chamadas, GPS e muito mais {{numPagina}}",
    "title": "Smartwatch para crianças {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Relógios {{marca}}",
    "meta": "Não perca os melhores modelos de Relógio {{marca}}. Visite o nosso site e descubra a nossa vasta gama de modelos. {{numPagina}}",
    "title": "Relógios {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Relógios {{marca}} para {{gama}}",
    "meta": "Não perca os melhores modelos de Relógio {{marca}} para {{gama}}. Visite o nosso website e descubra a nossa vasta gama de modelos.",
    "title": "Relógios {{marca}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Relógios {{marca}} {{colorCorrea}} para {{gama}}",
    "meta": "Procurando um relógio {{marca}} para {{gama}} {{colorCorrea}}? Encontre em nosso catálogo todas as cores de relógios {{marca}} para {{gama}}. {{numPagina}}",
    "title": "Relógios {{marca}} {{colorCorrea}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Relógios {{marca}} {{colorCorrea}} para {{gama}}",
    "meta": "Está à procura de Relógios {{marca}} {{colorCorrea}} para {{gama}}? Encontre no nosso catálogo todas as cores dos relógios {{marca}} para {{gama}}.",
    "title": "Relógios {{marca}} {{colorCorrea}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Relógios {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Procurando um relógio {{marca}} para {{gama}} {{colorEsfera}}? Encontre em nosso catálogo todas as cores de relógios {{marca}} para {{gama}}. {{numPagina}}",
    "title": "Relógios {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "Relógios {{marca}} {{colorEsfera}} para {{gama}}",
    "meta": "Está à procura de Relógios {{marca}} {{colorEsfera}} para {{gama}}? Encontre no nosso catálogo todas as cores dos relógios {{marca}} para {{gama}}.",
    "title": "Relógios {{marca}} {{colorEsfera}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Relógios {{marca}} {{gama}} {{estilo}}",
    "meta": "Encontre as últimas notícias em Relógios {{marca}} para {{gama}} {{estilo}}. Relógios com personalidade e design único. Visite nosso catálogo! {{numPagina}}",
    "title": "Relógios {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Relógios {{marca}} {{funcion}} para {{gama}}",
    "meta": "Visite nosso site e descubra nossa ampla variedade de relógios {{marca}} {{funcion}} para {{gama}}. Obtenha o relógio que melhor se adapta a você. {{numPagina}}",
    "title": "Relógios {{marca}} {{funcion}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "Relógios {{marca}} {{funcion}} para {{gama}}",
    "meta": "Visite nosso site e descubra nossa ampla variedade de relógios {{marca}} {{funcion}} para {{gama}}. Obtenha o relógio que melhor se adapta a você. {{numPagina}}",
    "title": "Relógios {{marca}} {{funcion}} para {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "Relógios {{marca}} para {{gama}}",
    "meta": "Não perca os melhores modelos de Relógio {{marca}} para {{gama}}. Visite o nosso website e descubra a nossa vasta gama de modelos.",
    "title": "Relógios {{marca}} para {{gama}} | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Relógios com capa {{materialCaja}}",
    "meta": "Você gosta de {{materialCaja}}relógios? Descubra nosso extenso catálogo e encontre o relógio que faz seu pulso brilhar. {{numPagina}}",
    "title": "Relógios com capa {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Relógios {{materialCorrea}}",
    "meta": "Você gosta de relógios com pulseira de {{materialCorrea}}? Descubra nosso extenso catálogo e encontre o relógio que faz seu pulso brilhar. {{numPagina}}",
    "title": "Relógios {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Relógios {{tipoCierre}}",
    "meta": "Procurando um relógio {{tipoCierre}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Relógios {{tipoCorrea}}",
    "meta": "Procurando um relógio {{tipoCorrea}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Relógios Digitais",
    "meta": "Está procurando um relógio digital? Na Candino temos uma grande seleção de relógios digitais disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios Digitais | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Relógios com movimento Quartzo-digital",
    "meta": "Está procurando relógios com movimento Quartzo-digital? Dê uma olhada em nossa ampla variedade de relógios e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios com movimento Quartzo-digital | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Relógios com movimento Manual",
    "meta": "Está procurando relógios com movimento Manual? Dê uma olhada em nossa ampla variedade de relógios e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios com movimento Manual | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Relógios Automáticos",
    "meta": "Está à procura de um relógio automático? Na Candino temos uma grande seleção de relógios automáticos disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios Automáticos | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Relógios Híbridos",
    "meta": "Está à procura de um relógio automático? Na Candino temos uma grande seleção de relógios automáticos disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios Híbridos | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Está à procura de um smartwatch? Na Candino temos uma grande seleção de relógios inteligentes disponíveis. Entre e escolha o que melhor se adapta ao seu estilo.",
    "title": "Smartwatches - Relógios Inteligentes | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Relógios de Quartzo",
    "meta": "Está procurando um relógio de quartzo? Na Candino temos uma grande seleção de relógios de quartzo disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios de Quartzo | Candino"
  }
}
