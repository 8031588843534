import { inject, Injectable } from '@angular/core';
import { LocaleActions, LocalesSelectors } from '@fgp/navbar/locale-selector/data-access';
import { FeatureRedirectByCountryComponent } from '@fgp/redirect-by-country/feature-redirect-by-country';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { GtmEventsService } from '@fgp/shared/services/gtm-events';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';
import { RedirectByCountryService } from '../redirect-by-country.service';
import { RedirectByCountryActions } from './redirect-by-country.actions';
import { RedirectByCountrySelectors } from './redirect-by-country.reducer';
@Injectable()
export class RedirectByCountryEffects {
  private actions$ = inject(Actions);
  private redirectByCountryService = inject(RedirectByCountryService);
  private modalService = inject(NgbModal);
  private deviceDetectorService = inject(DeviceDetectorService);
  private store = inject(Store);
  private adminSessionService = inject(AdminSessionService);
  private gtmService = inject(GtmEventsService);

  getLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocaleActions.loadSuccess),
      filter(
        () =>
          this.deviceDetectorService.isBrowser() &&
          !this.adminSessionService.getCountry() &&
          !this.adminSessionService.getShowExternalCountry()
      ),
      switchMap(() =>
        this.redirectByCountryService
          .getCurrentLocation()
          .pipe(map(response => RedirectByCountryActions.getLocationSuccess({ country: response?.content })))
      )
    )
  );

  getLocationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectByCountryActions.getLocationSuccess),
        concatLatestFrom(() => [
          this.store.select(LocalesSelectors.selectFiltersLocale),
          this.store.select(RedirectByCountrySelectors.selectCountry),
          this.store.select(LocalesSelectors.selectAllLocalesEntities)
        ]),
        map(([, locales, country, countries]) => {
          if (locales.includes(country)) {
            if (!this.adminSessionService.getCountry()) {
              const selectedCountry = countries.find(c => c.codigo === country);
              const currency = selectedCountry?.currency ? selectedCountry?.currency : 'EUR';
              this.store.dispatch(LocaleActions.setCurrency({ currency }));
              this.adminSessionService.setCountry(country);
              this.gtmService.changeCountryEvent(country);
              this.store.dispatch(LocaleActions.changeLocale({ isCesta: true, selectedId: country }));
            }
          } else {
            if (!this.adminSessionService.getShowExternalCountry()) {
              this.store.dispatch(RedirectByCountryActions.getUrlByCountry());
              this.adminSessionService.setShowExternalCountry(country, 2);
            }
          }
        })
      ),
    { dispatch: false }
  );

  getUrlByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectByCountryActions.getUrlByCountry),
      concatLatestFrom(() => [this.store.select(RedirectByCountrySelectors.selectCountry)]),
      filter(([, country]) => !!country),
      switchMap(([, country]) =>
        this.redirectByCountryService.getStores(country).pipe(
          map(response =>
            RedirectByCountryActions.showSuggestStoreUrl({
              storeUrl: response.data.length > 0 ? response.data[0].storeUrl : null,
              countryName: response.data.length > 0 ? response.data[0].countryName : null
            })
          )
        )
      )
    )
  );

  showSuggestStoreUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectByCountryActions.showSuggestStoreUrl),
        filter(({ storeUrl }) => !!storeUrl),
        map(() => {
          this.modalService.open(FeatureRedirectByCountryComponent, { size: 'lg', centered: true });
        })
      ),
    { dispatch: false }
  );
}
