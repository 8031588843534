import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  // 1. shouldReuseRoute(future, curr)
  // 2. retrieve(route)
  // 3. shouldDetach(route)
  // 4. store(route, detachedTree)
  // 5. shouldAttach(route)
  // 6. retrieve, depending on the return value of the previous step
  // 7. store(route, detachedTree), depending on the fifth step

  // Specify the routes to reuse/cache in an array.
  componentsToCache: string[] = ['ProductoListaComponent', 'ProductoComponent'];
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  landingRoute: string;

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.componentsToCache.some(component => component === (route?.component as any).id);
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle !== null) {
      const existEntry = Array.from(this.storedRouteHandles.entries()).find(([clave]) =>
        clave.includes(`${(route?.component as any).id}-`)
      );
      if (existEntry) {
        this.storedRouteHandles.delete(existEntry[0]);
      }
      this.storedRouteHandles.set(getKey(route), handle);
    } else {
      this.storedRouteHandles.delete(getKey(route));
    }
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (this.landingRoute == 'PRODUCTODETALLEPATH') {
      if (this.componentsToCache.some(component => component === (route.component as any)?.id)) {
        return this.storedRouteHandles.has(getKey(route));
      }
    }
    return false;
  }

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(getKey(route)) as DetachedRouteHandle;
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    this.landingRoute = curr.data.discriminantPathKey;
    return future.routeConfig === curr.routeConfig && (curr?.data?.shouldReuse ?? true);
  }
}

export function getKey(route: ActivatedRouteSnapshot) {
  return `${(route?.component as any).id}-${route.pathFromRoot.flatMap(v => v.url).join('/')}`;
}
