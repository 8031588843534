import { createActionGroup, emptyProps, props } from '@ngrx/store';

const redirectByCountry = createActionGroup({
  source: 'Redirect By Country',
  events: {
    getLocationSuccess: props<{ country: string }>(),
    getUrlByCountry: emptyProps(),
    showSuggestStoreUrl: props<{ storeUrl: string; countryName: string }>()
  }
});

export const RedirectByCountryActions = {
  ...redirectByCountry
};
