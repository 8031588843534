// @ts-nocheck
/* eslint-disable */
import { airplaneTakeoffIcon } from './AirplaneTakeoff';
import { alienIcon } from './Alien';
import { arrowArcLeftIcon } from './ArrowArcLeft';
import { arrowClockwiseIcon } from './ArrowClockwise';
import { arrowDownLeftIcon } from './ArrowDownLeft';
import { arrowDownRightIcon } from './ArrowDownRight';
import { arrowsDownUpIcon } from './arrows-down-up';
import { arrowsClockwiseIcon } from './ArrowsClockwise';
import { arrowsCounterClockwiseIcon } from './ArrowsCounterClockwise';
import { arrowSquareDownIcon } from './ArrowSquareDown';
import { arrowSquareDownRightIcon } from './ArrowSquareDownRight';
import { arrowSquareLeftIcon } from './ArrowSquareLeft';
import { arrowSquareRightIcon } from './ArrowSquareRight';
import { arrowSquareUpLeftIcon } from './ArrowSquareUpLeft';
import { arrowUDownLeftIcon } from './ArrowUDownLeft';
import { arrowUDownRightIcon } from './ArrowUDownRight';
import { arrowUpLeftIcon } from './ArrowUpLeft';
import { babyIcon } from './Baby';
import { backIcon } from './back';
import { basketballIcon } from './Basketball';
import { batteryVerticalFullIcon } from './BatteryVerticalFull';
import { bookmarkSimpleIcon } from './BookmarkSimple';
import { bookOpenIcon } from './BookOpen';
import { buscadorIcon } from './buscador';
import { caretRightIcon } from './CaretRight';
import { caretUpIcon } from './CaretUp';
import { caretUpDownIcon } from './CaretUpDown';
import { castleTurretIcon } from './CastleTurret';
import { cestaIcon } from './cesta';
import { chatCircleIcon } from './ChatCircle';
import { chatCircleDotsIcon } from './ChatCircleDots';
import { checkIcon } from './Check';
import { checkCircleIcon } from './CheckCircle';
import { closeIcon } from './Close';
import { cookieIcon } from './Cookie';
import { creditCardIcon } from './CreditCard';
import { deviceMobileIcon } from './DeviceMobile';
import { dotsThreeOutlineIcon } from './DotsThreeOutline';
import { dotsThreeOutlineVerticalIcon } from './DotsThreeOutlineVertical';
import { downloadSimpleIcon } from './DownloadSimple';
import { dropIcon } from './Drop';
import { fadersIcon } from './faders';
import { favoritosIcon } from './favoritos';
import { favoritosRellenoIcon } from './favoritos-relleno';
import { flagBannerFoldIcon } from './FlagBannerFold';
import { flyingSaucerIcon } from './FlyingSaucer';
import { gameControllerIcon } from './GameController';
import { gearSixIcon } from './GearSix';
import { handSwipeLeftIcon } from './HandSwipeLeft';
import { handSwipeRightIcon } from './HandSwipeRight';
import { handTapIcon } from './HandTap';
import { handWavingIcon } from './HandWaving';
import { heartIcon } from './Heart';
import { heartRellenoIcon } from './heart-relleno';
import { iconOnTwoColumnFilledsOneIcon } from './icon-on-two-column-filleds-one';
import { iconOnTwoColumnFilledsTwoIcon } from './icon-on-two-column-filleds-two';
import { instagramLogoIcon } from './InstagramLogo';
import { legoIcon } from './Lego';
import { lightbulbFilamentIcon } from './LightbulbFilament';
import { linkedinLogoIcon } from './LinkedinLogo';
import { listIcon } from './List';
import { lockIcon } from './Lock';
import { logoCalypsoIcon } from './logo-calypso';
import { magnifyingGlassIcon } from './MagnifyingGlass';
import { mapPinIcon } from './MapPin';
import { mapTrifoldIcon } from './MapTrifold';
import { moneyWavyIcon } from './MoneyWavy';
import { monitorIcon } from './Monitor';
import { oneColumnIcon } from './one-column';
import { packageIcon } from './Package';
import { paypalLogoIcon } from './PaypalLogo';
import { planetIcon } from './Planet';
import { puzzlePieceIcon } from './PuzzlePiece';
import { rainbowIcon } from './Rainbow';
import { sealCheckIcon } from './SealCheck';
import { sealPercentIcon } from './SealPercent';
import { sealWarningIcon } from './SealWarning';
import { shieldCheckIcon } from './ShieldCheck';
import { smileyIcon } from './Smiley';
import { sneakerMoveIcon } from './SneakerMove';
import { sparkleIcon } from './Sparkle';
import { storefrontIcon } from './Storefront';
import { tagIcon } from './Tag';
import { thumbsUpIcon } from './ThumbsUp';
import { tiktokLogoIcon } from './TiktokLogo';
import { toteIcon } from './Tote';
import { treeEvergreenIcon } from './TreeEvergreen';
import { treePalmIcon } from './TreePalm';
import { truckIcon } from './Truck';
import { warningIcon } from './Warning';
import { youtubeLogoIcon } from './YoutubeLogo';
export const calypsoIcons = [
  airplaneTakeoffIcon,
  alienIcon,
  arrowArcLeftIcon,
  arrowClockwiseIcon,
  arrowDownLeftIcon,
  arrowDownRightIcon,
  arrowsDownUpIcon,
  arrowsClockwiseIcon,
  arrowsCounterClockwiseIcon,
  arrowSquareDownIcon,
  arrowSquareDownRightIcon,
  arrowSquareLeftIcon,
  arrowSquareRightIcon,
  arrowSquareUpLeftIcon,
  arrowUDownLeftIcon,
  arrowUDownRightIcon,
  arrowUpLeftIcon,
  babyIcon,
  backIcon,
  basketballIcon,
  batteryVerticalFullIcon,
  bookmarkSimpleIcon,
  bookOpenIcon,
  buscadorIcon,
  caretRightIcon,
  caretUpIcon,
  caretUpDownIcon,
  castleTurretIcon,
  cestaIcon,
  chatCircleIcon,
  chatCircleDotsIcon,
  checkIcon,
  checkCircleIcon,
  closeIcon,
  cookieIcon,
  creditCardIcon,
  deviceMobileIcon,
  dotsThreeOutlineIcon,
  dotsThreeOutlineVerticalIcon,
  downloadSimpleIcon,
  dropIcon,
  fadersIcon,
  favoritosRellenoIcon,
  favoritosIcon,
  flagBannerFoldIcon,
  flyingSaucerIcon,
  gameControllerIcon,
  gearSixIcon,
  handSwipeLeftIcon,
  handSwipeRightIcon,
  handTapIcon,
  handWavingIcon,
  heartRellenoIcon,
  heartIcon,
  iconOnTwoColumnFilledsOneIcon,
  iconOnTwoColumnFilledsTwoIcon,
  instagramLogoIcon,
  legoIcon,
  lightbulbFilamentIcon,
  linkedinLogoIcon,
  listIcon,
  lockIcon,
  logoCalypsoIcon,
  magnifyingGlassIcon,
  mapPinIcon,
  mapTrifoldIcon,
  moneyWavyIcon,
  monitorIcon,
  oneColumnIcon,
  packageIcon,
  paypalLogoIcon,
  planetIcon,
  puzzlePieceIcon,
  rainbowIcon,
  sealCheckIcon,
  sealPercentIcon,
  sealWarningIcon,
  shieldCheckIcon,
  smileyIcon,
  sneakerMoveIcon,
  sparkleIcon,
  storefrontIcon,
  tagIcon,
  thumbsUpIcon,
  tiktokLogoIcon,
  toteIcon,
  treeEvergreenIcon,
  treePalmIcon,
  truckIcon,
  warningIcon,
  youtubeLogoIcon
];
