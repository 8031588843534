{
  "reloj": {
    "h1": "Uhren",
    "meta": "Auf der Suche nach einer einzigartigen und eleganten Uhr? Entdecken Sie unsere Uhren der besten Marken und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Uhren {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Uhren {{colorCorrea}}",
    "meta": "Hast du eine Schwäche für Uhren {{colorCorrea}}? Entdecken Sie die persönlichsten Modelle der besten Marken und erhalten Sie Ihre perfekte {{colorCorrea}} Uhr. {{numPagina}}",
    "title": "Uhren {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Uhren {{colorEsfera}}",
    "meta": "Hast du eine Schwäche für Uhren {{colorEsfera}}? Entdecken Sie die persönlichsten Modelle der besten Marken und erhalten Sie Ihre perfekte {{colorEsfera}} Uhr. {{numPagina}}",
    "title": "Uhren {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Uhren {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Auf der Suche nach einer einzigartigen und eleganten Uhr? Entdecken Sie unsere Uhren der besten Marken und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "robots": "false",
    "title": "Uhren {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Uhren {{estilo}}",
    "meta": "In unserem Online-Shop finden Sie eine große Auswahl an {{estilo}} Uhren, die perfekt für Sie sind. Kaufen Sie Ihren Favoriten {{estilo}} Jetzt online anschauen! {{numPagina}}",
    "title": "Uhren {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Uhren {{funcion}}",
    "meta": "Auf der Suche nach einer Uhr {{funcion}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Auf der Suche nach einer Uhr {{funcion}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "{{funcion}} | Candino"
  },
  "reloj.gama": {
    "h1": "Uhren für {{gama}}",
    "meta": "Holen Sie sich Uhren für {{gama}} , die am besten zu Ihrem Stil passen. Überprüfen Sie unseren Katalog und genießen Sie unsere besten Modelle. {{numPagina}}",
    "title": "Uhren für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Uhren {{colorCorrea}} für {{gama}}",
    "meta": "Entdecken Sie die besten Uhrenmodelle für {{gama}} {{colorCorrea}}. In unserem Online-Katalog finden Sie die ideale Uhr für Sie. Besuchen Sie uns jetzt! {{numPagina}}",
    "title": "Uhren {{colorCorrea}} für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Überwacht {{colorCorrea}} für {{gama}}",
    "meta": "Entdecken Sie die besten Modelle der Uhr {{colorCorrea}} für {{gama}}. Finden Sie die ideale Uhr für Sie in unserem Online-Katalog, besuchen Sie uns jetzt! {{numPagina}}",
    "title": "Uhren {{colorCorrea}} für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Uhren {{gama}} Zifferblatt {{colorEsfera}}",
    "meta": "Entdecken Sie die besten Uhrenmodelle für {{gama}} mit farbigem Zifferblatt {{colorEsfera}}. In unserem Online-Katalog finden Sie die ideale Uhr für Sie. Besuchen Sie uns jetzt! {{numPagina}}",
    "title": "Uhren {{gama}} Zifferblatt {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Überwacht {{colorEsfera}} für {{gama}}",
    "meta": "Entdecken Sie die besten Modelle der Uhr {{colorEsfera}} für {{gama}}. Finden Sie die ideale Uhr für Sie in unserem Online-Katalog, besuchen Sie uns jetzt! {{numPagina}}",
    "title": "Uhren {{gama}} Zifferblatt {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.default": {
    "h1": "Uhren {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Auf der Suche nach einer einzigartigen und eleganten Uhr? Entdecken Sie unsere Uhren der besten Marken und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Uhren {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Uhren {{gama}} {{estilo}}",
    "meta": "Entdecken Sie unsere Auswahl an {{gama}} {{estilo}} Uhren aus den besten Materialien und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Uhren {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Uhren für {{gama}} {{funcion}}",
    "meta": "Suchen Sie nach {{gama}} {{funcion}} Uhren? Schauen Sie sich unsere große Auswahl an Uhren für {{gama}} an und wählen Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Uhren für {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Überwacht {{funcion}} für {{gama}}",
    "meta": "Suchen Sie nach {{funcion}}Uhren? Werfen Sie einen Blick auf unser breites Angebot an Uhren für {{gama}} und wählen Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Beobachtet {{funcion}} für {{gama}} | Zeit Straße"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch für {{gama}}",
    "meta": "Holen Sie sich jetzt Ihre Smartwatch für {{gama}}! Entdecken Sie alle unsere Modelle und finden Sie Ihres. Ideal für jede Gelegenheit.",
    "title": "Smartwatch für {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": " {{gama}} Uhren mit {{materialCaja}}Gehäuse",
    "meta": "Holen Sie sich {{gama}} von {{materialCaja}} Uhren der besten Marken und erleben Sie Komfort am Handgelenk. Besuchen Sie jetzt unsere Website! {{numPagina}}",
    "title": " {{gama}} Uhren mit {{materialCaja}} {{numPagina}} Gehäuse | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Uhren für {{gama}} mit armband{{materialCorrea}}",
    "meta": "Holen Sie sich {{gama}} Uhren mit {{materialCorrea}} Armband der besten Marken und erleben Sie den Komfort am Handgelenk. Besuchen Sie jetzt unsere Website! {{numPagina}}",
    "title": "Uhren für {{gama}} mit armband{{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch für {{gama}}",
    "meta": "Holen Sie sich jetzt Ihre Smartwatch für {{gama}}! Entdecken Sie alle unsere Modelle und finden Sie Ihre. Ideal für jeden Anlass. {{numPagina}}",
    "title": "Smartwatch für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Überwacht {{tipoCierre}} für {{gama}}",
    "meta": "Auf unserer Website können Sie die große Auswahl an Uhren {{tipoCierre}} für {{gama}} sehen, die wir anbieten. Wir haben exklusive Stücke nur für Sie.",
    "title": "Uhren {{tipoCierre}} für {{gama}} | Candino"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Wasserdichte Uhren {{tipoEstanqueidad}} bis {{gama}}",
    "meta": "Sie suchen eine wasserdichte, aber elegante Uhr? Schauen Sie sich unseren Katalog der wasserdichten Uhren {{tipoEstanqueidad}} für {{gama}} an und zeigen Sie Ihre Schweizer Technologie.",
    "title": "Taucheruhren {{tipoEstanqueidad}} für {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digitale Uhren für {{gama}}",
    "meta": "Suchen Sie eine digitale Uhr für {{gama}}? BeiCandino haben wir eine große Auswahl an verfügbaren Uhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "title": "Digitale Uhren für {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Uhren für {{gama}} mit Quarz-digital Bewegung.",
    "meta": "Suchen Sie nach {{gama}} Uhren mit Quarz-digital? Werfen Sie einen Blick auf unsere große Auswahl an Uhren für {{gama}} und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren für {{gama}} mit Quarz-digital | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatische Uhren für {{gama}}",
    "meta": "Suchst du eine Automatikuhr für {{gama}}? BeiCandino haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Automatische Uhren für {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybriduhren für{{gama}}",
    "meta": "Suchst du eine Hybriduhr für {{gama}}? BeiCandino haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Hybriduhren für{{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches für {{gama}}",
    "meta": "Suchst du eine Smartwatch für {{gama}}? BeiCandino haben wir eine große Auswahl an verfügbaren Smartwatches. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Smartwatches für {{gama}} - Smartwatches | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Quarzuhren für {{gama}}",
    "meta": "Suchst du eine Quarzuhr für {{gama}}? BeiCandino haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Quarzuhren für {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Kinderuhren",
    "meta": "Finden Sie die besten Uhren für Kinder in unserem Katalog. Helfen Sie ihnen, die Zeit mit den besten Modellen der besten Marken zu lernen. {{numPagina}}",
    "title": "Kinderuhren {{numPagina}} | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Uhren {{estilo}} für Kinder",
    "meta": "Finden Sie die besten {{estilo}} Uhren für Kinder auf unserer Website. Machen Sie den Kleinen im Haus ein gutes Geschenk und bringen Sie ihnen bei, die Zeit zu lesen! {{numPagina}}",
    "title": "Uhren {{estilo}} für Kinder {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Uhren {{funcion}} für Kinder",
    "meta": "Möchten Sie eine {{funcion}} Uhr für ein Kind? Hier finden Sie eine Vielzahl von Kinderuhren {{funcion}} der besten Marken. Besuchen Sie unseren Katalog! {{numPagina}}",
    "title": "Uhren {{funcion}} für Kinder {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Kinder Smartwatch",
    "meta": "Entdecken Sie die besten Smartwatch-Modelle für Kinder und lassen Sie sie mit der Technologie interagieren. Apps, Messaging, Anrufe, GPS und vieles mehr {{numPagina}}",
    "title": "Smartwatch für Kinder {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Uhren {{marca}}",
    "meta": "Verpassen Sie nicht die besten Modelle von Uhren {{marca}}. Besuchen Sie unsere Website und entdecken Sie unsere breite Modellpalette. {{numPagina}}",
    "title": "Uhren {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Überwacht {{marca}} für {{gama}}",
    "meta": "Verpassen Sie nicht die besten Modelle von Watch {{marca}} für {{gama}}. Besuchen Sie unsere Website und entdecken Sie unsere breite Palette an Modellen.",
    "title": "Beobachtet {{marca}} für {{gama}} | Zeit Straße"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Uhren {{marca}} {{colorCorrea}} für {{gama}}",
    "meta": "Suchen Sie eine Uhr {{marca}} für {{gama}} {{colorCorrea}}? In unserem Katalog finden Sie alle Farben der Uhren {{marca}} für {{gama}}. {{numPagina}}",
    "title": "Uhren {{marca}} {{colorCorrea}} für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Überwacht {{marca}} {{colorCorrea}} für {{gama}}",
    "meta": "Suchen Sie nach Uhren {{marca}} {{colorCorrea}} für {{gama}}? Finden Sie in unserem Katalog alle Farben der Uhren {{marca}} für {{gama}}.",
    "title": "Beobachtet {{marca}} {{colorCorrea}} für {{gama}} | Zeitstraße"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Uhren {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Suchen Sie eine Uhr {{marca}} für {{gama}} {{colorEsfera}}? In unserem Katalog finden Sie alle Farben der Uhren {{marca}} für {{gama}}. {{numPagina}}",
    "title": "Uhren {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "Überwacht {{marca}} {{colorEsfera}} für {{gama}}",
    "meta": "Suchen Sie nach Uhren {{marca}} {{colorEsfera}} für {{gama}}? Finden Sie in unserem Katalog alle Farben der Uhren {{marca}} für {{gama}}.",
    "title": "Beobachtet {{marca}} {{colorEsfera}} für {{gama}} | Zeitstraße"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Uhren {{marca}} {{gama}} {{estilo}}",
    "meta": "Die neuesten Nachrichten finden Sie unter Uhren {{marca}} für {{gama}} {{estilo}}. Uhren mit Persönlichkeit und einzigartigem Design. Besuchen Sie unseren Katalog! {{numPagina}}",
    "title": "Uhren {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Uhren {{marca}} {{funcion}} für {{gama}}",
    "meta": "Besuchen Sie unsere Website und entdecken Sie unsere große Auswahl an Uhren {{marca}} {{funcion}} für {{gama}}. Holen Sie sich die Uhr, die am besten zu Ihnen passt. {{numPagina}}",
    "title": "Uhren {{marca}} {{funcion}} für {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "Überwacht {{marca}} {{funcion}} für {{gama}}",
    "meta": "Besuchen Sie unsere Website und entdecken Sie unsere große Auswahl an Uhren {{marca}} {{funcion}} für {{gama}}. Holen Sie sich die Uhr, die am besten zu Ihnen passt. {{numPagina}}",
    "title": "Beobachtet {{marca}} {{funcion}} für {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "Überwacht {{marca}} für {{gama}}",
    "meta": "Verpassen Sie nicht die besten Modelle von Watch {{marca}} für {{gama}}. Besuchen Sie unsere Website und entdecken Sie unsere breite Palette an Modellen.",
    "title": "Beobachtet {{marca}} für {{gama}} | Zeit Straße"
  },
  "reloj.materialCaja.default": {
    "h1": "Uhren mit {{materialCaja}}Fall",
    "meta": "Magst du {{materialCaja}}Uhren? Entdecken Sie unseren umfangreichen Katalog und finden Sie die Uhr, die Ihr Handgelenk zum Leuchten bringt. {{numPagina}}",
    "title": "Uhren mit {{materialCaja}} {{numPagina}} Fall | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Uhren {{materialCorrea}}",
    "meta": "Magst du Uhren mit einem {{materialCorrea}}Armband? Entdecken Sie unseren umfangreichen Katalog und finden Sie die Uhr, die Ihr Handgelenk zum Leuchten bringt. {{numPagina}}",
    "title": "Uhren {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Uhren {{tipoCierre}}",
    "meta": "Auf der Suche nach einer Uhr {{tipoCierre}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Uhren {{tipoCorrea}}",
    "meta": "Auf der Suche nach einer Uhr {{tipoCorrea}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digitale Uhren",
    "meta": "Suchen Sie eine digitale Uhr? BeiCandino haben wir eine große Auswahl an digitalen Uhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "robots": "false",
    "title": "Digitale Uhren | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Uhren mit Bewegung Quarz-digital",
    "meta": "Suchen Sie Uhren mit Quarz-digital? Werfen Sie einen Blick auf unsere große Auswahl an Uhren und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren mit Bewegung Quarz-digital | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Uhren mit Bewegung Manuell",
    "meta": "Suchen Sie Uhren mit Manuell? Werfen Sie einen Blick auf unsere große Auswahl an Uhren und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren mit Bewegung Manuell | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatische Uhren",
    "meta": "Suchst du eine Automatikuhr? BeiCandino haben wir eine große Auswahl an Automatikuhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Automatische Uhren | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybriduhren",
    "meta": "Suchen Sie eine Automatikuhr? BeiCandino haben wir eine große Auswahl an Automatikuhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "robots": "false",
    "title": "Hybriduhren | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Suchst du eine Smartwatch? Bei Candino haben wir eine große Auswahl an verfügbaren Smartwatches. Komm rein und wähle diejenige aus, die am besten zu deinem Stil passt.",
    "title": "Smartwatches - Smartwatches | {Geschäft}"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Quarzuhren",
    "meta": "Suchst du eine Quarzuhr? BeiCandino haben wir eine große Auswahl an Quarzuhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "robots": "false",
    "title": "Quarzuhren | Candino"
  }
}
