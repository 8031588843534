import { APPEnv, CMSEnv, IAppConfig, Language, ShopCode } from '@fgp/shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IAppConfig = {
  version: require('../../../../package.json').version,
  appVersion: require('../../../../package.json').version + '-prod',
  appEnv: APPEnv.PROD,
  production: true,
  apiPublicUrl: 'https://fcloud.festina.com/public/dmz/services/',
  JWTDomainPublic: 'fcloud.festina.com',
  tiendaCodigoAES: '5581e03082608fc04c7a0be8e881f9e9',
  tiendaNombre: 'candino.com',
  appName: 'candino',
  tiendaCode: ShopCode.Candino,
  cms: {
    local: false,
    endpoint: 'https://cms.festinagroup.com',
    bucket: 'bkt-festina-cms-strapi-env-prj-p-platform-ecommerce-1ef3',
    preview: false,
    env: CMSEnv.prod
  },
  imageConversor: {
    url: 'https://imageconv.festinagroup.com/convert?code=',
    basePath: 'festina-cms'
  },
  domain: 'https://candino.com',
  translateDomain: 'https://candino.com',
  imgServerUrl: 'https://static6.festinagroup.com/product/',
  urlServidorEstatico: 'https://media6.festinagroup.com/static/tiendas_online/',
  imgServerUrlLogoTienda: 'https://media6.festinagroup.com/static/tiendas_online/logos/',
  imgServerUrlHomeImages: 'https://media6.festinagroup.com/static/tiendas_online/candino/images/home',
  imgServerUrlMenuImages: 'https://media6.festinagroup.com/static/tiendas_online/candino/images/menu',
  imgServerUrlImages: 'https://media6.festinagroup.com/static/tiendas_online/candino/images',
  imgServerUrlLogoImages: 'https://media6.festinagroup.com/static/tiendas_online/candino/images/logos',
  imgServerUrlVideos: 'https://media6.festinagroup.com/static/tiendas_online/candino/videos',
  imgServerUrlSmartWatch: 'https://media6.festinagroup.com/static/tiendas_online/candino/smartwatch',
  apiDescargaManualesUrl: 'https://static6.festinagroup.com/product',
  imgServerUrlMapaImages: 'https://media6.festinagroup.com/static/tiendas_online/candino/images/mapa',
  imgServerUrlImagesPromociones: 'https://media6.festinagroup.com/static/tiendas_online/candino/images/promotions',
  documentsServerUrl: 'https://media6.festinagroup.com/static/tiendas_online/candino/documents',
  imgServerUrlSharedImages: 'https://media6.festinagroup.com/static/tiendas_online/shared/images',
  imgServerUrlSharedVideos: 'https://media6.festinagroup.com/static/tiendas_online/shared/videos',
  imgServerUrlPhotoReview: 'https://media6.festinagroup.com/static/logistica/photo-review',
  numeroProductosPorPagina: 12,
  modalSubscribe: false,
  modalSubscribeTime: 8000,
  // El primero de cada grupo de idiomas indica el que se escogerá por defecto
  idiomasAplicacion: [
    Language.English,
    Language.Spanish,
    Language.French,
    Language.Italian,
    Language.German,
    Language.Dutch,
    Language.Portuguese
  ],
  idiomaPorDefecto: Language.English,
  countrydefault: 'ES',
  idiomaCulturalSeparador: '-',
  reCaptcha: {
    enabled: true,
    siteKey: '6LcR8iwkAAAAAEYIE_Zdv7IrXUq9qGgdKtDAUW2K'
  },
  payPal: {
    messages: true,
    clientId: 'AcZmpdN7_YUz8eXs6bhmloQs4TLU10KO2JBpCn7527YbXQVt0O8NQLNws_T7pCsX2wBzEk9J_jJlRzeE'
  },
  stripe: {
    key: 'pk_test_51NnNKEDSaP4VoijmPFbI2pydGogVb93wnBjxC6Ckn2hJsH9ZEPGNcO7Q2KzURk6R7qxOb1ebbunhBOzm8IvIJV7M00fHn31b75'
  },
  google: {
    tagManagerId: 'GTM-WDM4WBX',
    googleTagManagerAuth: 'y5uZ4SyXiyJI4EIIXqKoBA',
    googleTagManagerPreview: 'env-1'
  },
  googleMaps: {
    key: 'AIzaSyCd3Kz19yz8NGrCBMnrGwUluWkOlYJGUhQ'
  },
  facebook: {
    appId: '125579485409570'
  },
  fileUpload: {
    extensionesImagenes: ['jpg', 'png'],
    extensionesFicheros: ['pdf'],
    tamanyoMaximo: 9000000
  },
  imgDefaultLazyLoading: 'https://media1.festina.com/static/tiendas_online/logos/candino.svg',
  urlInterceptorErrorEscaped: ['sfileserver.festina.com/Manual'],
  patronTelefono: '[0-9]+',
  seo: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Candino',
    logo: {
      '@type': 'ImageObject',
      url: 'https://candino.com/assets/img/logos/candino-negro.svg',
      caption: 'El logo de Candino',
      width: 182,
      height: 85
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Vía Layetana 20, 4º',
      addressLocality: 'Barcelona',
      addressRegion: 'Barcelona',
      postalCode: '08003',
      addressCountry: 'España'
    },
    email: 'atencioncliente@festina.com',
    legalName: 'Festina-Lotus S.A.',
    foundingDate: '1947',
    founders: {
      '@type': 'Person',
      name: 'Adolf Flury-Hug'
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+34 934867500',
      contactType: 'customer service',
      areaServed: 'ES',
      email: 'candino@festinagroup.com'
    },
    sameAs: [
      'https://facebook.com/CandinoSwissWatches/',
      'https://www.instagram.com/candino.watches/',
      'https://www.youtube.com/CandinoWatches'
    ]
  },
  fiestasNacionales: ['01-01', '01-06', '05-01', '06-01', '06-24', '08-06', '08-15', '09-11', '10-12', '12-08', '12-25', '12-26'],
  limiteDiasRespuestaIncidencia: 15,
  googleMerchantId: '285696531'
};
