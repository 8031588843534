import { NgModule } from '@angular/core';
import { ScrollDataAcessModule } from '../../../data-access/src';
import { ScrollDirective } from './scroll.directive';

@NgModule({
  imports: [ScrollDataAcessModule],
  declarations: [ScrollDirective],
  exports: [ScrollDirective]
})
export class ScrollModule {}
