import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { DeviceDetectorService } from '@fgp/shared/services/device-detector';
import { AlertService } from '@fgp/utils/alert';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptorCmsService implements HttpInterceptor {
  isBrowser = this.deviceDetectorService.isBrowser();

  constructor(
    private alertService: AlertService,
    @Inject(APP_CONFIG) private appConfig,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.appConfig.cms.endpoint)) {
      return next.handle(req).pipe(
        tap({
          error: (error: HttpErrorResponse) => {
            console.log(`Cms error ${JSON.stringify(error)}, url: ${req?.url}`);
            return throwError(() => error);
          }
        })
      );
    }
    return next.handle(req);
  }
}
