import { CommonModule } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'fgp-ui-redirect-by-country',
    imports: [CommonModule, TranslateModule, NgSelectModule, FormsModule, ReactiveFormsModule],
    template: `
    <form fgpInputRedError class="row text-center py-4" [formGroup]="redirectByCountryForm">
      <h4 class="col-12">
        {{ 'redirect-by-country.title' | translate }}
      </h4>
      <div class="col-12 subtitle-3 pt-3">
        {{ 'redirect-by-country.goToCountry' | translate: { country: countryName() } }}
      </div>
      <div class="col-12 pt-3">
        <a [href]="goToCountryUrl()" class="btn btn-link" class="btn btn-primary" type="button">
          {{ 'redirect-by-country.goToCountry.btn' | translate: { country: countryName() } }}
        </a>
      </div>

      <div class="col-12 pt-3">
        <a class="paragraph-1 text-underline cursor-pointer" (click)="closeModal()">
          {{ 'redirect-by-country.stayCountry' | translate: { country: 'common.pais.' + stayCountry() | translate } }}
        </a>
      </div>

      <!-- <div class="col-12 subtitle-3 pt-3">{{ 'redirect-by-country.searchCountry' | translate }}</div>
      <div class="offset-2 col-8 pt-3">
        <ng-select

          [items]="countries()"
          formControlName="country"
          [searchable]="false"
          [clearable]="false"
          appendTo="body"
          (change)="onChangeCountry()"
          placeholder="{{ 'commons.button.buscar' | translate }}"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ 'common.pais.' + item | translate }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index"> &nbsp; {{ 'common.pais.' + item | translate }} </ng-template>
        </ng-select>
      </div> -->
    </form>
    <div class="row text-center py-4"></div>
  `
})
export class UiRedirectByCountryComponent {
  countries = input<string[]>();
  countryName = input<string>();
  goToCountry = input<string>();
  goToCountryUrl = input<string>();
  stayCountry = input<string>();

  @Output() closeModalEvent = new EventEmitter<boolean>();

  redirectByCountryForm = new FormGroup({
    country: new FormControl<string>(this.goToCountry())
  });

  closeModal() {
    this.closeModalEvent.emit(true);
  }
}
