[
  {
    "src": "icono_visa.svg",
    "title": "Visa",
    "height": 25,
    "width": 30
  },
  {
    "src": "visa.svg",
    "title": "Visa",
    "height": 13,
    "width": 42
  },
  {
    "src": "paypal.svg",
    "title": "Paypal",
    "height": 15,
    "width": 56
  }
]
