[
  {
    "title": "ventajas.deliveryTime",
    "description": "ventajas.entrega.desc",
    "icon": "icon-entrega"
  },
  {
    "title": "ventajas.pago.titulo",
    "description": "ventajas.devolucion.desc",
    "icon": "icon-pago"
  },
  {
    "title": "ventajas.garantia.titulo",
    "description": "ventajas.garantia.desc",
    "icon": "icon-garantia"
  }
]
