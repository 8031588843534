{
  "reloj": {
    "h1": "Watches",
    "meta": "Looking for a unique and elegant watch? Discover our watches from the best brands and find the perfect model for you. {{numPagina}}",
    "title": "Watches {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Watches {{colorCorrea}}",
    "meta": "Do you have a weakness for Watches {{colorCorrea}}? Discover the most personal models of the best brands and get your perfect {{colorCorrea}} watch. {{numPagina}}",
    "title": "Watches {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Watches {{colorEsfera}}",
    "meta": "Do you have a weakness for Watches {{colorEsfera}}? Discover the most personal models of the best brands and get your perfect {{colorEsfera}} watch. {{numPagina}}",
    "title": "Watches {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Watches {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Looking for a unique and elegant watch? Discover our watches from the best brands and find the perfect model for you. {{numPagina}}",
    "robots": "false",
    "title": "Watches {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Watches {{estilo}}",
    "meta": "In our online store you will find a wide selection of {{estilo}} Watches perfect for you. Buy your favorite {{estilo}} Watch online now! {{numPagina}}",
    "title": "Watches {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Watches {{funcion}}",
    "meta": "Looking for a Watche {{funcion}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Find your new favorite Smartwatch. Discover all our models and give yourself a treat! Visit our online catalogue now!",
    "title": "{{funcion}} | Candino"
  },
  "reloj.gama": {
    "h1": "Watches for {{gama}}",
    "meta": "Are you looking for a watch that suits your lifestyle? Take a look at our catalog of watches for {{gama}} and choose a model 'made in Switzerland', of excellent quality.",
    "title": "Watches for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Watches {{colorCorrea}} for {{gama}}",
    "meta": "Discover the best Watch models for {{gama}} {{colorCorrea}}. Find the ideal watch for you in our online catalog. Visit us now! {{numPagina}}",
    "title": "Watches {{colorCorrea}} for {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "{{colorCorrea}} Watches for {{gama}}",
    "meta": "Are you looking for a watch that suits your style? Check out our catalog of {{colorCorrea}} Watches for {{gama}} and show off the model made in Switzerland.",
    "title": "{{colorCorrea}} Watches for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Watches {{gama}} Dial {{colorEsfera}}",
    "meta": "Discover the best Watch models for {{gama}} with colored dial {{colorEsfera}}. Find the ideal watch for you in our online catalog. Visit us now! {{numPagina}}",
    "title": "Watches {{gama}} Dial {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Watches for {{gama}} with a {{colorEsfera}} watch face",
    "meta": "Are you looking for a watch that suits your style? Check out our Watches for {{gama}} with a {{colorEsfera}} watch face and show off the model made in Switzerland.",
    "title": "Watches for {{gama}} with a {{colorEsfera}} watch face | Candino Swiss Watch"
  },
  "reloj.gama.default": {
    "h1": "Watches {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Looking for a unique and elegant watch? Discover our watches from the best brands and find the perfect model for you. {{numPagina}}",
    "title": "Watches {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Watches {{gama}} {{estilo}}",
    "meta": "Explore our selection of {{gama}} {{estilo}} watches made with the best materials and find the perfect model for you. {{numPagina}}",
    "title": "Watches {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Watches for {{gama}} {{funcion}}",
    "meta": "Are you looking for {{gama}} {{funcion}}Watches? Take a look at our wide range of watches for {{gama}} and choose the perfect model for you. {{numPagina}}",
    "title": "Watches for {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "{{funcion}} Watches for {{gama}}",
    "meta": "Are you looking for a {{funcion}} watch adapted to your lifestyle? Consult our catalog of {{funcion}} watches for {{gama}} and boast of a 'made in Switzerland' model.",
    "title": "{{funcion}} Watches for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch for {{gama}}",
    "meta": "Get your Smartwatch for {{gama}} now! Discover all our new models and find your ideal one. Perfect for any occasion.",
    "title": "Smartwatch for {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": " {{gama}} watches with {{materialCaja}}casing",
    "meta": "Get {{gama}} from {{materialCaja}} Watches from the best brands and experience comfort on your wrist. Visit our website now! {{numPagina}}",
    "title": " {{gama}} watches with {{materialCaja}} {{numPagina}} casing | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "{{materialCorrea}} Watches for {{gama}}",
    "meta": "Are you looking for a watch adapted to your lifestyle? Consult our catalog of {{materialCorrea}} watches for {{gama}} and show off the model made in Switzerland.",
    "title": "{{materialCorrea}} Watches for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch for {{gama}}",
    "meta": "Get your Smartwatch for {{gama}}now! Discover all our models and find yours. Ideal for any occasion. {{numPagina}}",
    "title": "Smartwatch for {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Watches with {{tipoCierre}} for {{gama}}",
    "meta": "Are you looking for a watch that suits your style? Consult our catalog of watches with {{tipoCierre}} for {{gama}} and show off the model made in Switzerland.",
    "title": "Watches with {{tipoCierre}} for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Submersible watches {{tipoEstanqueidad}} for {{gama}}",
    "meta": "Are you looking for a waterproof but stylish watch? Consult our catalog of submersible watches {{tipoEstanqueidad}} for {{gama}} and show off the Swiss technology.",
    "title": "Submersible watches {{tipoEstanqueidad}} for {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digital Watches for {{gama}}",
    "meta": "Are you looking for a digital watch for {{gama}}? At Candino we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Digital Watches for {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Watches for {{gama}} with Digital Quartz movement.",
    "meta": "Are you looking for {{gama}} watches with Digital Quartz movement? Take a look at our wide range of watches for {{gama}} and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches for {{gama}} with Digital Quartz movement | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatic Watches for {{gama}}",
    "meta": "Are you looking for an automatic watch for {{gama}}? At Candino, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Automatic Watches for {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybrid Watches for {{gama}}",
    "meta": "Are you looking for a hybrid watch for {{gama}}? At Candino, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Hybrid Watches for {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches for {{gama}}",
    "meta": "Are you looking for a smartwatch for {{gama}}? At Candino we have a great selection of smartwatches available. Come in and choose the one that best suits you.",
    "title": "Smartwatches for {{gama}} - Smartwatches | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Quartz Watches for {{gama}}",
    "meta": "Are you looking for a quartz watch for {{gama}}? At Candino, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Quartz Watches for {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Youth Watches",
    "meta": "Find the best Youth watches in our catalogue. Help them learn the time with the best models from the best brands.",
    "title": "Youth Watches | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Watches {{estilo}} for Children",
    "meta": "Find the best {{estilo}} Watches for Children on our website. Make a good gift to the little ones in the house and teach them to read the time! {{numPagina}}",
    "title": "Watches {{estilo}} for Children {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "{{funcion}} Youth Watches",
    "meta": "Do you want a {{funcion}} youth watch? Find a variety of kids' watches {{funcion}} from the best brands. Visit our catalogue!",
    "title": "{{funcion}} Youth Watches | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Kids Smartwatch",
    "meta": "Discover the best Smartwatch models for children and make them start interacting with technology. Apps, messaging, calls, GPS and much more {{numPagina}}",
    "title": "Smartwatch for Children {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Watches {{marca}}",
    "meta": "Do not miss the best models of Watch {{marca}}. Visit our website and discover our wide range of models. {{numPagina}}",
    "title": "Watches {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "{{marca}} Watches for {{gama}}",
    "meta": "Do not miss the best and most exclusive models of {{marca}} Watches for {{gama}}. Visit our website and discover our wide range of models.",
    "title": "{{marca}} Watches for {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Watches {{marca}} {{colorCorrea}} for {{gama}}",
    "meta": "Looking for a Watch {{marca}} for {{gama}} {{colorCorrea}}? Find in our catalog all the colors of watches {{marca}} for {{gama}}. {{numPagina}}",
    "title": "Watches {{marca}} {{colorCorrea}} pour {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "{{marca}} Watches in {{colorCorrea}}  for {{gama}}",
    "meta": "Looking for a {{colorCorrea}} {{marca}} Watch for {{gama}}? Find in our catalogue all the different colored {{marca}} watches for {{gama}}.",
    "title": "{{marca}} Watches in {{colorCorrea}}  for {{gama}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Watches {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "Looking for a Watch {{marca}} for {{gama}} {{colorEsfera}}? Find in our catalog all the colors of watches {{marca}} for {{gama}}. {{numPagina}}",
    "title": "Watches {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "{{marca}} Watches in {{colorEsfera}}  for {{gama}}",
    "meta": "Looking for a {{colorEsfera}} {{marca}} Watch for {{gama}}? Find in our catalogue all the different colored {{marca}} watches for {{gama}}.",
    "title": "{{marca}} Watches in {{colorEsfera}}  for {{gama}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Watches {{marca}} {{gama}} {{estilo}}",
    "meta": "Find the latest news in Watches {{marca}} for {{gama}} {{estilo}}. Watches with personality and unique design. Visit our catalog! {{numPagina}}",
    "title": "Watches {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Watches {{marca}} {{funcion}} for {{gama}}",
    "meta": "Visit our website and discover our wide range of Watches {{marca}} {{funcion}} for {{gama}}. Get the Watch that best suits you. {{numPagina}}",
    "title": "Watches {{marca}} {{funcion}} for {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "{{marca}} {{funcion}} Watches for {{gama}}",
    "meta": "Visit our website and discover our wide range of {{marca}} {{funcion}} Watches for {{gama}}. Get the Watch that best suits you.",
    "title": "{{marca}} {{funcion}} Watches for {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "{{marca}} Youth Watches",
    "meta": "Are you looking for {{marca}} Youth Watches? Discover in our catalogue all the new watches for the little ones. Visit our website now!",
    "title": "{{marca}} Youth Watches | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Watches with {{materialCaja}}case",
    "meta": "Do you like {{materialCaja}}watches? Discover our extensive catalog and find the watch that makes your wrist shine. {{numPagina}}",
    "title": "Watches with {{materialCaja}} {{numPagina}} case | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Watches {{materialCorrea}}",
    "meta": "Do you like watches with a {{materialCorrea}}strap? Discover our extensive catalog and find the watch that makes your wrist shine. {{numPagina}}",
    "title": "Watches {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Watches {{tipoCierre}}",
    "meta": "Looking for a Watch {{tipoCierre}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Watches {{tipoCorrea}}",
    "meta": "Looking for a Watch {{tipoCorrea}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digital Watches",
    "meta": "Are you looking for a digital watch? At Candino, we have a great selection of digital watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Digital Watches | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Watches with movement Digital Quartz",
    "meta": "Are you looking for watches with Digital Quartz movement? Take a look at our wide range of watches and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches with movement Digital Quartz | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Watches with movement Manual",
    "meta": "Are you looking for watches with Manual movement? Take a look at our wide range of watches and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches with movement Manual | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatic Watches",
    "meta": "Are you looking for an automatic watch? At Candino, we have a great selection of automatic watches available. Come in and choose the one that best suits you.",
    "title": "Automatic Watches | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybrid Watches",
    "meta": "Are you looking for an automatic watch? At Candino, we have a great selection of automatic watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Hybrid Watches | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Are you looking for a smartwatch? At Candino, we have a great selection of smartwatches available. Come in and choose the one that best suits your style.",
    "title": "Smartwatches - Smartwatches | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Quartz Watches",
    "meta": "Are you looking for a quartz watch? At Candino, we have a great selection of quartz watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Quartz Watches | Candino"
  }
}
