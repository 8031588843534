{
  "acabado.AC-OR": "STAAL-GOUD",
  "acabado.B": "helderheid",
  "acabado.BP01": "BP01",
  "acabado.BP01C": "KLEUR CIRKEL",
  "acabado.BSL": "BSL",
  "acabado.CAUCH": "Rubber",
  "acabado.CORB": "Binden",
  "acabado.CRISA": "POP",
  "acabado.ESTAM": "Afdrukken",
  "acabado.F+R": "FLAT + ROND",
  "acabado.F+S": "PLAT + RECHT",
  "acabado.F+S C": "FLAT + COLOR REC",
  "acabado.F+T": "FLAT + TIE",
  "acabado.FAC06": "FAC06",
  "acabado.FR11": "FRAP 11",
  "acabado.FRA15": "FRAP15",
  "acabado.GRAB": "Opgenomen",
  "acabado.LAPI": "LAPIDADO",
  "acabado.LASER": "zijn",
  "acabado.M": "MAAT",
  "acabado.M+S": "BERG + RECHT",
  "acabado.M+S C": "MONT.RECHT.KLEUR",
  "acabado.M+T": "BERG + STROPDAS",
  "acabado.MAL": "Mesh",
  "acabado.MB": "MAT-GLANZEND",
  "acabado.MECA": "Bewerking",
  "acabado.MF5P": "MICROFUSIE 5 ST",
  "acabado.MMILA": "MILAAN MESH",
  "acabado.MOUNT": "Montana",
  "acabado.MTCA": "MATE CALADA",
  "acabado.OYS": "OESTER",
  "acabado.PIR3": "PYRAMIDAAL 3M",
  "acabado.PMP": "PULI-MATE-PULI",
  "acabado.PPM": "GEPOLIJST-MAT PANTER",
  "acabado.PPU": "GEPOLIJST PANTER",
  "acabado.PRES": "President",
  "acabado.PULID": "GEPOLIJST",
  "acabado.RAS": "GEKRASSEN",
  "acabado.ROUND": "Ronde",
  "acabado.S7M": "ESTAM 7 3MAT",
  "acabado.S7R": "STEMPEL 7 STUKS",
  "acabado.S73M": "ESTAMP 7-3MATE",
  "acabado.ST3": "3 STUKS STEMPELEN",
  "acabado.ST4": "STEMPELEN 4 STUKS",
  "acabado.ST5": "GESTEMPELD 5 STUKS",
  "acabado.ST6": "GESTEMPELD 6 STUKS",
  "acabado.ST7": "7 STUKS",
  "acabado.TPMP": "BUIS P-M-P",
  "acabado.TUB": "BUIS",
  "acabado.V620": "V620",
  "acabado.Y": "GEPOLIJSTE BERG",
  "acabado.YHLO": "BERG 1 / 2B + M",
  "accesorios.subtipo.BALLPOINT_PEN": "balpen",
  "accesorios.subtipo.BELT": "Riem",
  "accesorios.subtipo.CARD_HOLDER": "kaarthouder",
  "accesorios.subtipo.FOLDER": "bindmiddel",
  "accesorios.subtipo.FOUNTAIN_PEN": "Vulpen",
  "accesorios.subtipo.LEATHER_PEN_SET": "set leren pennen",
  "accesorios.subtipo.OPTIC": "optische bril",
  "accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
  "accesorios.subtipo.PEN": "pen",
  "accesorios.subtipo.REPLACEMENT": "Reserveonderdeel",
  "accesorios.subtipo.ROLLERBALL_PEN": "balpen",
  "accesorios.subtipo.SOLAR": "Zonnebril",
  "accesorios.subtipo.WALLET": "portemonnee",
  "accesorios.subtipo.WRITING_SET": "schrijfset",
  "accesorios.tipo.CLEANER": "schoonmakers",
  "accesorios.tipo.EYEWEAR": "Bril",
  "accesorios.tipo.FRAGANCE": "parfums",
  "accesorios.tipo.JEWELLERY_BOX": "Juweliers",
  "accesorios.tipo.KEYCHAIN": "sleutelhangers",
  "accesorios.tipo.LEATHER_ITEM": "leder artikel",
  "accesorios.tipo.SET": "sets",
  "accesorios.tipo.SPARE_PARTS": "reserveonderdelen",
  "accesorios.tipo.TOOL": "gereedschap",
  "accesorios.tipo.WRITING": "schrijven",
  "accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
  "accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
  "agrupacion.coleccion": "verzameling",
  "agrupacion.seleccion": "selectie",
  "anchopuente.14": "14 mm",
  "anchopuente.15": "15 mm",
  "anchopuente.16": "16 mm",
  "anchopuente.17": "17 mm",
  "anchopuente.18": "18 mm",
  "anchopuente.21": "21 mm",
  "calendario.false": "GEEN",
  "calendario.true": "Ja",
  "caracteristicas.alarma": "Alarm",
  "caracteristicas.balancier_visible": "Zichtbare balans",
  "caracteristicas.complicacion_automatico": "Automatische complicatie",
  "caracteristicas.corona_rosca": "Kransbrood",
  "caracteristicas.dual_time": "Dubbele tijd",
  "caracteristicas.gmt": "GMT",
  "caracteristicas.luminova": "Luminova",
  "caracteristicas.luz": "Licht",
  "caracteristicas.reserva_marcha": "Gangreserve",
  "caracteristicas.skeleton": "Skeleton",
  "caracteristicas.solar": "Zonne",
  "caracteristicas.superluminova": "Superluminova",
  "caracteristicas.tourbillon": "Tourbillon",
  "cierre.agrupado.CAJ": "Plooislot",
  "cierre.agrupado.CAP": "KNOOP DOOS",
  "cierre.agrupado.CCJ": "SLUITING VAN DE LADE",
  "cierre.agrupado.CI": "karabijnsluiting",
  "cierre.agrupado.CIP": "Druk",
  "cierre.agrupado.CM": "MAGNETISCH",
  "cierre.agrupado.CPC": "DOOS PULS RIEM",
  "cierre.agrupado.CPL": "KANT",
  "cierre.agrupado.CPR": "Druk",
  "cierre.agrupado.CSE": "Plooislot",
  "cierre.agrupado.CSP": "VEILIGE SLUITING DRUKKNOP",
  "cierre.agrupado.HEB": "Gespsluiting",
  "cierre.agrupado.HKSALO": "KLUIS SLOT",
  "cierre.agrupado.LI": "Druksluiting",
  "cierre.agrupado.MOS": "KARABINER",
  "cierre.agrupado.OCL": "Verborgen",
  "cierre.agrupado.OCP": "VERBORGEN DRUKKNOP",
  "cierre.agrupado.OCU": "Afrikaans",
  "cierre.agrupado.ROS": "DRAAD SLUITING",
  "cierre.agrupado.SO": "Dubbele drukknop",
  "cierre.agrupado.SOP": "KNOOP KLEP",
  "cierre.CAJ": "Plooislot",
  "cierre.CAP": "KNOOP DOOS",
  "cierre.CCJ": "LADE SLUITING (STIJL)",
  "cierre.CI": "karabijnsluiting",
  "cierre.CIP": "DRUK SLUITING",
  "cierre.CM": "MAGNETISCH",
  "cierre.CMS": "MAGNETISCH MET VERZEKERING",
  "cierre.CPC": "RIEM PULS DOOS",
  "cierre.CPL": "PULS LATER DOOS.",
  "cierre.CPR": "Druk",
  "cierre.CSE": "Plooislot",
  "cierre.CSP": "VEILIGE SLUITING DRUKKNOP",
  "cierre.HEB": "Gespsluiting",
  "cierre.HKBB": "DE GESP VAN DE VLINDER",
  "cierre.HKCB": "KLEM GESP",
  "cierre.HKCLB": "CLIP LOCK GESP",
  "cierre.HKJEWBUK": "SIERADEN GESP",
  "cierre.HKLB": "LEREN GESP",
  "cierre.HKLOBUK": "Vlinderslot",
  "cierre.HKMB": "MAGNETISCHE GESP",
  "cierre.HKSALO": "KLUIS SLOT",
  "cierre.HKSCB": "SPRING C-CLIP GESP",
  "cierre.HKT-BABUK": "T-BAR GESP",
  "cierre.HOR": "GOUDEN GESP",
  "cierre.LI": "Druksluiting",
  "cierre.MOS": "karabijnsluiting",
  "cierre.NEO": "DRAAGT NIET",
  "cierre.NOSE": "Weet niet",
  "cierre.OCH": "VERBORGEN SLUITING GESP",
  "cierre.OCL": "Plooislot",
  "cierre.OCP": "VERBORGEN DRUKKNOP",
  "cierre.OCS": "VERBORGEN KLEP",
  "cierre.OCU": "Afrikaans",
  "cierre.OPC": "VERBORGEN STRAP PUSH",
  "cierre.OSP": "VERBORGEN KLEPPULS",
  "cierre.ROS": "DRAAD SLUITING",
  "cierre.SO": "Dubbele drukknop",
  "cierre.SOC": "KLEPRIEM",
  "cierre.SOH": "FLAP GESP",
  "cierre.SOP": "KNOOP KLEP",
  "cierre.SPC": "FLAP PULS RIEM",
  "cierre.undefined": "",
  "colores_base.1": "CRO",
  "colores_base.2N18": "2N18 GOUDEN",
  "colores_base.2NBN": "CENTRUM 2N18 ZWART LICHAAM",
  "colores_base.2NMA": "2N18 MET MERKEN",
  "colores_base.2NTN": "2N18 MET ZWARTE FILET",
  "colores_base.3N": "3N GOUD",
  "colores_base.3N18": "3N18",
  "colores_base.4N18": "LICHT ROOS GOUD",
  "colores_base.5N": "5N ROSE GOUD",
  "colores_base.5N18": "ROZE GOUD",
  "colores_base.026": "IVOOR",
  "colores_base.032": "Donker bruin",
  "colores_base.037": "HAVANA BRUIN",
  "colores_base.041": "MEDIUM GOUD",
  "colores_base.061": "MEDIUM BLAUW",
  "colores_base.065": "LICHTBLAUW",
  "colores_base.066": "LICHTBLAUW",
  "colores_base.068": "AZUUR",
  "colores_base.084": "KORAAL",
  "colores_base.100": "GLANZEND STAAL",
  "colores_base.128": "Naakt",
  "colores_base.591": "PVD ZWART",
  "colores_base.600": "Rose goud",
  "colores_base.840": "STAAL + GOUD",
  "colores_base.850": "STAAL + ROOS GOUD",
  "colores_base.ACCC": "STAAL ZIRCONIA",
  "colores_base.AG": "Zilver",
  "colores_base.AGAB": "ZILVEREN AMBER",
  "colores_base.AGCR": "ZILVEREN KRISTAL",
  "colores_base.AGLA": "LAVENDEL ZILVER",
  "colores_base.AGLM": "ZILVEREN KALK",
  "colores_base.AGMC": "GEMARKEERD ZILVER",
  "colores_base.AGMO": "ZILVER PAARS",
  "colores_base.AGPE": "ZILVEREN PAREL",
  "colores_base.AGRO": "ZILVER ROOD",
  "colores_base.AGTP": "ZILVEREN TOPAAS",
  "colores_base.AGU": "Aquamarijn",
  "colores_base.AM": "Geel",
  "colores_base.AMA": "Amethist",
  "colores_base.AMBA": "AMBER",
  "colores_base.AMBR": "BRONS GEEL",
  "colores_base.AMCC": "GEEL ZIRCONIA",
  "colores_base.AMMA": "BRUIN GEEL",
  "colores_base.AMPE": "GELE PAREL",
  "colores_base.AMT": "Amethist",
  "colores_base.AQ": "Aquamarijn",
  "colores_base.AQBO": "AQUA BOHEMICA",
  "colores_base.AUR": "DAGERAAD",
  "colores_base.AURB": "NOORDERLICHT",
  "colores_base.AVAZ": "BLAUW AVONTUUR",
  "colores_base.AZ": "blauw",
  "colores_base.AZRS": "BLAUW ROZE",
  "colores_base.BDIA": "ZWARTE DIAMANT",
  "colores_base.BE": "Beige",
  "colores_base.BICO": "Tweekleurig",
  "colores_base.BL": "wit",
  "colores_base.BLCO": "KORAALWIT",
  "colores_base.BLPE": "WITTE PAREL",
  "colores_base.BLRY": "GESTREEPT WIT",
  "colores_base.BR": "Bronzen",
  "colores_base.BRCC": "BRONZEN ZIRCONS",
  "colores_base.BRIL": "SPARKLY",
  "colores_base.BRLI": "BRONS LILAC",
  "colores_base.BRRS": "BRONS ROZE",
  "colores_base.BU": "BORDEAUX",
  "colores_base.BUCR": "BOURGONDISCHE ROOM",
  "colores_base.CA": "KAMEEL",
  "colores_base.CABA": "PAARD",
  "colores_base.CAL": "Slepen",
  "colores_base.CALD": "Boiler",
  "colores_base.CCPE": "ZIRCONIA PAREL",
  "colores_base.CER": "KERS",
  "colores_base.CH": "CHAMPAGNE",
  "colores_base.CHA": "VERPLATEERD",
  "colores_base.ChGR": "GRIJS GEPLATEERD",
  "colores_base.CHOC": "CHOCOLA",
  "colores_base.CIRC": "Zirkonia",
  "colores_base.CITR": "CITRINE",
  "colores_base.CM": "ROOM",
  "colores_base.CMFU": "FUCHSIA CRÈME",
  "colores_base.CO": "koper",
  "colores_base.CORA": "KORAAL",
  "colores_base.CQ": "KHAKI",
  "colores_base.CR": "VERCHROOMD",
  "colores_base.CRDO": "GOUDEN KRISTAL",
  "colores_base.CRIS": "Kristal",
  "colores_base.CRTR": "TRANSPARANT KRISTAL",
  "colores_base.CURR": "CURRO",
  "colores_base.CZ": "KWARTS",
  "colores_base.CZAZ": "BLAUWE KWARTS",
  "colores_base.CZMA": "BRUINE KWARTS",
  "colores_base.CZRS": "ROZE KWARTS",
  "colores_base.DELF": "DOLFIJN",
  "colores_base.DI": "Digitaal",
  "colores_base.DIAM": "DIAMANTEN",
  "colores_base.DO": "GOUDEN",
  "colores_base.DONE": "GOUDEN ZWART",
  "colores_base.ESM": "Smaragd",
  "colores_base.EXPO": "EXPO",
  "colores_base.FLOR": "Bloem",
  "colores_base.FLUO": "FLUORESCEREND",
  "colores_base.FOCA": "zegel",
  "colores_base.FR": "Aardbei",
  "colores_base.FRAM": "FRAMBOOS",
  "colores_base.FU": "FUCHSIA",
  "colores_base.FUCC": "FUCHSIA ZIRCONS",
  "colores_base.FUME": "Rook",
  "colores_base.GATO": "kat",
  "colores_base.GN": "Granaat",
  "colores_base.GR": "GRIJS",
  "colores_base.GRA": "Granaat",
  "colores_base.GRAG": "Grijs zilver",
  "colores_base.GRPL": "ZILVER GRIJS",
  "colores_base.HA": "HAMILTON",
  "colores_base.HAB": "HAVANA",
  "colores_base.HAHA": "HAMILTON HAMILTON",
  "colores_base.HAMC": "HAMILTON GEMARKEERD",
  "colores_base.HANA": "HAMILTON NACAR",
  "colores_base.HANC": "HAMILTON MOEDER VAN PAREL",
  "colores_base.HEM": "HEMATIET",
  "colores_base.INDI": "INDICOLIET",
  "colores_base.JET": "JET",
  "colores_base.KOAL": "KOALA",
  "colores_base.LI": "LILA",
  "colores_base.LIOS": "DONKERE LILAC",
  "colores_base.LIPE": "LILAC PAREL",
  "colores_base.LUMI": "SUPERLUMINOVA",
  "colores_base.LUN": "Maan",
  "colores_base.MA": "bruin",
  "colores_base.MAAG": "BRUIN / ZILVER",
  "colores_base.MAPE": "PAREL BRUIN",
  "colores_base.MBLU": "METALLIC BLAUW",
  "colores_base.MC": "Gemarkeerd",
  "colores_base.ME": "PERZIK",
  "colores_base.MEN": "Munt",
  "colores_base.MF": "IVOOR",
  "colores_base.MFNE": "IVOOR-ZWART",
  "colores_base.MGRE": "METAALGROEN",
  "colores_base.MO": "Purper",
  "colores_base.MOBL": "PAARS WIT",
  "colores_base.MOS": "Mosterd",
  "colores_base.MS": "Mosterd",
  "colores_base.MSIL": "METALLISCH ZILVER",
  "colores_base.MT": "MAAT",
  "colores_base.MULT": "VEELKLEURIG",
  "colores_base.MULTAM": "VEELKLEURIG GEEL",
  "colores_base.MULTAZ": "VEELKLEURIG BLAUW",
  "colores_base.MULTRS": "VEELKLEURIG ROZE",
  "colores_base.NA": "NACRE",
  "colores_base.NAAZ": "NACAR BLAUW",
  "colores_base.NE": "Zwart",
  "colores_base.NEAM": "ZWART GEEL",
  "colores_base.NECC": "ZWARTE ZIRCONS",
  "colores_base.NEDO": "ZWART GOUD",
  "colores_base.NEGD": "ZWARTE DIAMANT",
  "colores_base.NEO": "DRAAGT NIET",
  "colores_base.NEPE": "ZWARTE PAREL",
  "colores_base.NJ": "Oranje",
  "colores_base.NJNC": "ORANJE PAREL",
  "colores_base.NYB": "WIT NYLON",
  "colores_base.NYBE": "NYLON BEIG",
  "colores_base.NYBL": "WIT NYLON",
  "colores_base.NYC": "NYLON ROOM",
  "colores_base.NYCM": "NYLON ROOM",
  "colores_base.NYG": "GRIJS NYLON",
  "colores_base.NYGR": "GRIJS NYLON",
  "colores_base.NYM": "NYLON BRUIN",
  "colores_base.NYMA": "NYLON BRUIN",
  "colores_base.NYN": "ZWART NYLON",
  "colores_base.NYNE": "ZWART NYLON",
  "colores_base.NYO": "NYLON OKER",
  "colores_base.NYR": "ROOD NYLON",
  "colores_base.OBSI": "OBSIDIAN",
  "colores_base.OC": "OKER",
  "colores_base.OCR": "OKER",
  "colores_base.OLIV": "OLIVINA",
  "colores_base.ONIX": "ONYX",
  "colores_base.OR": "Goud",
  "colores_base.ORAZ": "BLAUW GOUD",
  "colores_base.PACH": "BLAUWE CHAMPAGNE",
  "colores_base.PAJA": "VOGEL",
  "colores_base.PAV": "PAVONADO",
  "colores_base.PD": "Palladium",
  "colores_base.PER": "Parel",
  "colores_base.PERN": "ZWARTE PAREL",
  "colores_base.PERR": "Hond",
  "colores_base.PLAG": "ZILVER ZILVER",
  "colores_base.PLAM": "ZILVER GEEL",
  "colores_base.PLAZ": "BLAUW ZILVER",
  "colores_base.PLLI": "LILAC ZILVER",
  "colores_base.PLNE": "ZILVER ZWART",
  "colores_base.PLPL": "ZILVER ZILVER",
  "colores_base.PLRS": "ROZE ZILVER",
  "colores_base.PLVE": "ZILVERGROEN",
  "colores_base.PV": "PAVONADO",
  "colores_base.PVCH": "BLAUWE CHAMPAGNE",
  "colores_base.PVD": "PVD",
  "colores_base.RH": "Rhodium",
  "colores_base.RH5N": "WIT GOUD + 5N GOUD",
  "colores_base.RO": "Rood",
  "colores_base.ROCA": "RODE CALADA",
  "colores_base.ROMS": "RODE MOSTERD",
  "colores_base.RONE": "ROOD ZWART",
  "colores_base.RS": "Roos",
  "colores_base.RSCC": "ROZE ZIRCONIA",
  "colores_base.RSPE": "PAREL ROZE",
  "colores_base.RSRO": "ROZE ROOD",
  "colores_base.RU": "RUTHENIUM",
  "colores_base.RUBI": "Robijn",
  "colores_base.SASA": "ZALM ZALM",
  "colores_base.ST": "SEMITRANSPARANT",
  "colores_base.TA": "BLAUWE TIJGER",
  "colores_base.TEJA": "DAKPAN",
  "colores_base.TIT": "TITANIUM",
  "colores_base.TOP": "TOPAZIO",
  "colores_base.TP": "Topaas",
  "colores_base.TPCL": "LICHT TOPAAS",
  "colores_base.TR": "ROZE TIJGER",
  "colores_base.TRAN": "Transparant",
  "colores_base.TRCO": "TRICOLOR",
  "colores_base.TRI": "TRICOLOR",
  "colores_base.TRNE": "TRANSP ZWART",
  "colores_base.TW/W": "TOP WESSELTON TOT WESSELTON",
  "colores_base.VA": "VANILLE",
  "colores_base.VE": "Groen",
  "colores_base.VEBRI": "GLANZEND GROEN",
  "colores_base.VECC": "GROEN ZIRCONIA",
  "colores_base.VI": "Paars",
  "colores_base.VIDI": "DIGITAAL VIOLET",
  "colores_base.VIGR": "VIOLET GRIJS",
  "colores_base.VIN": "wijn",
  "colores_base.VIOS": "DONKER VIOLET",
  "colores_base.ZAFI": "Saffier",
  "colores_catalogo.AG": "Zilver",
  "colores_catalogo.AM": "Geel",
  "colores_catalogo.AZ": "blauw",
  "colores_catalogo.BE": "Beige",
  "colores_catalogo.BL": "wit",
  "colores_catalogo.BU": "BORDEAUX",
  "colores_catalogo.CH": "CHAMPAGNE",
  "colores_catalogo.CM": "ROOM",
  "colores_catalogo.CO": "koper",
  "colores_catalogo.DO": "GOUDEN",
  "colores_catalogo.GR": "GRIJS",
  "colores_catalogo.MA": "bruin",
  "colores_catalogo.MULT": "VEELKLEURIG",
  "colores_catalogo.NA": "NACRE",
  "colores_catalogo.NE": "Zwart",
  "colores_catalogo.NJ": "Oranje",
  "colores_catalogo.PL": "Zilver",
  "colores_catalogo.PLA": "Zilver",
  "colores_catalogo.PLAZ": "BLAUW ZILVER",
  "colores_catalogo.PLRS": "ROZE ZILVER",
  "colores_catalogo.RO": "Rood",
  "colores_catalogo.ROSE": "Roos",
  "colores_catalogo.RS": "Roos",
  "colores_catalogo.VE": "Groen",
  "colores_catalogo.VI": "Paars",
  "correa.agrupada.ARMYS": "LEGERS",
  "correa.agrupada.CORREA": "riem",
  "estanqueidad.1A": "1 geldautomaat",
  "estanqueidad.3A": "3 ATM",
  "estanqueidad.5A": "5 ATM",
  "estanqueidad.10A": "10 ATM",
  "estanqueidad.20A": "20 ATM",
  "estanqueidad.?": "Geen beklemming",
  "estanqueidad.IP68": "IP68",
  "estanqueidad.NEO": "NEEN",
  "estanqueidad.R.P.": "STOFBESTENDIG",
  "estanqueidad.RP": "STOFBESTENDIG",
  "estanqueidad.SHPR": "DOUCHEBESTENDIG",
  "estanqueidad.W.P.": "WATER PROFF.",
  "estanqueidad.W.R.": "WATERAFSTOTEND",
  "estanqueidad.WR": "WATERAFSTOTEND",
  "estilos.CA": "GEWOONTJES",
  "estilos.CH": "Chique",
  "estilos.CL": "Klassiek",
  "estilos.EL": "ELEGANTIE",
  "estilos.LE": "BEPERKTE EDITIE",
  "estilos.SP": "sport",
  "estilos.VI": "Wijnoogst",
  "formaCaja.C": "Plein",
  "formaCaja.CR": "Kruis",
  "formaCaja.DI": "Digitaal",
  "formaCaja.IR": "ONREGELMATIG",
  "formaCaja.O": "ovaal",
  "formaCaja.RC": "Rechthoekig",
  "formaCaja.RD": "Ronde",
  "formaCaja.TO": "VAT",
  "formaCaja.TV": "Televisie",
  "funcion.agrupada.101": "Digitale Functies",
  "funcion.agrupada.102": "Multifunctioneel",
  "funcion.agrupada.103": "UREN EN MINUTEN",
  "funcion.agrupada.104": "Chronograaf",
  "funcion.agrupada.105": "Slimme Functies",
  "gamas.?": "",
  "gamas.agrupada.BE": "Baby",
  "gamas.agrupada.C": "Heren",
  "gamas.agrupada.IN": "Kinderen",
  "gamas.agrupada.NA": "Meisje",
  "gamas.agrupada.NI": "Jongen",
  "gamas.agrupada.S": "Dames",
  "gamas.agrupada.UN": "Unisex",
  "gamas.AN": "Ringen",
  "gamas.BE": "Baby",
  "gamas.C": "Heren",
  "gamas.CD": "JUNIOR",
  "gamas.CO": "Hanger",
  "gamas.IN": "JUNIOR",
  "gamas.NA": "JUNIOR",
  "gamas.NI": "JUNIOR",
  "gamas.PE": "Dames",
  "gamas.PU": "ARMBANDEN",
  "joyas.tipo.agrupada.ANILLO": "Ring",
  "joyas.tipo.agrupada.BROCHE": "Broche",
  "joyas.tipo.agrupada.CIERRE": "Verschlüsse",
  "joyas.tipo.agrupada.COLGANTE": "Hanger",
  "joyas.tipo.agrupada.COLLAR": "Ketting",
  "joyas.tipo.agrupada.CONJUNTO": "set",
  "joyas.tipo.agrupada.GEMELOS": "Tweelingen",
  "joyas.tipo.agrupada.PENDIENTES": "hellingen",
  "joyas.tipo.agrupada.PIERCING": "Piercing",
  "joyas.tipo.agrupada.PULSERA": "armband",
  "joyas.tipo.agrupada.TOBILLERA": "enkelbandje",
  "joyas.tipo.ANILLO": "Ring",
  "joyas.tipo.BROCHE": "Broche",
  "joyas.tipo.COLGANTE": "Hanger",
  "joyas.tipo.COLLAR": "Ketting",
  "joyas.tipo.CONJUNTO": "set",
  "joyas.tipo.GEMELOS": "Tweelingen",
  "joyas.tipo.PENDIENTES": "hellingen",
  "joyas.tipo.PIERCING": "Piercing",
  "joyas.tipo.PULSERA": "armband",
  "joyas.tipo.sub.1-1": "LISA",
  "joyas.tipo.sub.1-2": "MET STENEN",
  "joyas.tipo.sub.1-3": "MET DIAMANTEN",
  "joyas.tipo.sub.1-4": "LISA ALLIANCE",
  "joyas.tipo.sub.1-5": "LAPIDADA ALLIANTIE",
  "joyas.tipo.sub.1-6": "ALLIANTIE MET STEEN",
  "joyas.tipo.sub.1-7": "STEMPEL",
  "joyas.tipo.sub.1-8": "EMAILLE PRINT",
  "joyas.tipo.sub.1-9": "DIAMOND ALLIANCE",
  "joyas.tipo.sub.1-10": "Afdrukken",
  "joyas.tipo.sub.1-11": "glazuur",
  "joyas.tipo.sub.1-12": "DROOGTE",
  "joyas.tipo.sub.1-13": "PIDRA CRISTAL",
  "joyas.tipo.sub.1-14": "SWAROVSKI",
  "joyas.tipo.sub.2-1": "Glad",
  "joyas.tipo.sub.2-2": "MET STENEN",
  "joyas.tipo.sub.2-3": "SCAPULAIR",
  "joyas.tipo.sub.2-4": "GLAD KRUIS",
  "joyas.tipo.sub.2-5": "MAAGD MEISJE",
  "joyas.tipo.sub.2-6": "PERKAMENT / PLAAT",
  "joyas.tipo.sub.2-7": "Kleine Engels",
  "joyas.tipo.sub.2-8": "KRUIS MET STENEN",
  "joyas.tipo.sub.2-9": "KRUIS MET CHRISTUS",
  "joyas.tipo.sub.2-10": "FETISCH",
  "joyas.tipo.sub.2-11": "KRUIS MET DIAMANTEN",
  "joyas.tipo.sub.2-12": "MET DIAMANTEN",
  "joyas.tipo.sub.2-13": "FETISH MET STENEN",
  "joyas.tipo.sub.2-14": "ELASTISCH",
  "joyas.tipo.sub.2-15": "Maagd",
  "joyas.tipo.sub.2-16": "CHRISTUS",
  "joyas.tipo.sub.2-17": "CRUZ CARAVACA",
  "joyas.tipo.sub.2-18": "glazuur",
  "joyas.tipo.sub.2-19": "Gemeenschap",
  "joyas.tipo.sub.2-20": "DROOGTE",
  "joyas.tipo.sub.2-21": "CRUZ CALADA",
  "joyas.tipo.sub.3-1": "CHATON",
  "joyas.tipo.sub.3-2": "Zirkonia",
  "joyas.tipo.sub.3-3": "KLEUR STEEN",
  "joyas.tipo.sub.3-4": "glazuur",
  "joyas.tipo.sub.3-5": "ELEKTROFORMING",
  "joyas.tipo.sub.3-6": "PARELS",
  "joyas.tipo.sub.3-7": "JIJ EN IK",
  "joyas.tipo.sub.3-8": "Ring",
  "joyas.tipo.sub.3-9": "BAL",
  "joyas.tipo.sub.3-10": "Diversen",
  "joyas.tipo.sub.3-11": "Keramiek",
  "joyas.tipo.sub.3-12": "LANG MET STENEN",
  "joyas.tipo.sub.3-13": "Ring",
  "joyas.tipo.sub.3-14": "glazuur",
  "joyas.tipo.sub.3-15": "MET DIAMANTEN",
  "joyas.tipo.sub.3-16": "ELASTISCH",
  "joyas.tipo.sub.3-17": "STEEN HOEPEL",
  "joyas.tipo.sub.3-18": "Afdrukken",
  "joyas.tipo.sub.3-19": "DROOGTE",
  "joyas.tipo.sub.4-1": "LISA",
  "joyas.tipo.sub.4-2": "GEGRAVEERD",
  "joyas.tipo.sub.4-3": "Krullend",
  "joyas.tipo.sub.4-4": "KRULLEND MET STENEN",
  "joyas.tipo.sub.4-5": "GLAD MET STENEN",
  "joyas.tipo.sub.4-6": "GLAD MET DIAMANTEN",
  "joyas.tipo.sub.4-7": "glazuur",
  "joyas.tipo.sub.4-8": "MET HUID",
  "joyas.tipo.sub.5-1": "1e POSITIE MET PLAAT",
  "joyas.tipo.sub.5-2": "fijn",
  "joyas.tipo.sub.5-3": "Bruto",
  "joyas.tipo.sub.5-4": "DIKKE STEVIG MET BLAD",
  "joyas.tipo.sub.5-5": "DIKKE STEVIG",
  "joyas.tipo.sub.5-6": "DIKKE HOL MET KRALEN",
  "joyas.tipo.sub.5-7": "DIKKE HOL",
  "joyas.tipo.sub.5-8": "DIK MET STENEN",
  "joyas.tipo.sub.5-9": "RIVIERE",
  "joyas.tipo.sub.5-10": "DIKKE HOL MET BLAD",
  "joyas.tipo.sub.5-11": "FIJNE HOL MET PLAAT",
  "joyas.tipo.sub.5-12": "MET STEEN",
  "joyas.tipo.sub.5-13": "BALLEN",
  "joyas.tipo.sub.5-14": "BAL MET STEEN",
  "joyas.tipo.sub.5-15": "1e POSITIE",
  "joyas.tipo.sub.5-16": "FIJN HOLLE",
  "joyas.tipo.sub.5-17": "FIJN EFFEN MET BLAD",
  "joyas.tipo.sub.5-18": "ELASTISCH",
  "joyas.tipo.sub.5-19": "MET KRALEN",
  "joyas.tipo.sub.5-20": "LEDEREN BAND",
  "joyas.tipo.sub.5-21": "MET DIAMANTEN",
  "joyas.tipo.sub.5-22": "DIAMANT LEREN BAND",
  "joyas.tipo.sub.5-23": "MET PARELS",
  "joyas.tipo.sub.5-24": "RUBBEREN BAND",
  "joyas.tipo.sub.6-1": "FIJN HOLLE",
  "joyas.tipo.sub.6-2": "DIKKE HOL",
  "joyas.tipo.sub.6-3": "FIJN VAST",
  "joyas.tipo.sub.6-4": "DIKKE STEVIG",
  "joyas.tipo.sub.6-5": "RUBBER MET SLUITING",
  "joyas.tipo.sub.6-6": "LEER MET ZILVER",
  "joyas.tipo.sub.6-7": "LEER MET GOUDEN SLUITING",
  "joyas.tipo.sub.6-8": "HOLLE LAPIDADO",
  "joyas.tipo.sub.6-9": "NYLON SLUITINGSPLAT",
  "joyas.tipo.sub.7-1": "HOLLE LAPIDADO",
  "joyas.tipo.sub.7-2": "FIJN MET STEEN",
  "joyas.tipo.sub.7-3": "FINA MET HANGER",
  "joyas.tipo.sub.7-4": "Ringen",
  "joyas.tipo.sub.7-5": "FIJNE STEEN HANGER",
  "joyas.tipo.sub.7-6": "DIK MET HANGER",
  "joyas.tipo.sub.7-7": "fijn",
  "joyas.tipo.sub.7-8": "BALLEN",
  "joyas.tipo.sub.7-9": "FINA MET EMAILLE HANGER",
  "joyas.tipo.sub.7-10": "DIK MET STENEN",
  "joyas.tipo.sub.7-11": "HOLLE DIK",
  "joyas.tipo.sub.7-12": "FIJN HOLLE",
  "joyas.tipo.sub.7-13": "RUBBER MET HANGER",
  "joyas.tipo.sub.7-14": "FINA DIAMOND HANGER",
  "joyas.tipo.sub.7-15": "ELASTISCH",
  "joyas.tipo.sub.7-16": "DIK MET DIAMANTEN",
  "joyas.tipo.sub.7-17": "LEDEREN KOORD",
  "joyas.tipo.sub.7-18": "KOORD MET HANGER",
  "joyas.tipo.sub.7-19": "MET KRALEN",
  "joyas.tipo.sub.8-1": "1e POSITIE",
  "joyas.tipo.sub.8-2": "Binden",
  "joyas.tipo.sub.9-1": "FIJN GLAD",
  "joyas.tipo.sub.9-2": "DIK GLAD",
  "joyas.tipo.sub.9-3": "FIJN MET STENEN",
  "joyas.tipo.sub.9-4": "DIK MET STENEN",
  "joyas.tipo.sub.9-5": "FIJN MET DIAMANTEN",
  "joyas.tipo.sub.9-6": "DIK MET DIAMANTEN",
  "joyas.tipo.sub.9-7": "BALLEN",
  "joyas.tipo.sub.9-8": "RIVIERE",
  "joyas.tipo.sub.10-1": "Glad",
  "joyas.tipo.sub.10-2": "MET STENEN",
  "joyas.tipo.sub.10-3": "PARELS",
  "joyas.tipo.sub.11-1": "Glad",
  "joyas.tipo.sub.11-2": "MET STENEN",
  "joyas.tipo.sub.12-1": "MET DIAMANTEN",
  "joyas.tipo.sub.12-2": "Glad",
  "joyas.tipo.sub.13-1": "Glad",
  "joyas.tipo.sub.13-2": "MET STENEN",
  "joyas.tipo.sub.13-3": "MET DIAMANTEN",
  "joyas.tipo.sub.14-1": "Druk",
  "joyas.tipo.sub.14-2": "draad",
  "joyas.tipo.sub.15-1": "MET KRALEN",
  "joyas.tipo.sub.17-1": "BOUTEN PAAR",
  "joyas.tipo.sub.25-1": "EENVOUDIGE KLIK",
  "joyas.tipo.sub.99": "Ongedefinieerd",
  "joyas.tipo.sub.900-1": "9K GEEL",
  "joyas.tipo.sub.900-2": "9K WIT",
  "joyas.tipo.sub.900-3": "9K BICOLOR",
  "joyas.tipo.sub.undefined": "",
  "joyas.tipo.TOBILLERA": "enkelbandje",
  "kilataje.ORO9K": "9K GOUD",
  "kilataje.ORO18K": "18K GOUD",
  "longpatillas.140": "140 mm",
  "longpatillas.142": "142 mm",
  "longpatillas.145": "145 mm",
  "material.9K": "375 ml 9K goud",
  "material.18K": "18K GOUD",
  "material.24K": "FIJN GOUD",
  "material.?": "?",
  "material.AC": "Staal",
  "material.AC-EB": "STAAL-EBANE",
  "material.ACAC": "STAAL EN STAAL",
  "material.ACBI": "STAAL-BICOLOR",
  "material.ACBR": "HELDER STAAL",
  "material.ACCA": "STAAL EN RUBBER",
  "material.ACCC": "STAAL ZIRCONIA",
  "material.ACCE": "KERAMISCH STAAL",
  "material.ACCH": "VERPLATEERD STAAL",
  "material.ACDI": "STALEN DIAMANTEN",
  "material.ACE": "316L roestvrij staal",
  "material.ACERO": "316l roestvrij staal",
  "material.ACETA": "Acetaat",
  "material.ACFC": "KOOLSTOFVEZEL",
  "material.ACHB": "BICOLOUR GEPLATEERD STAAL",
  "material.ACHO": "VERGULD STAAL",
  "material.ACMA": "STAAL EN HOUT",
  "material.ACOR": "STAAL EN GOUD",
  "material.ACPI": "STAAL EN LEER",
  "material.ACPL": "STAAL EN KUNSTSTOF",
  "material.ACTI": "STAAL TITANIUM",
  "material.AGCI": "ZILVER-ZIRKONIA",
  "material.AGES": "GEËMAILLEERD ZILVER",
  "material.AGG": "Zilver",
  "material.AGPE": "ZILVER EN PARELS",
  "material.agrupado.ACERO": "Staal",
  "material.agrupado.ACETA": "ACETAAT",
  "material.agrupado.CAUCHO": "Rubber",
  "material.agrupado.CERAMICA": "Keramiek",
  "material.agrupado.CUERO": "Leer",
  "material.agrupado.GOMA": "Rubber",
  "material.agrupado.METAL": "METALL",
  "material.agrupado.ORO": "Goud",
  "material.agrupado.ORO9K": "GOUD9K",
  "material.agrupado.ORO18K": "GOUD18K",
  "material.agrupado.PLATA": "Zilver",
  "material.agrupado.TITANIO": "TITANIUM",
  "material.AGS": "SWAROVSKI ZILVER",
  "material.ALG": "Katoen",
  "material.ALUM": "Aluminium",
  "material.ALZ": "Zink legering",
  "material.ANTE": "Voordat",
  "material.BELC": "BELCRO",
  "material.BICO": "Tweekleurig",
  "material.BRO": "Bronzen",
  "material.C18K": "RUBBER + GOUD SLUITING",
  "material.CAAC": "RUBBER STAAL",
  "material.CAU": "Rubber",
  "material.CAUCHO": "Rubber",
  "material.CEBR": "KERAMISCH-BRILJANT",
  "material.CER": "Keramiek",
  "material.CERAMICA": "Keramiek",
  "material.CHA": "Chapada",
  "material.CHAP": "VERPLATEERD",
  "material.CHBI": "BICOLOUR GEPLATEERD",
  "material.CHOR": "VERGULD",
  "material.COCO": "KROKODIL HUID",
  "material.COCT": "COCO CUT",
  "material.CRBI": "BICOLOR CHROOM",
  "material.CRMO": "CHROOM",
  "material.CRO": "VERCHROOMD",
  "material.CU": "TOUW",
  "material.CUERO": "Leer",
  "material.FIB": "vezel",
  "material.GO": "Rubber",
  "material.GOCA": "RUBBER RUBBER",
  "material.GOM": "Rubber",
  "material.HNAU": "NAUTISCHE DRAAD",
  "material.LACH": "VERPLATEERDE MESSING",
  "material.LACR": "CHROOM MESSING",
  "material.LACT": "SNIJ Hagedis",
  "material.LAG": "HAGEDIS",
  "material.LAT": "Messing",
  "material.LCHB": "BICOLOUR GEPLATEERD MESSING",
  "material.LIQ": "Vloeistof",
  "material.METAL": "METALL",
  "material.MIL": "MINERALE MAGNIFIER",
  "material.MIN": "MINERAAL",
  "material.MINP": "PLAT MINERAAL",
  "material.MINT": "GETINT MINERAAL",
  "material.NEO": "NEOPREEN",
  "material.NOB": "NOBUCK",
  "material.NY": "NYLON",
  "material.NYBE": "NYLON BELCRO",
  "material.NYCA": "NYLON RUBBER",
  "material.NYCT": "NYLON GESNEDEN",
  "material.NYL": "NYLON",
  "material.NYPC": "NYLON-LEDER BOVENSTUK",
  "material.NYPI": "NYLON BASISLEER",
  "material.O18": "18K GOUD",
  "material.OASP": "18K GOUD ZONDER PRIJS",
  "material.OB5N": "WIT GOUD + 5N GOUD",
  "material.OBSP": "WIT GOUD ZONDER PRIJS",
  "material.OR18": "GOUD 18 KTES.",
  "material.OR24": "GOUD 24 KTES.",
  "material.ORO": "Goud",
  "material.OROB": "18K WIT GOUD",
  "material.P+O": "LEER + GOUDEN SLUITING",
  "material.P-CC": "LEER C / CUT (7.5X4.2)",
  "material.PABA": "PAVE BAL",
  "material.PALD": "Palladium",
  "material.PAST": "Pasta",
  "material.PC": "KUNSTSTOF + GLASVEZEL",
  "material.PEL": "PLEX MAGNIFIER",
  "material.PI": "Huid",
  "material.PI-C": "LEER C / CUT (7.5X3.8)",
  "material.PICA": "RUBBER LEER",
  "material.PICH": "LAKLEER",
  "material.PICT": "LEER GESNEDEN",
  "material.PIEL": "Leer",
  "material.PIEL-PU": "LEDER-POLYURETHAAN",
  "material.PIGR": "GEGRAVEERD LEER",
  "material.PILI": "GLADDE HUID",
  "material.PINA": "NAYLON LEER",
  "material.PINY": "NYLON LEER",
  "material.PIPL": "PLASTIFIED HUID",
  "material.PIRA": "GESTREEPT LEDER",
  "material.PISI": "SYNTHETISCHE HUID",
  "material.PISIN": "SYNTHETISCHE HUID",
  "material.PL": "PLEX",
  "material.PLA": "Plastic",
  "material.PLAA": "KUNSTSTOF RING",
  "material.PLAL": "KUNSTSTOF-MAGNIFIER",
  "material.PLAS": "Plastic",
  "material.PLAT": "Zilver 925 ml",
  "material.PLATA": "Zilver",
  "material.PLEX": "KUNSTSTOF PLEX",
  "material.PLNY": "KUNSTSTOF NYLON",
  "material.PLPI": "LEREN ONDERSTEL KUNSTSTOF",
  "material.PLPL": "PLPL",
  "material.PLXL": "PLAST + MAGNIFIER",
  "material.POLICARBONATO": "POLYCARBONAAT",
  "material.PU": "POLYURETHAAN",
  "material.PUPI": "LEDER BASIS PURPURIJN",
  "material.PVC": "PVC",
  "material.RAYA": "GESTREEPT LEDER",
  "material.RH": "Rhodium",
  "material.RU": "RUTHENIUM",
  "material.SERP": "Slang",
  "material.SPBP": "SYNTHETISCHE LEDER BASIS",
  "material.STBP": "SYNTHETISCHE LEDER BASIS",
  "material.SWA": "SWAROVSKI",
  "material.TELA": "Kleding",
  "material.TEPI": "LEREN BASISSTOF",
  "material.TIBI": "BICOLOUR TITANIUM",
  "material.TIBU": "HAAIEN HUID",
  "material.TIT": "TITANIUM",
  "material.TITANIO": "Titanium",
  "material.TORT": "SCHILDPAD HUID",
  "material.TUNG": "WOLFRAAM",
  "material.undefined": "",
  "material.Z+L": "SAPPHIRE + MAGNIFIER",
  "material.Z-G": "ZAF + VERGROOT + GRAVERING",
  "material.ZAF": "Saffier",
  "material.ZAFP": "GESCHILDERDE SAFFIER",
  "movimiento.funcion.1": "EENVOUDIG (CAL)",
  "movimiento.funcion.4": "CHRONO (GEEN CAL)",
  "movimiento.funcion.12": "RESERVE-START",
  "movimiento.funcion.101": "Digitaal",
  "movimiento.funcion.102": "Multifunctioneel",
  "movimiento.funcion.103": "KWARTS",
  "movimiento.funcion.104": "Chronograaf",
  "movimiento.funcion.105": "Digitaal",
  "movimiento.funcion.106": "Digitaal",
  "movimiento.funcion.107": "ANALOOG ALARM",
  "movimiento.funcion.108": "Multifunctioneel",
  "movimiento.funcion.109": "DUBBELE TIJD",
  "movimiento.funcion.110": "Automatisch",
  "movimiento.funcion.111": "Tourbillon",
  "movimiento.funcion.112": "GMT",
  "movimiento.funcion.113": "RESERVE-START",
  "movimiento.funcion.agrupada.ANALO": "Analoog",
  "movimiento.funcion.agrupada.AUTO": "Automatischs",
  "movimiento.funcion.agrupada.CRONO": "Chronograaf",
  "movimiento.funcion.agrupada.DIGI": "Digitaal",
  "movimiento.funcion.agrupada.HYBRID": "Hybriden",
  "movimiento.funcion.agrupada.MULTI": "Multifunctioneel",
  "movimiento.funcion.agrupada.SMART": "smartwatch",
  "movimiento.funcion.ANA-AL": "ANALOOG ALARM",
  "movimiento.funcion.ANA-DI": "DIGITAAL ANALOOG",
  "movimiento.funcion.ANA-DIGI": "DIGITAAL ANALOOG",
  "movimiento.funcion.AUT-AL": "AUTOMATISCH ALARM",
  "movimiento.funcion.AUTO": "Automatischs",
  "movimiento.funcion.CRO-SUI": "ZWITSERSE CHRONOGRAAF",
  "movimiento.funcion.CRO.SOL": "ZONNE CHRONO",
  "movimiento.funcion.CRONO": "Chronograaf",
  "movimiento.funcion.CRONO-AL": "CHRONO-ALARM",
  "movimiento.funcion.DI-MULTI": "DIGITALE MULTIFUCIE",
  "movimiento.funcion.DIGI": "Digitaal",
  "movimiento.funcion.DUAL-TIM": "DUBBELE TIJD",
  "movimiento.funcion.HYBRID": "Hybriden",
  "movimiento.funcion.MULTI": "Multifunctioneel",
  "movimiento.funcion.QUTZ-ANA": "KWARTS / ANALOOG",
  "movimiento.funcion.SI": "Gemakkelijk",
  "movimiento.funcion.SIM": "Gemakkelijk",
  "movimiento.funcion.SIM-SUI": "EENVOUDIG ZWITSERS",
  "movimiento.funcion.SMART": "SLIM HORLOGE",
  "movimiento.tipo.01": "Digitaal",
  "movimiento.tipo.1": "EENVOUDIG (CAL)",
  "movimiento.tipo.02": "ANALOGICO DIGITALE",
  "movimiento.tipo.03": "KWARTS / ANALOOG",
  "movimiento.tipo.04": "Automatisch",
  "movimiento.tipo.05": "HIBRID",
  "movimiento.tipo.06": "SLIM HORLOGE",
  "movimiento.tipo.07": "Zonne",
  "movimiento.tipo.7": "MULTIF (CAL)",
  "movimiento.tipo.12": "RESERVE-START",
  "movimiento.tipo.ANA-AL": "ANALOOG ALARM",
  "movimiento.tipo.ANA-DI": "DIGITAAL ANALOOG",
  "movimiento.tipo.ANA-DIGI": "DIGITAAL ANALOOG",
  "movimiento.tipo.AUT-AL": "AUTOMATISCH ALARM",
  "movimiento.tipo.AUTO": "Automatisch",
  "movimiento.tipo.AUTO-CRO": "AUTOMATISCHE CHRONOGRAAF",
  "movimiento.tipo.AUTOM": "Automatisch",
  "movimiento.tipo.C": "TOUW",
  "movimiento.tipo.CRO-SUI": "ZWITSERSE CHRONOGRAAF",
  "movimiento.tipo.CRONO": "Chronograaf",
  "movimiento.tipo.CUER-MEC": "TOUW / MECHANISCH",
  "movimiento.tipo.CUER.": "TOUW",
  "movimiento.tipo.D": "Digitaal",
  "movimiento.tipo.DI": "Digitaal",
  "movimiento.tipo.DIGI": "Digitaal",
  "movimiento.tipo.DUAL-TIM": "DUBBELE TIJD",
  "movimiento.tipo.F": "Zonne",
  "movimiento.tipo.HIBRID": "HIBRID",
  "movimiento.tipo.ME.CRO.": "MECA.CRONO",
  "movimiento.tipo.ME.SIM.": "EENVOUDIG MECA",
  "movimiento.tipo.MULTI": "Multifunctioneel",
  "movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
  "movimiento.tipo.PROF.": "DIEPTEMETER",
  "movimiento.tipo.QUTZ-ANA": "KWARTS / ANALOOG",
  "movimiento.tipo.SIM": "Gemakkelijk",
  "movimiento.tipo.SIM-SUI": "EENVOUDIG ZWITSERS",
  "movimiento.tipo.SMART": "SLIM HORLOGE",
  "movimiento.tipo.TOURBILLON": "TOERBILLON",
  "numeraciones.1A+AP": "1AR.AP + AR.PI",
  "numeraciones.1A+CC": "1AR.AP + CRIRC",
  "numeraciones.1A+IA": "1AR.AP + IN.AP",
  "numeraciones.1A+IP": "1AR.AP + IN.PI",
  "numeraciones.1A.AP": "1AR.AP",
  "numeraciones.1A.PI": "1AR.PI",
  "numeraciones.1AA": "1AR.AP",
  "numeraciones.1AP+I": "1AR.PI + IN.AP",
  "numeraciones.1AP+P": "1AR.PI + IN.PI",
  "numeraciones.1BR+I": "1BRILL + IN.AP",
  "numeraciones.1CC": "1CIRC",
  "numeraciones.1CC+I": "1CIRCO + IN.AP",
  "numeraciones.1CC+IA": "1CIRC + IN.AP",
  "numeraciones.1CIR+I": "1CIRCO + IN.AP.",
  "numeraciones.1D+IN": "1DIAM + IND APLI",
  "numeraciones.1I.AP": "1IN.AP",
  "numeraciones.1IA": "1IN.AP",
  "numeraciones.1IP": "1IN.PI",
  "numeraciones.1R+IA": "1RO.AP + IN.AP",
  "numeraciones.1R+IP": "1RO.AP + IN.PI",
  "numeraciones.1R+RP": "1RO.AP + RO.PI",
  "numeraciones.1R.AP": "1RO.AP",
  "numeraciones.1R.P": "1RO.PI",
  "numeraciones.1R.PI": "1RO.PI",
  "numeraciones.1RA": "1RO.AP.",
  "numeraciones.1RA+P": "1R.APL + RO.PIN.",
  "numeraciones.1RP+I": "1RO.PI + IN.AP",
  "numeraciones.1RP+P": "1RO.PI + IN.PI",
  "numeraciones.2A+AP": "2AR.AP + AR.PI",
  "numeraciones.2A+CC": "2AR.AP + CIRC",
  "numeraciones.2A+IA": "2AR.AP + IN.AP",
  "numeraciones.2A+IP": "2AR.AP + IN.PI",
  "numeraciones.2A.AP": "2AR.AP",
  "numeraciones.2A.PI": "2AR.PI",
  "numeraciones.2AA+2RA": "2AR.AP + 2RO.AP",
  "numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+I": "2AR.PI + IN.AP",
  "numeraciones.2AP+P": "2AR.PI + IN.PI",
  "numeraciones.2CC": "2CIRC.",
  "numeraciones.2CC+I": "2CIRC + IN.AP",
  "numeraciones.2CC+IA": "2CIRC + IN.AP",
  "numeraciones.2I+RP": "2IN.AP + 10RO.PI",
  "numeraciones.2I.AP": "2IN.AP",
  "numeraciones.2IA": "2IN.AP",
  "numeraciones.2IA+P": "2IN.AP + IN.PI.",
  "numeraciones.2IP": "2IN.PI",
  "numeraciones.2R+CC": "2RO.AP + CIRC",
  "numeraciones.2R+IA": "2RO.AP + IN.AP",
  "numeraciones.2R+IP": "2RO.AP + IN.PI",
  "numeraciones.2R+RP": "2RO.AP + RO.PI",
  "numeraciones.2R.AP": "2RO.AP",
  "numeraciones.2R.P": "2RO.PI.",
  "numeraciones.2R.PI": "2RO.PI",
  "numeraciones.2RA": "2ROM.APL.",
  "numeraciones.2RP+C": "2RO.PI + CIRC",
  "numeraciones.2RP+I": "2RO.PI + IN.AP",
  "numeraciones.2RP+P": "2RO.PI + IN.PI",
  "numeraciones.3A+AP": "3AR.AP + AR.PI",
  "numeraciones.3A+IA": "3AR.AP + IN.AP",
  "numeraciones.3A+IP": "3AR.AP + IN.PI",
  "numeraciones.3A.AP": "3AR.AP",
  "numeraciones.3A.PI": "3AR.PI",
  "numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
  "numeraciones.3AP+I": "3AR.PI + IN.AP.",
  "numeraciones.3AP+P": "3AR.PI + IN.PI",
  "numeraciones.3CC": "3CIRC.",
  "numeraciones.3CC+IA": "3CIRC + IN.AP",
  "numeraciones.3I+RP": "3IN.AP + RO.PI",
  "numeraciones.3I.AP": "3IN.AP",
  "numeraciones.3IA": "3IN.AP",
  "numeraciones.3IP": "3IN.PI",
  "numeraciones.3R+CC": "3ROM + ZIRCONS",
  "numeraciones.3R+IA": "3RO.AP + IN.AP",
  "numeraciones.3R+IP": "3RO.AP + IN.PI",
  "numeraciones.3R+IR": "3/4 ROMEINEN + IND.APL",
  "numeraciones.3R+RP": "3RO.AP + RO.PI",
  "numeraciones.3R.AP": "3RO.AP",
  "numeraciones.3R.P": "3RO.PI.",
  "numeraciones.3R.PI": "3RO.PI",
  "numeraciones.3RA+CC": "3RA.AP + CC",
  "numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
  "numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
  "numeraciones.3RP+I": "3RO.PI + IN.AP",
  "numeraciones.3RP+P": "3RO.PI + IN.PI",
  "numeraciones.4A+4A": "4AR.PI + 4A.PI",
  "numeraciones.4A+4AP": "4AR.AP + 4AR.P",
  "numeraciones.4A+4R": "4AR + IA / 4R + IA.",
  "numeraciones.4A+AP": "4AR.AP + AR.PI",
  "numeraciones.4A+CC": "4ARA.AP + CIRC",
  "numeraciones.4A+IA": "4AR.AP + IN.AP",
  "numeraciones.4A+IP": "4AR.AP + IN.PI",
  "numeraciones.4A+P": "4ARP + IND.PIN.",
  "numeraciones.4A.AP": "4AR.AP",
  "numeraciones.4A.PI": "4AR.PI",
  "numeraciones.4AA+6": "4AR.A + 6AR.P",
  "numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
  "numeraciones.4AP+I": "4AR.PI + IN.AP",
  "numeraciones.4AP+P": "4AR.PI + IN.PI",
  "numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
  "numeraciones.4CC": "4CIRC.",
  "numeraciones.4DIA": "4 DIAMANTEN",
  "numeraciones.4I+CC": "4IN.AP + CIRC",
  "numeraciones.4IA": "4IN.AP",
  "numeraciones.4IA+P": "4IN.AP + IN.PI",
  "numeraciones.4IN.A": "4 TOEGEPASTE INDICES",
  "numeraciones.4INDS": "4 INDICES",
  "numeraciones.4IP": "4IN.PI",
  "numeraciones.4R+CC": "4 ROM. + CIRC.",
  "numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
  "numeraciones.4R+IA": "4RO.AP + IN.AP",
  "numeraciones.4R+IP": "4RO.AP + IN.PI",
  "numeraciones.4R+RP": "4RO.AP + RO.PI",
  "numeraciones.4R.AP": "4RO.AP",
  "numeraciones.4R.P": "4RO.PI.",
  "numeraciones.4R.PI": "4RO.PI",
  "numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
  "numeraciones.4RA+P": "4RO.AP + 8RO.PI",
  "numeraciones.4RE+E": "4RO.EST + IN.EST.",
  "numeraciones.4ROM": "4 ROMEINEN",
  "numeraciones.4RP+I": "4RO.PI + IN.AP",
  "numeraciones.4RP+P": "4RO.PI + IN.PI",
  "numeraciones.5A+5CC": "5AR.AP + 5CIRC",
  "numeraciones.5A+AP": "5AR.AP + AR.PI",
  "numeraciones.5A+IA": "5AR.AP + IN.AP",
  "numeraciones.5A+IP": "5AR.AP + IN.PI",
  "numeraciones.5A.AP": "5AR.AP",
  "numeraciones.5A.PI": "5AR.PI",
  "numeraciones.5AP+I": "5AR.PI + IN.AP",
  "numeraciones.5AP+P": "5AR.PI + IN.PI",
  "numeraciones.5CC": "5CIRC.",
  "numeraciones.5D+IA": "5 DIAM + IND. APLI",
  "numeraciones.5DIAM": "5 DIAMANTEN",
  "numeraciones.5IA": "5IN.AP",
  "numeraciones.5IP": "5IN.PI",
  "numeraciones.5R+IA": "5RO.AP + IN.AP",
  "numeraciones.5R+IP": "5RO.AP + IN.PI",
  "numeraciones.5R+RP": "5RO.AP + RO.PI",
  "numeraciones.5R.AP": "5RO.AP",
  "numeraciones.5R.PI": "5RO.PI",
  "numeraciones.5RP+I": "5RO.PI + IN.AP",
  "numeraciones.5RP+P": "5RO.PI + IN.PI",
  "numeraciones.6A+AP": "6AR.AP + AR.PI",
  "numeraciones.6A+CC": "6AR.AP + CIRC.",
  "numeraciones.6A+IA": "6AR.AP + IN.AP",
  "numeraciones.6A+IP": "6AR.AP + IN.PI",
  "numeraciones.6A.AP": "6AR.AP",
  "numeraciones.6A.PI": "6AR.PI",
  "numeraciones.6AP+I": "6AR.PI + IN.AP",
  "numeraciones.6AP+P": "6AR.PI + IN.PI.",
  "numeraciones.6CC.": "6CIRC.",
  "numeraciones.6IA": "6IN.AP",
  "numeraciones.6IP": "6IN.PI",
  "numeraciones.6R+IA": "6RO.AP + IN.AP",
  "numeraciones.6R+IP": "6RO.AP + IN.PI",
  "numeraciones.6R+RP": "6RO.AP + RO.PI",
  "numeraciones.6R.AP": "6RO.AP",
  "numeraciones.6R.P": "6RO.PIN.",
  "numeraciones.6RP+I": "6RO.PI + IN.AP",
  "numeraciones.6RP+P": "6RO.PI + IN.PI",
  "numeraciones.7A+IA": "7AR.AP + IN.AP",
  "numeraciones.7AR.P": "7AR.P",
  "numeraciones.7DIA": "7 DIAMANTEN",
  "numeraciones.8A+IA": "8AR.AP + IN.AP",
  "numeraciones.8AP": "8ARA.PIN.",
  "numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
  "numeraciones.8R+IA": "8RO.AP + IN.AP",
  "numeraciones.8RP+P": "8RO.PI + IN.PI.",
  "numeraciones.9ARAB": "9 ARABEN",
  "numeraciones.10ARA": "10 ARABEN",
  "numeraciones.A+R.P": "ARA + ROM.PIN.",
  "numeraciones.AA+AP": "ARA PINT + ARA APLIC",
  "numeraciones.AP+AA": "ARA PINT + ARA APLIC",
  "numeraciones.AP+IA": "ARA.PIN + IND.A",
  "numeraciones.AP+IP": "AR.PI + IN.PI",
  "numeraciones.AR+DI": "RA. + 10 DIAMANTEN",
  "numeraciones.AR.AP": "TOEGEPASTE ARABS",
  "numeraciones.AR.PI": "GESCHILDERDE ARABEN",
  "numeraciones.AR4/4": "ARABES 4/4 + INDICES",
  "numeraciones.ARA.A": "TOEGEPASTE ARABS",
  "numeraciones.ARA.E": "GEDRUKTE ARABEN",
  "numeraciones.ARA.P": "GESCHILDERDE ARABEN",
  "numeraciones.ARAB": "ARABES",
  "numeraciones.ARABE": "ARABES 12/12",
  "numeraciones.BAND": "vlaggen",
  "numeraciones.BOLAS": "2N18 BALLEN",
  "numeraciones.CIRC": "Zirkonia",
  "numeraciones.CR": "SWAROVSKI-KRISTAL",
  "numeraciones.DIAM": "12 DIAMANTEN",
  "numeraciones.DIAMG": "GROTE DIAMANTEN",
  "numeraciones.DIAN": "DIGITAAL ANALOOG",
  "numeraciones.DIAR": "DIGITALE ARABEN",
  "numeraciones.DIG": "Digitaal",
  "numeraciones.DIIN": "DIGITALE INDICES",
  "numeraciones.FANT": "Fantasie",
  "numeraciones.FL": "BLOEMEN",
  "numeraciones.IN+CC": "IND + ZIRCONS",
  "numeraciones.IN.AP": "TOEGEPASTE INDICES",
  "numeraciones.IN.PI": "GESCHILDERDE INDEXEN",
  "numeraciones.INAR": "12INDIC + 12ARAB",
  "numeraciones.IND.A": "TOEGEPASTE INDICES",
  "numeraciones.IND.E": "IND.EST.",
  "numeraciones.IND.P": "GESCHILDERDE INDUSTRIEËN",
  "numeraciones.INDIC": "INDICES",
  "numeraciones.INFAN": "FANTASY-INDICES",
  "numeraciones.INPIN": "IND.PIN.",
  "numeraciones.INRO": "12 INDICES + 12 ROMEINS",
  "numeraciones.IP+AP": "12IN.PI + 12AR.PI",
  "numeraciones.NEO": "DRAAGT NIET",
  "numeraciones.RA+RP": "4ROM APLI + 4ROM-PIN",
  "numeraciones.RO+IN": "ROMEINEN 12 + 10 INDEX",
  "numeraciones.RO.AP": "TOEGEPASTE ROMEINEN",
  "numeraciones.RO.PI": "GESCHILDERDE ROMEINEN",
  "numeraciones.RO2/2": "ROMEINEN 2/2 + INDEX",
  "numeraciones.RO4/4": "ROMEINEN 4/4 + INDICES",
  "numeraciones.ROES": "ROM.STAMPINGS",
  "numeraciones.ROM": "ROMEINEN",
  "numeraciones.ROM.A": "ROMEINEN TOEPASSINGEN",
  "numeraciones.ROM.E": "ROMEINEN GESTEMPELD",
  "numeraciones.ROM.P": "GESCHILDERDE ROMEINEN",
  "numeraciones.ROMAN": "12/12 ROMEINEN",
  "numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
  "numeraciones.RP+IP": "12RO.PI + 12IN.PI",
  "origen.1": "Zwitserland",
  "origen.2": "Japan",
  "piedras.tipo.agrupada.ABA": "KRALEN VERSCHILLENDE VORMEN",
  "piedras.tipo.agrupada.ACBA": "STALEN KOGEL",
  "piedras.tipo.agrupada.AGA": "AGAAT",
  "piedras.tipo.agrupada.AGBA": "ZILVEREN BAL",
  "piedras.tipo.agrupada.AMA": "Amethist",
  "piedras.tipo.agrupada.AMAN": "AMANZONIET",
  "piedras.tipo.agrupada.ARCI": "ZILVEREN RING MET ZIRCONS",
  "piedras.tipo.agrupada.ARGO": "Ring",
  "piedras.tipo.agrupada.AVEN": "AVONTUURLIJK",
  "piedras.tipo.agrupada.BAEN": "STUURSET ZIRCONIA",
  "piedras.tipo.agrupada.BAPA": "PAVE BAR",
  "piedras.tipo.agrupada.BGSP": "ZAKKEN ZONDER PRIJS",
  "piedras.tipo.agrupada.BOL": "ZILVEREN BAL FACET",
  "piedras.tipo.agrupada.BRI": "SPARKLY",
  "piedras.tipo.agrupada.BS": "BS",
  "piedras.tipo.agrupada.BUTT": "GECULTIVEERDE PARELKNOOP",
  "piedras.tipo.agrupada.CALC": "CHALCEDOON",
  "piedras.tipo.agrupada.CER": "Keramiek",
  "piedras.tipo.agrupada.CIR": "GEEL ZIRCONIA (CZ-YE1)",
  "piedras.tipo.agrupada.CIRC": "ZIRCONIA INSTELLEN",
  "piedras.tipo.agrupada.COR": "KORNALIJN",
  "piedras.tipo.agrupada.CRBA": "KRISTALLEN BOL",
  "piedras.tipo.agrupada.CRI": "Kristal",
  "piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
  "piedras.tipo.agrupada.CUAR": "KWARTS",
  "piedras.tipo.agrupada.DIAM": "Diamant",
  "piedras.tipo.agrupada.DISP": "0",
  "piedras.tipo.agrupada.ESMA": "glazuur",
  "piedras.tipo.agrupada.ESP": "Spinel",
  "piedras.tipo.agrupada.FERI": "vakantie",
  "piedras.tipo.agrupada.G030": "FO1030",
  "piedras.tipo.agrupada.G031": "FO1031",
  "piedras.tipo.agrupada.G034": "BRILJANT",
  "piedras.tipo.agrupada.G036": "FO1036",
  "piedras.tipo.agrupada.G050": "FO1050",
  "piedras.tipo.agrupada.GI10": "STEEN 0,90",
  "piedras.tipo.agrupada.GI11": "38640",
  "piedras.tipo.agrupada.GI12": "MOD FO282",
  "piedras.tipo.agrupada.GI13": "SCHOKGOLF GEKRIMPELD BEZEL",
  "piedras.tipo.agrupada.GI14": "STENEN 1,6DIAM",
  "piedras.tipo.agrupada.GI15": "STEEN 0,80",
  "piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0.015K",
  "piedras.tipo.agrupada.GIL1": "STENEN 1,20 + 1,50",
  "piedras.tipo.agrupada.GIL2": "FO436",
  "piedras.tipo.agrupada.GIL3": "DIAMANT 1.10",
  "piedras.tipo.agrupada.GIL4": "STEENMODEL LO314",
  "piedras.tipo.agrupada.GIL5": "STENEN MODEL FO323",
  "piedras.tipo.agrupada.GIL6": "STENEN VAN 1,20",
  "piedras.tipo.agrupada.GIL7": "STENEN 1.40",
  "piedras.tipo.agrupada.GIL8": "STENEN 1.30",
  "piedras.tipo.agrupada.GIL9": "STEEN 1.00",
  "piedras.tipo.agrupada.GLAM": "GLAM",
  "piedras.tipo.agrupada.HEMA": "HEMATIET",
  "piedras.tipo.agrupada.JADE": "JADE",
  "piedras.tipo.agrupada.LABR": "LABRADORIET",
  "piedras.tipo.agrupada.LAPI": "LAPISLAZULI",
  "piedras.tipo.agrupada.LAVA": "LAVA BAL",
  "piedras.tipo.agrupada.MP": "MOEDER PAREL",
  "piedras.tipo.agrupada.NAC": "NACRE",
  "piedras.tipo.agrupada.OJGA": "KATTENOOG",
  "piedras.tipo.agrupada.ONI": "ONYX",
  "piedras.tipo.agrupada.OPPE": "PERUAANS OPAAL",
  "piedras.tipo.agrupada.OTIG": "TIGER'S OOG (GRANAAT)",
  "piedras.tipo.agrupada.OTUR": "TURKS OOG",
  "piedras.tipo.agrupada.PABA": "PAVE BAL",
  "piedras.tipo.agrupada.PEBA": "GECULTIVEERDE BAROKKE PAREL",
  "piedras.tipo.agrupada.PECU": "GEKWEEKTE PAREL",
  "piedras.tipo.agrupada.PERL": "NATUURLIJKE PAREL",
  "piedras.tipo.agrupada.PESY": "SYNTHETISCHE PAREL",
  "piedras.tipo.agrupada.PILU": "MAAN STEEN",
  "piedras.tipo.agrupada.QURS": "ROZE KWARTS",
  "piedras.tipo.agrupada.RES": "HARS BAL",
  "piedras.tipo.agrupada.RESI": "HARS",
  "piedras.tipo.agrupada.RORU": "RHODIUM RUTENIUM",
  "piedras.tipo.agrupada.RUB": "Robijn",
  "piedras.tipo.agrupada.SWA": "SWAROVSKI",
  "piedras.tipo.agrupada.SZA1": "STEEN 1.50",
  "piedras.tipo.agrupada.SZA2": "STEEN 1.20",
  "piedras.tipo.agrupada.TUCI": "ZIRCONIA BUIS",
  "piedras.tipo.agrupada.TUR": "TURKOOIS",
  "producto.coleccion": "Verzameling",
  "producto.tipo.accesorio": "accessoire",
  "producto.tipo.joya": "Juweel",
  "producto.tipo.reloj": "KLOK",
  "tipo.movimiento.agrupado.01": "Digitaal",
  "tipo.movimiento.agrupado.02": "DIGITAAL KWARTS",
  "tipo.movimiento.agrupado.03": "Handmatig",
  "tipo.movimiento.agrupado.04": "Automatisch",
  "tipo.movimiento.agrupado.05": "hybriden",
  "tipo.movimiento.agrupado.06": "SMARTWATCH",
  "tipo.movimiento.agrupado.07": "KWARTS",
  "tipolente.POLARIZED": "Gepolariseerd"
}
