{
  "reloj": {
    "h1": "Relojes",
    "meta": "¿Buscas un reloj único y elegante? Descubre nuestros relojes de las mejores marcas y encuentra el modelo perfecto para ti. {{numPagina}}",
    "title": "Relojes {{numPagina}} | Candino"
  },
  "reloj.colorCorrea.default": {
    "h1": "Relojes {{colorCorrea}}",
    "meta": "¿Tienes debilidad por los Relojes {{colorCorrea}}? Descubre los modelos más personales de las mejores marcas y consigue tu reloj {{colorCorrea}} perfecto. {{numPagina}}",
    "title": "Relojes {{colorCorrea}} {{numPagina}} | Candino"
  },
  "reloj.colorEsfera.default": {
    "h1": "Relojes {{colorEsfera}}",
    "meta": "¿Tienes debilidad por los Relojes {{colorEsfera}}? Descubre los modelos más personales de las mejores marcas y consigue tu reloj {{colorEsfera}} perfecto. {{numPagina}}",
    "title": "Relojes {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.default": {
    "h1": "Relojes {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "¿Buscas un reloj único y elegante? Descubre nuestros relojes de las mejores marcas y encuentra el modelo perfecto para ti. {{numPagina}}",
    "robots": "false",
    "title": "Relojes {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.estilo": {
    "h1": "Relojes {{estilo}}",
    "meta": "En nuestra tienda online podrás encontrar una amplia selección de Relojes {{estilo}} perfectos para ti. ¡Compra ya online tu Reloj {{estilo}} favorito! {{numPagina}}",
    "title": "Relojes {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.funcion": {
    "h1": "Relojes {{funcion}}",
    "meta": "¿Buscas un Reloj {{funcion}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.funcion.SMART": {
    "h1": "{{funcion}}",
    "meta": "Encuentra tu nuevo Smartwatch favorito. ¡Descubre todos nuestros modelos y regalate un capricho! Visita nuestro catálogo online",
    "title": "Smartwatch | Candino"
  },
  "reloj.gama": {
    "h1": "Relojes para {{gama}}",
    "meta": "¿Buscas un reloj que se adapte a tu estilo de vida? Consulta nuestro catálogo de relojes para {{gama}} y presume de modelo 'made in Suiza', de excelsa calidad.",
    "title": "Relojes para {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.colorCorrea": {
    "h1": "Relojes {{colorCorrea}} para {{gama}}",
    "meta": "Descubre los mejores modelos de Reloj {{colorCorrea}} para {{gama}}. Encuentra el reloj ideal para ti en nuestro catálogo online. ¡Visítanos ahora! {{numPagina}}",
    "title": "Relojes {{colorCorrea}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Relojes para {{gama}} con {{colorCorrea}}",
    "meta": "¿Buscas un reloj que se adapte a tu estilo? Consulta nuestro catálogo de relojes con correa {{colorCorrea}} para {{gama}} y presume de modelo fabricado en Suiza.",
    "title": "Relojes para {{gama}} con {{colorCorrea}} | Candino"
  },
  "reloj.gama.colorEsfera": {
    "h1": "Relojes {{gama}} Esfera {{colorEsfera}}",
    "meta": "Descubre los mejores modelos de Reloj para {{gama}} con esfera de color {{colorEsfera}}. Encuentra el reloj ideal para ti en nuestro catálogo online. ¡Visítanos ahora! {{numPagina}}",
    "title": "Relojes {{gama}} Esfera {{colorEsfera}} {{numPagina}} | Candino"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Relojes con Esfera color {{colorEsfera}} para {{gama}}",
    "meta": "¿Buscas un reloj que se adapte a tu estilo? Consulta nuestro catálogo de relojes con esfera {{colorEsfera}} para {{gama}} y presume de modelo fabricado en Suiza.",
    "title": "Relojes con Esfera color {{colorEsfera}} para {{gama}} | Candino"
  },
  "reloj.gama.default": {
    "h1": "Relojes {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} qwe",
    "meta": "¿Buscas un reloj único y elegante? Descubre nuestros relojes de las mejores marcas y encuentra el modelo perfecto para ti. {{numPagina}}",
    "title": "Relojes {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.gama.estilo": {
    "h1": "Relojes {{gama}} {{estilo}}",
    "meta": "Explora nuestra selección Relojes de {{gama}} {{estilo}} fabricados con los mejores materiales y encuentra el modelo perfecto para ti. {{numPagina}}",
    "title": "Relojes {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion": {
    "h1": "Relojes para {{gama}} {{funcion}}",
    "meta": "¿Buscas Relojes de {{gama}} {{funcion}}? Echa un ojo a nuestra amplia gama de relojes para {{gama}} y escoge el modelo perfecto para ti. {{numPagina}}",
    "title": "Relojes para {{gama}} {{funcion}} {{numPagina}} | Candino"
  },
  "reloj.gama.funcion.default": {
    "h1": "Relojes {{funcion}} para {{gama}}",
    "meta": "¿Buscas un reloj {{funcion}} adaptado a tu estilo de vida? Consulta nuestro catálogo de relojes {{funcion}} para {{gama}} y presume de modelo 'made in Suiza'.",
    "title": "Relojes {{funcion}} para {{gama}} | Candino Swiss Watch"
  },
  "reloj.gama.funcion.SMART": {
    "h1": "Smartwatch para {{gama}}",
    "meta": "¡Consigue ahora tu Smartwatch para {{gama}}! Descubre todos nuestros modelos y encuentra el tuyo. Ideales para cualquier ocasión.",
    "title": "Smartwatch para {{gama}} | Candino"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "Relojes {{gama}} con carcasa de {{materialCaja}}",
    "meta": "Consigue Relojes de {{gama}} de {{materialCaja}} de las mejores marcas y experimenta la comodidad en tu muñeca. ¡Visita nuestra web ahora! {{numPagina}}",
    "title": "Relojes {{gama}} con carcasa de {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Relojes para {{gama}} con {{materialCorrea}}",
    "meta": "¿Buscas un reloj adaptado a tu estilo de vida? Consulta nuestro catálogo de relojes {{materialCorrea}} para {{gama}} y presume de modelo fabricado en Suiza.",
    "title": "Relojes para {{gama}} con {{materialCorrea}} | Candino"
  },
  "reloj.gama.SMART": {
    "h1": "Smartwatch para {{gama}}",
    "meta": "¡Consigue ahora tu Smartwatch para {{gama}}! Descubre todos nuestros modelos y encuentra el tuyo. Ideales para cualquier ocasión. {{numPagina}}",
    "title": "Smartwatch para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Relojes para {{gama}} con {{tipoCierre}}",
    "meta": "¿Buscas un reloj que se adapte a tu estilo? Consulta nuestro catálogo de relojes con {{tipoCierre}} para {{gama}} y presume de modelo fabricado en Suiza.",
    "title": "Relojes para {{gama}} con {{tipoCierre}} | Candino"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Relojes Sumergibles {{tipoEstanqueidad}} para {{gama}}",
    "meta": "¿Buscas un reloj resistente al agua pero con estilo? Consulta nuestro catálogo de relojes sumergibles {{tipoEstanqueidad}} para {{gama}} y presume de tecnología suiza.",
    "title": "Relojes Sumergibles {{tipoEstanqueidad}} para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Relojes Digitales para {{gama}}",
    "meta": "¿Estás buscando un reloj digital para {{gama}}? En Candino tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Digitales para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Relojes para {{gama}} con movimiento Cuarzo Digital",
    "meta": "¿Buscas Relojes de {{gama}} con movimiento Cuarzo Digital? Echa un ojo a nuestra amplia gama de relojes para {{gama}} y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes para {{gama}} con movimiento Cuarzo Digital | Candino"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Relojes Automáticos para {{gama}}",
    "meta": "¿Estás buscando un reloj automático para {{gama}}? En Candino tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Automáticos para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Relojes Híbridos para {{gama}}",
    "meta": "¿Estás buscando un reloj híbrido para {{gama}}? En Candino tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Híbridos para {{gama}} | Candino"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches para {{gama}}",
    "meta": "¿Estás buscando un reloj híbrido para {{gama}}? En Candino tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Smartwatches para {{gama}} - Relojes Inteligentes | Candino"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Relojes de Cuarzo para {{gama}}",
    "meta": "¿Estás buscando un reloj de cuarzo para {{gama}}? En Candino tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes de Cuarzo para {{gama}} | Candino"
  },
  "reloj.IN": {
    "h1": "Relojes para Niños",
    "meta": "Encuentra los mejores Relojes para Niños en nuestro catálogo. Ayúdales a que aprendan la hora con los mejores modelos de las mejores marcas. {{numPagina}}",
    "title": "Relojes para Niños {{numPagina}} | Candino"
  },
  "reloj.IN.estilo": {
    "h1": "Relojes {{estilo}} para Niños",
    "meta": "Encuentra los mejores Relojes {{estilo}} para Niños en nuestra web. ¡Haz un buen regalo a los pequeños de la casa y enséñales a leer la hora! {{numPagina}}",
    "title": "Relojes {{estilo}} para Niños {{numPagina}} | Candino"
  },
  "reloj.IN.funcion.default": {
    "h1": "Relojes {{funcion}} para Niños",
    "meta": "¿Quieres un reloj {{funcion}} para niño? Encuentra toda una variedad de relojes infantiles {{funcion}} de las mejores marcas. ¡Visita nuestro catálogo! {{numPagina}}",
    "title": "Relojes {{funcion}} para Niños {{numPagina}} | Candino"
  },
  "reloj.IN.SMART": {
    "h1": "Smartwatch para Niños",
    "meta": "Descubre los mejores modelos de Smartwatch para niños y haz que empiecen a interactuar con la tecnología. Apps, mensajería, llamadas, GPS y mucho más {{numPagina}}",
    "title": "Smartwatch para Niños {{numPagina}} | Candino"
  },
  "reloj.marca": {
    "h1": "Relojes {{marca}}",
    "meta": "No te pierdas los mejores modelos de Reloj {{marca}}. Visita nuestra web y descubre nuestra amplia gama de modelos. {{numPagina}}",
    "title": "Relojes {{marca}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama": {
    "h1": "Relojes {{marca}} para {{gama}}",
    "meta": "No te pierdas los mejores modelos de Reloj {{marca}} para {{gama}}. Visita nuestra web y descubre nuestra amplia gama de modelos.",
    "title": "Relojes {{marca}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.colorCorrea": {
    "h1": "Relojes {{marca}} {{colorCorrea}} para {{gama}}",
    "meta": "¿Estás buscando un Reloj {{marca}} para {{gama}} {{colorCorrea}}? Encuentra en nuestro catálogo todos los colores de relojes {{marca}} para {{gama}}. {{numPagina}}",
    "title": "Relojes {{marca}} {{colorCorrea}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorCorrea.default": {
    "h1": "Relojes {{marca}} {{colorCorrea}} para {{gama}}",
    "meta": "¿Estás buscando Relojes {{marca}} {{colorCorrea}} para {{gama}}? Encuentra en nuestro catálogo todos los colores de relojes {{marca}} para {{gama}}.",
    "title": "Relojes {{marca}} {{colorCorrea}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.colorEsfera": {
    "h1": "Relojes {{marca}} {{colorEsfera}} {{gama}}",
    "meta": "¿Estás buscando un Reloj {{marca}} para {{gama}} {{colorEsfera}}? Encuentra en nuestro catálogo todos los colores de relojes {{marca}} para {{gama}}. {{numPagina}}",
    "title": "Relojes {{marca}} {{colorEsfera}} {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.colorEsfera.default": {
    "h1": "Relojes {{marca}} {{colorEsfera}} para {{gama}}",
    "meta": "¿Estás buscando Relojes {{marca}} {{colorEsfera}} para {{gama}}? Encuentra en nuestro catálogo todos los colores de relojes {{marca}} para {{gama}}.",
    "title": "Relojes {{marca}} {{colorEsfera}} para {{gama}} | Candino"
  },
  "reloj.marca.gama.estilo": {
    "h1": "Relojes {{marca}} {{gama}} {{estilo}}",
    "meta": "Encuentra las últimas novedades en Relojes {{marca}} para {{gama}} {{estilo}}. Relojes con personalidad y diseño único. ¡Visita nuestro catálogo! {{numPagina}}",
    "title": "Relojes {{marca}} {{gama}} {{estilo}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion": {
    "h1": "Relojes {{marca}} {{funcion}} para {{gama}}",
    "meta": "Visita nuestro sitio web y descubre nuestra amplia gama de Relojes {{marca}} {{funcion}} para {{gama}}. Consigue el Reloj que mejor se adapte a ti. {{numPagina}}",
    "title": "Relojes {{marca}} {{funcion}} para {{gama}} {{numPagina}} | Candino"
  },
  "reloj.marca.gama.funcion.default": {
    "h1": "Relojes {{marca}} {{funcion}} para {{gama}}",
    "meta": "Visita nuestro sitio web y descubre nuestra amplia gama de Relojes {{marca}} {{funcion}} para {{gama}}. Consigue el Reloj que mejor se adapte a ti.",
    "title": "Relojes {{marca}} {{funcion}} para {{gama}} | Candino"
  },
  "reloj.marca.IN": {
    "h1": "Relojes {{marca}} para {{gama}}",
    "meta": "¿Buscas Relojes {{marca}} para Niños? Descubre en nuestro catálogo todas las novedades de relojes para los más pequeños. ¡Visita la web!",
    "title": "Relojes {{marca}} para {{gama}} | Candino"
  },
  "reloj.materialCaja.default": {
    "h1": "Relojes con carcasa de {{materialCaja}}",
    "meta": "¿Te gustan los relojes de {{materialCaja}}? Descubre nuestro amplio catálogo y encuentra el reloj que haga brillar tu muñeca. {{numPagina}}",
    "title": "Relojes con carcasa de {{materialCaja}} {{numPagina}} | Candino"
  },
  "reloj.materialCorrea.default": {
    "h1": "Relojes {{materialCorrea}}",
    "meta": "¿Te gustan los relojes con correa de {{materialCorrea}}? Descubre nuestro amplio catálogo y encuentra el reloj que haga brillar tu muñeca. {{numPagina}}",
    "title": "Relojes {{materialCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tipoCierre.default": {
    "h1": "Relojes {{tipoCierre}}",
    "meta": "¿Buscas un Reloj {{tipoCierre}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{tipoCierre}} {{numPagina}} | Candino"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Relojes {{tipoCorrea}}",
    "meta": "¿Buscas un Reloj {{tipoCorrea}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{tipoCorrea}} {{numPagina}} | Candino"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Relojes Digitales",
    "meta": "¿Estás buscando un reloj digital? En Candino tenemos una gran selección de relojes digitales disponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes Digitales | Candino"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Relojes con movimiento Cuarzo Digital",
    "meta": "¿Buscas Relojes con movimiento Cuarzo Digital? Echa un ojo a nuestra amplia gama de relojes  y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes con movimiento Cuarzo Digital | Candino"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Relojes con movimiento Manual",
    "meta": "¿Buscas Relojes con movimiento Manual? Echa un ojo a nuestra amplia gama de relojes para Candino y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes con movimiento Manual | Candino"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Relojes Automáticos",
    "meta": "¿Estás buscando un reloj automático? En Candino tenemos una gran selección de relojes automáticos disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Automáticos | Candino"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Relojes Híbridos",
    "meta": "¿Estás buscando un reloj automático? En Candino tenemos una gran selección de relojes automáticosdisponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes Híbridos | Candino"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "¿Estás buscando un smartwatch? En Candino tenemos una gran selección de relojes inteligentes disponibles. Entra y elige el que mejor se adapta a tu estilo.",
    "title": "Smartwatches - Relojes Inteligentes | Candino"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Relojes de Cuarzo",
    "meta": "¿Estás buscando un reloj de cuarzo? En Candino tenemos una gran selección de relojes de cuarzo disponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes de Cuarzo | Candino"
  }
}
